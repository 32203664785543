.modal {
  z-index: 10001;

  &--cookie {
    .modal-title {
      font-size: 18px;
    }

    .modal-body {
      p, li, span {
        font-size: .875rem;
      }

      .small-text {
        font-size: .875rem;
      }
    }

    .modal-headline {
      display: block;
      margin: 10px 0;
      font-size: 1rem
    }

    @media only screen and (min-width: $screen-lg) {
      .modal-body {
        p, li, span {
          font-size: 1rem;
        }
      }
    }
  }
}

.cookie-setting-checkbox{
  border: 1px solid $ln-darkgray;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 10px;

  &.agree:hover, &.agree.active {
    cursor: pointer;
    color: $ln-green;
  }

  &.deny:hover, &.deny.active {
    cursor: pointer;
    color: red;
  }

  @media only screen and (min-width: $screen-lg) {
    padding: 20px 0;
  }
}

