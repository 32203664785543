/**
 * Created by RZI on 07.03.2016.
 */
.case-studies {
    border-top: 1px solid #C8C7D9;
}

.case-study-image-box {
    margin: 0;
    height: 225px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}

.case-study-box {
    padding: 15px;
    border: 1px solid #c7c6d6;
    margin-bottom: 20px;
}

  .statement-img {
    font-size: 40px;
    color: $ln-color-white;
    background-color: $ln-base-light-blue;
    width: 70px;
    height:70px;
    line-height: 70px;
    text-align: center;

    margin-top: 20px;

    img{
      border-radius: 50%;

    }
  }

@media (max-width: 991px)  {
    .case-study-image-box {
        display: none;
    }
}

@media (min-width: 992px)  {
    .case-study-image-box {
        margin: 0;
        height: 225px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }

    .case-study-box {
        border-top: 0;
    }
}



.case-study-box img {
    margin-bottom: 15px;
    max-height: 80px;
}
