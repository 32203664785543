.ln-instagram-feed-slider {
  $block: &;

  display: block;
  position: relative;
  height: 100%;
  min-height: 13.438rem;
  padding: 0 2rem;

  @media screen and (min-width: $ln-breakpoint-sm){
    padding: 0 2.5rem;
  }

  &__prev, &__next {
    display: block;
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    transition: position .3s ease;
    cursor: pointer;
    z-index: 2;


    text-align: center;
    border-radius: 50%;
    height: 1.875rem;
    width: 1.875rem;
    line-height: 2.063rem;
  }

    &__prev:hover,  &__next:hover {
      background-color: $ln-green;
      color: #fff;
    }

    &__prev {
      left: 0;
      text-indent: -1px;
    }

    &__next {
      right: 0;
      text-indent: 1px;
    }

    //Redesign
    /*
        height: 1.688rem;
        width: 0.875rem;

       @media screen and (min-width: $ln-breakpoint-sm){
        height: 2.375rem;
        width: 1.25rem;
      }

       &__prev {
        background: url('../images/icons/icon-arrow-left-blue.svg') 50%/cover no-repeat;
        left: 5px;

      &:hover {
       left: 0;
      }
    }

    &__next {
      background: url('../images/icons/icon-arrow-right-blue.svg') 50%/cover no-repeat;
      right: 5px;

      &:hover {
        right: 0;
      }
    }
  */

  &__nav.disabled {
    display: none;
  }

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    display: none;
  }

  &--loaded {
    #{$block}__spinner {
      display: block;
    }
  }

  &__list {
    position: relative;
    height: auto;
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  &__wrapper {
    position: relative;
    display: flex !important;
    flex-wrap: wrap;
  }

  &__item {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: initial;
    }
  }
}