.ln-subsidiary-list {
  $block: &;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;

  &--global {
    @media (min-width: $ln-breakpoint-xl) {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    flex-basis: 50%;
    margin-bottom: 3rem;
    outline: 0 !important;

    @media (min-width: $ln-breakpoint-xl) {
      flex-basis: 25%;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-bottom: 10px;

    > i {
      position: absolute;
      left: -3px;
      top: 55%;
      transform: translateY(-44%);
      font-size: 2.5rem;
      color: $ln-darkgray;

      &.fa {
        left: 0;
      }
    }
  }

  &__flag {
    display: inline-block;
    margin-bottom: 10px;
    line-height: 2;
  }

  &__label {
    font-weight: $ln-font-weight-medium;
  }

  &__link {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    &:after {
      display: inline-block;
      position: absolute;
      right: -12px;
      content: '|';
      color: $ln-darkgray;
      border-bottom: none;
      outline: none;
      text-decoration: none;
    }

    &:last-child:after {
      content:'';
    }
  }
}
