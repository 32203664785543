.ln-video {
  display: block;

  &__item {
    display: block;
  }

  &__caption {
    display: block;
    text-align: center;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__item {
      width: 66%;
    }
  }
}