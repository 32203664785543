.ln-space {
  &-before {
    &--large {
      margin-top: $ln-space-large;

      @media (min-width: $ln-breakpoint-md) {
        margin-top: $ln-space-large-md;
      }

      @media (min-width: $ln-breakpoint-lg) {
        margin-top: $ln-space-large-lg;
      }
    }

    &--extra-large {
      margin-top: $ln-space-extra-large;

      @media (min-width: $ln-breakpoint-md) {
        margin-top: $ln-space-extra-large-md;
      }

      @media (min-width: $ln-breakpoint-lg) {
        margin-top: $ln-space-extra-large-lg;
      }
    }
  }

  &-after {
    &--large {
      margin-bottom: $ln-space-large;

      @media (min-width: $ln-breakpoint-md) {
        margin-bottom: $ln-space-large-md;
      }

      @media (min-width: $ln-breakpoint-lg) {
        margin-bottom: $ln-space-large-lg;
      }
    }

    &--extra-large {
      margin-bottom: $ln-space-extra-large;

      @media (min-width: $ln-breakpoint-md) {
        margin-bottom: $ln-space-extra-large-md;
      }

      @media (min-width: $ln-breakpoint-lg) {
        margin-bottom: $ln-space-extra-large-lg;
      }
    }
  }
}

.ln-spacer-line {
  display: block;
  height: 1px;
  margin: 0 $ln-grid-gutter-width;
  padding: 0;
  font-size: 0;
  border-bottom: 1px solid $ln-gray-eaeaea;
  color: transparent;
  outline: none;

  @each $color, $value in $ln-colors {
    &--#{$color} {
      border-bottom: 1px solid #{$value};
    }
  }

  @each $color, $value in $ln-colors-extend {
    &--#{$color} {
      border-bottom: 1px solid #{$value};
    }
  }

  &--full-width {
    margin: 0;
  }

  // fix for ckeditor, can't handle empty tags
  &__placeholder {
    display: none;
    visibility: hidden;
  }
}
