$ln-faq-left-space: 30px;

.lnet-faq {
  &-status {
    padding-bottom: 20px;

    &__text {
      &--lg {
        font-size: 18px;
      }
    }

    &__spinner {
      text-align: center;

      i {
        font-size: 50px;
      }
    }
  }

  &-filter {
    @extend .clearfix;

    &__headline {
      display: block;
      font-size: 26px;
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 20px;
      letter-spacing: -0.005em;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-flow: row wrap;
      padding: 0;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      width: 100%;
      margin-bottom: 20px;
      padding: 5px 20px;
      color: $ln-white;
      background-color: $ln-electric-purple;
      border: 2px solid $ln-electric-purple;
      font-size: 14px;
      text-align: center;
      //text-transform: uppercase;
      cursor: pointer;
      position: relative;
      user-select: none;

      &:hover {
        background-color: $ln-electric-purple--hover;
        border: 2px solid $ln-electric-purple--hover;
      }

      &-text {
        display: inline-block;
        text-align: center;
        line-height: 18px;
      }

      &-icon {
        height: 40px;

        > i {
          &::before {
            color: #fff;
            font-size: 40px;
          }
        }
      }

      &-icon-badge {
        display: none;
        position: absolute;
        left: auto;
        right: -15px;
        top: -15px;
        border-radius: 50%;
        background-color: $ln-neon-green;
        color: $ln-color-white;
        width: 30px;
        line-height: 28px;
        height: 30px;
        z-index: 9;

        > i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      &--active {
        background-color: $ln-electric-purple;
        border-color: $ln-electric-purple;

        &:hover {
          background-color: $ln-electric-purple;
          border-color: $ln-electric-purple;
        }

        .lnet-faq-filter__item-icon-badge {
          display: block;
        }
      }
    }

    // lnet-faq-filter grid
    &__wrapper {
      .lnet-faq-filter__item {
        .lnet-faq-filter__item-icon {
          width: 20%;
        }

        .lnet-faq-filter__item-text {
          width: 80%;
        }
      }
    }

    // >= 540 (
    /*@media only screen and (min-width: 540px) {
      &__wrapper {
        .lnet-faq-filter__item {
         max-width: 250px;
        }
      }
    }*/

    // >= 768
    @media only screen and (min-width: $screen-sm-min) {
      &__wrapper {
        justify-content: flex-start;

        .lnet-faq-filter__item {
          margin-right: 35px;
          max-width: 250px;
        }
      }
    }
  }

  &-list {
    &__headline {
      // taken from _headlines.scss h3
      font-family: $font-stack-1;
      font-weight: $font-weight-normal;
      font-size: 22px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 15px;
      @include letter-spacing(-5);

      @media only screen and (min-width: $screen-sm-min) {
        font-size: 20px;
        line-height: 26px;
        margin-top: 5px;
        @include letter-spacing(25);
      }
    }

    &-item {
      border-bottom: 1px solid rgba(0, 0, 0, .05);

      &__header {
        padding: 0;
      }

      &__headline {
        display: block;
        margin: 0;
        padding: 10px 0 10px $ln-faq-left-space;
        font-size: 16px;
        position: relative;
        line-height: 1.4;
        cursor: pointer;

        &:hover {
          color: $ln-electric-purple;
        }

        &:before {
          font-family: fontawesome;
          content: "\f107";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          line-height: 1.1;
          color: $ln-electric-purple;
        }

        &.collapsed:before {
          content: "\f105";
        }
      }

      &__body {
        @media only screen and (min-width: $screen-sm-min) {
          width: 80%;
        }
      }

      &__text {
        border-left: 2px solid $ln-electric-purple;
        margin: 5px 0 15px $ln-faq-left-space;
        padding-left: 15px;
      }
    }
  }

}
