.ln-login-block {
  $block: &;

  @media (min-width: $ln-breakpoint-md) {
    width: 60%;
  }

  @media (min-width: $ln-breakpoint-lg) {
    width: 40%;
  }

  &--valign-middle {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  &--half, &--wide {
    width: 100%;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &__headline {
    color: $ln-nightblue;
  }

  &__body {
    padding: 2.1875rem;

    &--grid-2 {
      display: block;

      #{ $block }__item:nth-child(odd) {
        padding: 0 0 2.1875rem 0;
      }

      #{ $block }__item:nth-child(even) {
        border-top: 1px solid $ln-gray-eaeaea;
        padding: 2.1875rem 0 0 0;
      }
    }

    &--shadow {
      box-shadow: $ln-teaser-box-shadow;
    }

    @media (min-width: $ln-breakpoint-md) {
      &--valign-middle {
        margin-top: 5.313rem;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      &--grid-2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        #{ $block }__item {
          flex: 0 0 50%;
          max-width: 50%;
        }

        #{ $block }__item:nth-child(odd) {
          padding: 0 2.1875rem 0 0;
        }

        #{ $block }__item:nth-child(even) {
          border-top: 0;
          border-left: 1px solid $ln-gray-eaeaea;
          padding: 0 0 0 2.1875rem;
        }
      }
    }
  }

  @media only screen and (min-width: $ln-breakpoint-xl) {
    &--half {
      width: 50%;
    }

    &--wide {
      width: 70%;
    }
  }
}
