.lnet-company-logo-slider {
    min-height: 130px;

    &__item {
      display: block;
      width: 100%;
      height: 100px;

      &--grey {
        filter: grayscale(100%);
        opacity: 0.8;
      }

      img {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
      }
    }

  .owl-stage {
    margin: 0 auto; //Fix slider, set content center
  }
}
