// width definitions are based on available space according to boot strap break points: (xs <= 767px), sm >= 768px, md >= 992px, lg >= 1200px
.ln-image {
  &--half-side {
    display: block;
    margin: 0;
    padding: 0;

    @media only screen and (min-width: $ln-breakpoint-md) {
      width: 345px;
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      width: 455px;
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (min-width: $ln-breakpoint-xl) {
      width: 555px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--full-size {
    text-align: center;

    .img-responsive {
      margin: 0 auto;
    }
  }

  // used for image block left and right
  &--pos-left,
  &--pos-center,
  &--pos-right {
    .img-responsive {
      margin: 0 auto;
    }

    figcaption {
      text-align: center;
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    &--pos-left {
      .img-responsive {
        margin: 0 auto 0 20px;
      }

      figcaption {
        text-align: left;
      }
    }

    &--pos-center {
      .img-responsive {
        margin: 0 auto;
      }

      figcaption {
        text-align: center;
      }
    }

    &--pos-right {
      .img-responsive {
        margin: 0 20px 0 auto;
      }

      figcaption {
        text-align: right;
      }
    }
  }
}

a.ln-image,
a.image-holder { // @todo image holder is old and must be refactored
  border: 0;

  &:hover {
    border: 0;
  }
}
