a.lnet-block__anchor {
  width: 1px;
  height: 1px;
  position: absolute;
  left: 0;
  top: -70px;
  line-height: 1px;
  font-size: 1px;
  opacity: 0;

  @media (min-width: $screen-sm-min) {
    top: -90px;
  }
}

// special for topmenu
@media (min-width: $screen-sm-min) {
  body {
    &.has-topmenu {
      a.lnet-block__anchor {
        top: -121px;
      }
    }
    &.has-stagebar {
      a.lnet-block__anchor {
        top: -121px;
      }
    }
    &.has-topmenu.has-stagebar {
      a.lnet-block__anchor {
        top: -157px;
      }
    }
  }
}
