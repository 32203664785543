@mixin ln-panel-theme-header($prefix, $color) {
  #{ $prefix }__header {
    background-color: #{ $color };
    color: #fff;
  }
}

@mixin ln-panel-theme ($prefix, $color) {
  border: 1px solid #{ $color };

  @include ln-panel-theme-header($prefix, $color);
}
