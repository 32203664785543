.fancybox-navigation,
.fancybox-toolbar {
  .fancybox-button {
    svg {
      color: #fff;
    }
  }
}

.fancybox-caption {
  .fancybox-caption__body {
    color: #fff;

    p, strong {
      color: #fff;
    }

    p {
      margin-bottom: 10px;
    }
  }
}
