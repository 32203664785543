.lnet-blog {

  &-detail {
    margin-top: 0;
    margin-bottom: 15px;

    &__headline {
      display: block;
      font-size: 26px;
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 10px;
      letter-spacing: -0.005em;

      @media only screen and (min-width: $screen-sm-min) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    // define elements for wrapper-meta (usable without wrapper)
    &__separator-middot {
      text-align: center;
      font-size: 12px;

      &:after {
        content: '\00B7';
      }

    }

    &__separator-pipe {
      text-align: center;
      font-size: 12px;

      &:after {
        content: '|';
      }
    }

    &__author {
      width: 100%;
      font-size: 12px;

      @media only screen and (min-width: $screen-sm-min) {
        width: auto;
      }
    }

    &__release-date {
      font-size: 12px;
    }

    &__category {
      font-size: 12px;
      /** old
      color: $ln-green;
       */
      color: $ln-electric-purple;
    }

    &__wrapper-meta {
      display: table;
      margin: 5px 0;
    }

    // defines elements behavior in wrapper context
    &__wrapper-meta > &__separator-middot,
    &__wrapper-meta > &__separator-pipe,
    &__wrapper-meta > &__author,
    &__wrapper-meta > &__release-date,
    &__wrapper-meta > &__category {
      display: inline-block;
      vertical-align: middle;

      @media only screen and (min-width: $screen-sm-min) {
        display: table-cell;
        width: auto;
      }
    }

    &__wrapper-meta > &__separator-middot,
    &__wrapper-meta > &__separator-pipe {
      width: 20px;
    }

    &__wrapper-meta > &__separator-middot {
      display: none;

      @media only screen and (min-width: $screen-sm-min) {
        display: table-cell;
      }
    }

    // End define elements for wrapper-meta

    &__figure {
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__text {
      margin-top: 0;
      margin-bottom: 10px;

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }
    }

    &__date-modified {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &-author {
    @extend .lnet-blog-wrapper;
    margin-bottom: 20px;


    &__name {
      > h5 {
        font-size: 1.25rem;
        font-style: $ln-font-style-base;
        font-weight: $ln-font-weight-base;
        color: $ln-darkgray;
        margin-bottom: .5rem;
      }
    }

    &__label {
      margin-bottom: 15px;
      width: 100%;

      .ln-headline--small {
        color: $ln-nightblue;
      }
    }

    &__image {
      flex: 0 0 auto;
      margin-bottom: 10px;
      max-width: 100px;
      width: 100%;

      img {
        height: auto;
        max-width: 100%;
        border-radius: 50%;
      }

      > i {
        font-size: 70px;
      }
    }

    &__text {
      font-size: 12px;
    }
  }

  &-overview {
    margin: 1.25rem 0;

    &__header {
      display: block;
      font-size: 24px;
      line-height: 30px;
      margin-top: 0;
      margin-bottom: 15px;
      letter-spacing: -0.005em;

      @media only screen and (min-width: $screen-sm-min) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 15px;
        letter-spacing: 0em;
      }
    }

    &__description {
      display: block;
      margin-bottom: 25px;
    }
  }

  &-wrapper {
    @extend .clearfix;
    display: block;

    // compensate for bootstrap row margins
    margin-left: -15px;
    margin-right: -15px;
  }

  &-teaser {
    display: block;
    position: relative;
    border: 1px solid $ln-color-gray-light_v1;
    margin-bottom: 30px;
    padding: 10px 10px 40px 10px;
    color: inherit;

    &:hover, &:focus, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }

    &__bg-image {
      display: block;
      height: 200px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    // define elements for wrapper-meta (usable without wrapper)
    &__separator {
      text-align: center;
      font-size: 12px;

      &:after {
        content: '|';
      }
    }

    &__release-date {
      font-size: 12px;
    }

    &__category {
      font-size: 12px;
    }

    &__wrapper-meta {
      display: table;
      margin: 5px 0;
    }

    &__wrapper-meta > &__separator,
    &__wrapper-meta > &__release-date,
    &__wrapper-meta > &__category {
      display: table-cell;
      vertical-align: middle;
    }

    &__wrapper-meta > &__separator {
      width: 20px;
    }

    // End define elements for wrapper-meta

    &__headline {
      display: block;
      margin-top: 0;
      margin-bottom: 10px;
      color: $ln-color-13;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.005em;

      @media only screen and (min-width: $screen-sm-min) {
        margin-top: 5px;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.025em;
      }
    }

    &__text {
      margin-top: 0;
      margin-bottom: 10px;
    }


    &__cta {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  &-sidebar-block {
    margin-bottom: 20px;

    &__headline {
      display: block;
      margin-top: 0;
      margin-bottom: 15px;

      @media only screen and (min-width: $screen-sm-min) {
        margin-top: 5px;
      }
    }

    &__text {
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__link {
      @extend .btn-block;
    }

    ul, ol {
      &.list-fix {
        padding: 0;
        margin: 0 0 10px 0;
        list-style: none !important; // override ln-theme skeleton.scss

        > li {
          &::before {
            content: none;
            border: none;
            height: auto;
            width: auto;
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &__category-link {
      line-height: 28px;
    }
  }

  @media only screen and (min-width: $screen-sm-min) {

    &__overview {
      margin: 2rem 0;
    }
  }
}
