.lnet-search {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;

  &__input-wrapper {
    flex: 1;
    position: relative;
  }

  &__button-wrapper {
    padding-left: 15px;
  }

  &__input {
    height: 45px;
    border-radius: 0;
    padding-right: 45px;
    border: 1px solid $ln-base-light-blue;
    font-size: 18px;

    // remove default clear icon
    // https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-clear
    &::-ms-clear {
      display: none;
    }

    &--bg-white {
      background-color: #fff;
    }

    &--fw-300 {
      font-weight: 300;
    }
  }

  &__icon-search {
    position: absolute;
    display: none;
    top:50%;
    transform: translateY(-50%);
    left:15px;
    font-size: 26px;
    color: $ln-electric-purple;
  }

  &__icon-reset {
    position: absolute;
    display: block;
    top:50%;
    transform: translateY(-50%);
    right: 15px;
    background: transparent;
    color: $ln-electric-purple;
    font-size: 26px;
    line-height: inherit;
    border: 0;
    text-decoration: none;
    cursor: pointer;
  }

  &__button {
    @extend .btn-green;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 20px;

    &-icon {
      font-size: 28px;
    }

    &--capitalize {
      text-transform: uppercase;
    }
  }

  @media only screen and (min-width: $screen-md-min) {
    &__input {
      padding: 6px 50px;
    }

    &__button {
      &-icon {
        padding-left: 6px;
      }
    }

    &__icon-search {
      display: block;
    }
  }
}
