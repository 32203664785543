// set defaults
// we have to style these tags
h1, h2, h3, h4, h5, h6 {
  @include ln-headline-default();
  color: $ln-nightblue;
  margin-top: 0;
  margin-bottom: .5rem
}

h1 {
  @include ln-headline-large();
}

h2 {
  @include ln-headline-normal();
}

h3 {
  @include ln-headline-small();
}

h4 {
  @include ln-headline-tiny();
}

.ln-headline {
  @include ln-headline-default();

  &--large {
    @include ln-headline-large();
  }

  &--normal {
    @include ln-headline-normal();
  }

  &--small {
    @include ln-headline-small();
  }

  &--tiny {
    @include ln-headline-tiny();
  }

  &--block {
    @include ln-headline-block();
  }

  &--teaser {
    @include ln-headline-teaser();
  }

  &--hero {
    @include ln-headline-hero();

    &--no-transform {
      text-transform: none;
    }
  }
}
