@mixin ln-headline-default() {
  display: block;
  font-family: $ln-font-family;
  font-style: $ln-font-style-base;
  font-weight: $ln-font-weight-base;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.25rem;

  > p, strong {
    color: inherit;
  }
}

@mixin ln-headline-large() {
  @include ln-headline-default();
  font-size: 1.875rem;

  @media screen and (min-width: $ln-breakpoint-md) {
    font-size: 2.25rem;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 2.6875rem;
  }
}

@mixin ln-headline-normal() {
  @include ln-headline-default();
  margin: 2.125rem 0 1rem;
  font-size: 1.625rem;

  @media screen and (min-width: $ln-breakpoint-md) {
    font-size: 2rem;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 2.375rem;
  }
}


@mixin ln-headline-small() {
  @include ln-headline-default();
  margin: 2rem 0 1rem;
  font-size: 1.25rem;

  @media screen and (min-width: $ln-breakpoint-md) {
    font-size: 1.625rem;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 1.875rem;
  }
}

@mixin ln-headline-tiny() {
  @include ln-headline-default();
  margin: 1.25rem 0 .75rem;
  font-size: 1.125rem;

  @media screen and (min-width: $ln-breakpoint-md) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 1.375rem;
  }
}

@mixin ln-headline-block() {
  @include ln-headline-default();
  margin: 0 0 1rem 0;

  @media screen and (min-width: $ln-breakpoint-md) {
    font-size: 2rem;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 2.375rem;
  }
}

@mixin ln-headline-teaser() {
  @include ln-headline-default();
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: $ln-font-weight-base;
  line-height: 1.6875rem;

  @media screen and (min-width: $ln-breakpoint-lg) {
    font-size: 1.375rem;
  }

  &--blog {
    margin: .625rem 0 .9375rem;
    font-size: 1.375rem;
  }

  &--small-mb {
    margin-bottom: .625rem;
  }
}

@mixin ln-headline-hero() {
  @include ln-headline-default();
  margin-bottom: .625rem;
  font-size: 2.25rem;
  line-height: 1.1;
  font-weight: $ln-font-weight-light;
  text-transform: uppercase;

  @media screen and (min-width: $ln-breakpoint-lg) {
    margin-bottom: .625rem;
    font-size: 2.25rem;
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    font-size: 2.75rem;
  }

  @media screen and (min-width: $ln-breakpoint-xxl) {
    font-size: 3.25rem;
  }
}
