.ln-icon-size-lg {
  font-size: $font-size-lg-icon;
}

.ln-icon-size-md {
  font-size: $font-size-md-icon;
}

/*
[class^="ln-icon"]:before,
[class*=" ln-icon"]:before {
  color: $ln-color-13;
}*/
