.full-width-block {
  position: relative;
  width: 100%;
  padding: 1.25rem 0;
  color: $ln-darkgray;
  box-shadow: none;

  &--medium-space-above {
    padding-top: 1.875rem;
  }

  &--medium-space-below {
    padding-bottom: 1.875rem;
  }

  &--large-space-above {
    padding-top: 2.5rem;
  }

  &--large-space-below, &--extra-large-space-below {
    padding-bottom: 2.5rem;
  }

  &--zero-space-above {
    padding-top: 0;
  }

  &--zero-space-below {
    padding-bottom: 0;
  }

  @media (min-width: $ln-breakpoint-md) {
    &--extra-large-space-below {
      padding-bottom: 6rem;
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    padding: 2rem 0;

    &--medium-space-above {
      padding-top: 1.875rem;
    }

    &--medium-space-below {
      padding-bottom: 1.875rem;
    }

    &--large-space-above {
      padding-top: 4.0625rem; // 65px
    }

    &--large-space-below {
      padding-bottom: 4.0625rem; // 65px
    }

    &--extra-large-space-below {
      padding-bottom: 10rem;
    }

    &--zero-space-above {
      padding-top: 0;
    }

    &--zero-space-below {
      padding-bottom: 0;
    }

    &--left-half,
    &--right-half {
      > .container {
        display: flex;

        > .row {
          max-width: 50%;
          flex-basis: 50%;
        }
      }
    }

    &--left-three-quarters,
    &--right-three-quarters {
      > .container {
        display: flex;

        > .row {
          max-width: 75%;
          flex-basis: 75%;
        }
      }
    }

    &--left-half,
    &--left-three-quarters {
      > .container {
        justify-content: flex-start;
      }
    }

    &--right-half,
    &--right-three-quarters {
      > .container {
        justify-content: flex-end;
      }
    }
  }

  &__background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: $ln-breakpoint-md-max) {
      background-image: url("/images/hero-bg-mobile.jpg") !important;
    }
  }
}
