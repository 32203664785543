.ln-bluebox {
  $block: &;
  position: relative;
  padding-top: 0;

  &:before {
    display: none;
    content: "";
    position: absolute;
    background: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
    z-index: 0;
  }

  &--big {
    &:before {
      height: 25.625rem;
    }
  }

  &--small {
    &:before {
      height: 18.125rem; // 290px
    }
  }

  &--narrow,
  &--half,
  &--wide {
    &:before {
      width: 50%;
    }
  }

  // not in use
  &--super-narrow {
    &:before {
      width: 30%;
    }
  }

  &--top {
    &:before {
      top: -1.25rem; // -20px
    }
  }

  // not in use
  &--nearly-top {
    &:before {
      top: ln-px-to-rem(10px);
    }
  }

  &--bottom {
    padding-top: 2.5rem;

    &:before {
      bottom: 0;
    }
  }

  &--left {
    &:before {
      left: 0;
    }
  }

  &--right {
    &:before {
      right: 0;
    }
  }

  &--has-button {
    &#{ $block}--bottom {
      &#{ $block }--wide {
        &:before {
          width: 76%;
        }
      }
    }
  }

  @media only screen and (min-width: $ln-breakpoint-sm) {
    &--top {
      &:before {
        top: -2.5rem;
      }
    }

    // not in use in content block theming
    &--nearly-top {
      &:before {
        top: ln-px-to-rem(50px);
      }
    }

    &--bottom {
      &:before {
        bottom: -2.5rem;
      }
    }
  }

  @media only screen and (min-width: $ln-breakpoint-md) {
    &:before {
      display: block;
    }

    &--big {
      margin-bottom: 6.25rem;
      padding-bottom: 0;
    }

    &--small {
      margin-bottom: 1.875rem;
      padding-bottom: 0;
    }
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    // not in use
    &--super-narrow {
      &:before {
        width: 33%;
      }
    }

    &--narrow {
      &:before {
        width: 43%;
      }
    }

    &--wide {
      &:before {
        width: 76%;
      }
    }

    &--big {
      margin-bottom: 4.0625rem;
      padding-bottom: 3.125rem;
    }

    &--small {
      margin-bottom: 6.25rem;
      padding-bottom: 0;
    }

    &--top {
      &:before {
        top: -6.25rem;
      }
    }

    // not in use
    &--nearly-top {
      &:before {
        top: ln-px-to-rem(80px);
      }
    }

    &--bottom {
      margin-bottom: 6.25rem;
      padding-top: 4.0625rem; // 65px

      &:before {
        bottom: -6.25rem; // -100px
      }
    }

    &--has-button {
      &#{ $block }--bottom {
        &:before {
          bottom: 0;
        }
      }
    }
  }
}
