.ln-company-info {
  position: relative;
  background-color: $ln-gray-eaeaea;
  padding: 2.5rem 1.625rem;

  &__grid {
    display: flex;
    border-bottom: 1px solid hsla(0, 0%, 60%, .3);

    &:nth-child(1) {
      border: 0;
      padding-bottom: .75rem;
    }

    dt {
      width: 100%;
      padding-bottom: .75rem;
      line-height: $ln-line-height-base;
      font-weight: $ln-font-weight-light;
      font-size: $ln-font-size-base;
    }

    dd {
      width: 100%;
      padding-bottom: .75rem;
      line-height: $ln-line-height-base;
      font-weight: $ln-font-weight-light;
      font-size: $ln-font-size-base;
    }
  }

  @media (min-width: $ln-breakpoint-sm) {
    padding: 3.125rem 2.5rem;
  }

  @media (min-width: $ln-breakpoint-lg) {
    &__grid {
      dt {
        margin-right: .625rem;
        width: 30%;
        font-size: 1.125rem;
      }

      dd {
        width: 70%;
        font-size: 1.125rem;
      }
    }
  }
}
