.ln-statement {
  $block: &;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background-image: $ln-gradient-purple-to-lagoonblue--to-bottom-left;

  &__image {
    display: none;

    &--mobile {
      display: block;
      max-width: 100%;
      width: 100%;
      flex: 0 0 100%;
    }
  }

  &__text {
    font-size: 1.25rem;
    color: #fff;
    line-height: 1.3;
    padding: 1.875rem;
  }

  &__quote {
    position: relative;
    margin-top: 1.25rem;

    p, strong {
      font-size: 1rem;
      font-weight: $ln-font-weight-base;
      color: #fff;
      font-style: italic;
      line-height: 1.3;
    }

    &:after {
      background: url('../images/icons/icon-quote-white.svg') no-repeat 50%/cover;
      content: "";
      display: block;
      height: 3.4375rem;
      left: auto;
      position: absolute;
      top: -30px;
      right: 0;
      width: 3.4375rem;
    }
  }

  &__footer {
    font-weight: $ln-font-weight-light;
    border-top: 1px solid hsla(0, 0%, 100%, .2);
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    line-height: $ln-line-height-base;
    font-size: $ln-font-size-base;

    strong {
      font-weight: $ln-font-weight-base;
      color: #fff;
    }
  }

  &--has-image {
    #{$block}__quote {
      p, strong {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: $ln-breakpoint-sm) {
    &__text {
      padding: 1.875rem;
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    flex-wrap: nowrap;

    &__image {
      &--mobile {
        display: block;
        max-width: 25rem;
        width: 25rem;
        flex: 0 0 25rem;
      }
    }

    &__text {
      padding: 1.875rem 1.875rem 6.25rem;
    }

    &__quote {
      margin-top: 2.5rem;
      padding: .625rem 0;

      p, strong {
        font-size: 1.125rem;
      }
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    align-items: center;

    &__image {
      display: block;
      max-width: 33.375rem;
      width: 33.375rem;
      flex: 0 0 33.375rem;
    }

    &__text {
      padding: 3.125rem;
    }

    &__quote {
      font-size: 1.375rem;
      padding: .625rem 0;

      strong, p {
        font-size: 1.25rem;
      }

      &:after {
        height: 5.9375rem;
        width: 5.9375rem;
      }
    }

    &__footer {
      font-size: 1.125rem;
    }

    &--has-image {
      align-items: flex-start;

      #{$block}__quote {
        p, strong {
          font-size: 1.375rem;
        }
      }
    }
  }

  @media (min-width: $ln-breakpoint-xl) {
    &__text {
      font-size: 1.5rem;
    }

    &__quote {
      font-size: 1.5rem;
      padding: .625rem 0 0 0;

      strong, p {
        font-size: 1.25rem;
      }
    }

    &--has-image {
      #{$block}__quote {
        p, strong {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (min-width: $ln-breakpoint-px-xxl) {
    &--large-align-left {
      padding-left: 6.25rem;
    }

    &--medium-max-width {
      max-width: 57.5rem;
    }
  }
}
