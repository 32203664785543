.ln-navigation {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  background: $ln-lagoonblue url("../images/mobile-navigation-bg.png") no-repeat top/cover;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
  overflow: auto;
  transition: right .5s ease;
  visibility: hidden;

  &--show {
    visibility: visible;
    right: 0;
  }

  &__topbar {
    top: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: $ln-navigation-topbar-height-base;
    width: 100%;
    z-index: 690;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
  }

  &__logo {
    display: flex;
    background: $ln-nightblue;
    align-items: center;
    height: 100%;
    max-width: 3.4375rem;
    width: 3.4375rem;
    justify-content: center;
    flex-basis: 3.4375rem;

    a {
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      text-decoration: none;
      text-align: center;

      &:hover {
        border: 0;
        text-decoration: none;
      }
    }

    img {
      margin-top: 50%;
      transform: translateY(-50%);
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__video {
    flex-grow: 1;
    height: 100%;
    padding: 0;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      border: none;
      color: #fff;
      text-transform: uppercase;
      background-color: $ln-lagoonblue;
      justify-content: center;
      align-items: center;
      font-weight: $ln-font-weight-base;
      transition: ease-in-out, background-color .15s;

      &:hover {
        border: none;
        background-color: $ln-berry-shade-7--90; /** @ToDo Must be replaced, BG hover color not defined **/
      }
    }
  }

  &__demo {
    flex-grow: 1;
    height: 100%;
    padding: 0;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      border: none;
      color: #fff;
      text-transform: uppercase;
      background-color: $ln-raspberry;
      justify-content: center;
      align-items: center;
      font-weight: $ln-font-weight-base;
      transition: ease-in-out, background-color .15s;

      &:hover {
        border: none;
        background-color: change-color($ln-berry-shade-7, $alpha: 0.9);
      }
    }
  }

  &__contact {
    height: 100%;
    max-width: 3.4375rem;
    flex-basis: 3.4375rem;
    width: 3.4375rem;
    text-align: center;
    margin-left: auto;

    a {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: 100%;
      @extend .btn-primary;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__icon-contact {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }

  &__close {
    height: 100%;
    max-width: 3.4375rem;
    flex-basis: 3.4375rem;
    width: 3.4375rem;
    margin-left: auto;
    text-align: center;
    background: $ln-nightblue;
  }

  &__btn-close {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: $ln-nightblue;
    color: #fff;
    border: 0;
    box-shadow: none;
  }

  &__icon-close {
    fill: #fff;
    height: 1.25rem;
    width: 1.25rem;
  }

  @media (min-width: $ln-breakpoint-xl) {
    display: flex;
    position: relative;
    height: 100%;
    background: none;
    box-shadow: none;
    overflow: inherit;
    left: auto;
    right: 0;
    transition: none;
    visibility: visible;

    &__topbar {
      display: none;
    }
  }
}