.ln-sidebar {
  margin-top: 1.5rem;

  @media (min-width: $ln-breakpoint-lg) {
    margin-top: 0;
  }

  // modify panel in sidebar
  .ln-panel {
    display: block;
    margin-bottom: 1.875rem;

    &__item {
      margin-top: 0 !important; // we have to override bootstrap ".panel-group .panel + .panel" selector
      border-bottom: 1px solid $ln-gray-e8e8e8;
    }
  }

  // modify grid item in sidebar
  .ln-teaser__grid-item {
    margin-bottom: 1.875rem;

    .ln-teaser__item {
      cursor: default;
    }
  }

  // ie 11 hack @see https://vinceumo.github.io/devNotes/CSS/bug-ie-flexbox-min-height/
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ln-teaser__item {
      display: block;
    }

    .ln-teaser__body {
      display: block;
    }
  }

  // modify event appointment list in panel
  .ln-event-appointment-list {
    margin-bottom: 0;

    &__headline {
      display: none;
    }

    &__register {
      display: inline-block;
    }

    &__item:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &__cta {
      &--btn-raspberry {
        @extend .btn;
        @extend .btn-raspberry;
        @extend .btn--small-margin;

        &:before {
          display: none;
        }
      }
    }
  }
}
