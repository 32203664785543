.ln-modal {
  $block: &;

  &__dialog {
    &--xl {
      width: auto;
    }

    &--centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - 1rem);
      margin: 0 1rem;

      &:before {
        display: block;
        height: calc(100vh - 1rem);
        content: "";
      }
    }
  }

  &__content {
    width: 100%;
    border: 0 solid rgba(0,0,0,.2);
    border-radius: 0;
    box-shadow: none;
  }

  &__header {
    position: relative;
    min-height: 3rem;
    padding: .625rem 1rem 0 0;

    &--border-none {
      border: 0;
    }
  }

  &__body {
    position: relative;
    padding: 1rem 1.5rem;
  }

  &__headline {
    @include ln-headline-small();
    margin-top: 0;
    margin-bottom: 0;
    color: $ln-nightblue;
  }

  &__badge {
    @include ln_badge();
  }

  &__flag {
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &__header {
    #{$block}__category {
      margin-left: 3.25rem;
      font-size: small;
      color: $ln-gray-999;
    }

    &--border-none {
      border: 0;
    }

    &--small {

    }
  }

  &__headline-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    #{$block}__flag {
      margin-right: 20px;
    }
  }

  &__close {
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    top: 30px;
    right: 30px;
    margin: -1rem -1rem -1rem auto;
    padding: 0;
    background: $ln-green;
    border: 0;
    cursor: pointer;
  }

  &__icon-close {
    font-size: 10px;
    height: .9375rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: .9375rem;
    fill: #fff;
  }

  @media (min-width: $ln-breakpoint-sm) {
    &__body {
      padding: 1rem 2rem;
    }

    &__dialog {
      &--centered {
        min-height: calc(100% - 3.5rem);

        &:before {
          height: calc(100vh - 3.5rem);
        }
      }
    }
  }

  @media (min-width:  $ln-breakpoint-md) {
    &__dialog {
      &--xl {
        max-width: $ln-modal-max-width-xl;
      }
    }
  }

  @media (min-width:  $ln-breakpoint-lg) {
    &__header {
      padding: 1rem;
    }

    &__body {
      &--margin-bottom {
        margin-bottom: 3.75rem;
      }
    }
  }

  // 1400px
  @media (min-width: $ln-modal-max-width-xl) {
    &__dialog {
      &--centered {
        margin: 0 auto;
      }
    }
  }
}
