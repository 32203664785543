.ln-container-flex {
  $block: &;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 1.25rem;


  &__item {
    position: relative;
    display: block;
    //flex: 0 0 100%; // ie11 mag dit nicht!
    max-width: 100%;
    @include ln-grid-items(); // zu klären ob type abhängig
    margin: 0;
    outline: 0 !important;
    border: 0;
  }

  // types
  &--program {
    #{$block}__item {
      &:first-of-type {
        margin-bottom: 1.25rem;
        padding-top: 1.25rem;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      flex-direction: row;

      #{$block}__item {
        flex-basis: 84%;
        max-width: 84%;
        padding-top: 1.25rem;

        &:first-of-type {
          flex-basis: 16%;
          max-width: 16%;
          padding-right: 0;
        }
      }
    }
  }

  &--subsidiaries {
    @media only screen and (min-width: $ln-breakpoint-lg) {
      flex-direction: row;

      #{$block}__item {
        flex-basis: 80%;
        max-width: 80%;
        padding-top: 1.25rem;

        &:first-of-type {
          flex-basis: 20%;
          max-width: 20%;
        }
      }
    }
  }

  &--vita {
    flex-direction: row;

    #{$block}__item {
      width: 100%;
    }

    @media (min-width: $ln-breakpoint-sm) {
      flex-direction: column;
    }

    @media (min-width: $ln-breakpoint-lg) {
      flex-direction: row-reverse;

      #{$block}__item {
        flex-basis: 58.33333%;
        max-width: 58.33333%;

        &:first-of-type {
          flex-basis: 33.33333%;
          max-width: 33.33333%;
          margin-left: 8.33333%;
        }
      }
    }

    @media only screen and (min-width: $ln-breakpoint-xl) {
      margin: 0 8.33333%;
    }
  }

  &--statement {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    #{$block}__item {
      &:first-of-type {
        flex-basis: 100%;
        max-width: 100%;
        order: 2;
        padding-top: 1.25rem;
      }

      &:last-of-type {
        flex-basis: 100%;
        max-width: 100%;
        order: 1;
        padding-bottom: 1.25rem;
      }
    }

    @media (min-width: $ln-breakpoint-sm) {
      #{$block}__item {
        &:last-of-type {
          flex-basis: 33.75rem;
          max-width: 33.75rem;
          padding: 0 .75rem;
        }
      }
    }

    @media (min-width: $ln-breakpoint-md) {
      #{$block}__item {
        &:last-of-type {
          flex-basis: 45rem;
          max-width: 45rem;
        }
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      flex-wrap: nowrap;
      justify-content: stretch;

      #{$block}__item {
        &:first-of-type {
          flex-basis: 50%;
          max-width: 50%;
          order: 1;
          padding: 0;
        }

        &--full-size {
          &:first-of-type {
            flex-basis: 84.375rem;
            max-width: 84.375rem;
          }
        }

        &:nth-of-type(2) {
          flex-basis: 41.66667%;
          padding: 0 0 0 1.875rem;
          order: 2;
        }
      }
    }
  }

  &--80-20,
  &--70-30 {
    #{$block}__item {
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: $ln-breakpoint-px-md-max) {
      #{$block}__item {
        &:first-of-type {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  &--80-20 {
    @media (min-width: $ln-breakpoint-lg) {
      flex-direction: row;

      #{$block}__item {
        flex-basis: 80%;
        max-width: 80%;

        &:last-of-type {
          flex-basis: 20%;
          max-width: 20%;
        }
      }
    }
  }

  &--70-30 {
    @media (min-width: $ln-breakpoint-lg) {
      flex-direction: row;

      #{$block}__item {
        flex-basis: 70%;
        max-width: 70%;

        &:last-of-type {
          flex-basis: 30%;
          max-width: 30%;
        }
      }
    }
  }

  &--recruiting {
    margin-bottom: 0;

    #{$block}__item {
      padding: 0;
    }

    #{$block}__item:last-of-type {
      text-align: center;
      margin-top: 1.25rem;
    }

    @media (min-width: $ln-breakpoint-lg) {
      flex-direction: row;
      align-items: center;

      #{$block}__item {
        padding-left: 0.938rem;
        padding-right: 0.938rem;
      }

      #{$block}__item:first-of-type {
        padding-left: 0;
      }

      #{$block}__item:last-of-type {
        flex-grow: 3;
        text-align: right;
        margin-top: 0;
      }
    }
  }

  // theming
  @each $color, $value in map-merge($ln-colors, $ln-colors-extend) {
    &--border-#{$color} {
      @include ln-container-flex-theme-border($value);
    }
  }

  @each $color, $value in map-merge($ln-colors, $ln-colors-extend) {
    &--background-#{$color} {
      @include ln-container-flex-theme-background($value);
    }
  }

  @each $color, $value in map-merge($ln-colors, $ln-colors-extend) {
    &--color-#{$color} {
      @include ln-container-flex-theme-color($value);
    }
  }
}
