.ln-badge {
  display: inline-block;
  color: #fff;
  background-color: $ln-nightblue;
  font-weight: $ln-font-weight-base;
  border-radius: 0;
  margin-bottom: .625rem;
  padding: .4375rem .625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  
  @media (min-width: $ln-breakpoint-lg) {
    font-size: 0.875rem;
  }

  &--top-right {
    position: absolute;
    top: 0.9375rem;
    right: 0;
  }

  &--sage {
    background-color: $ln-sage;
  }

  &--lagoonblue {
    background-color: $ln-lagoonblue;
  }
}
