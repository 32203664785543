.ln-program-speaker {
  $block: &;

  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;


  &__image {
    position: relative;
    display: block;
    width: 5rem;
    height: 5rem;

    > img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: 30px;
  }

  &__position {
    font-weight: $ln-font-weight-light;
  }

  &__badge {
    @include ln-badge;

    > i {
      &[class="ln-icon-lucanet-ag-signet"] {
        top: 54%;
      }
    }
  }

  &__image {
    #{$block}__badge {
      top: unset;
      bottom: 0;
    }
  }
}

.ln-program-keynote-speakers {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;

  .ln-program-speaker {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: center;

    .ln-program-speaker__content {
      margin: 20px 0 0  0;
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    flex-direction: row;

    .ln-program-speaker {
      flex-basis: 50%;
    }
  }

  @media (min-width: $ln-breakpoint-px-xl) {
    .ln-program-speaker {
      flex-basis: 25%;
    }
  }
}
