@mixin ln-badge {
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -.75rem;
  top: -.75rem;
  border-radius: 50%;
  color: #fff;
  /**
  background-color: $ln-green;
   */
  background-color: $ln-neon-green;

  > i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    // overrides default color
    &::before {
      color: #fff;
    }

    // fix ln-icon- moep
    &[class^="ln-icon-"] {
      top: 57%;
    }
  }∆∆

  &--left {
    left: -.75rem;
    right: unset;
  }

  &--gradient {
    background: $ln-gradient-lagoonblue-to-purple-to-bottom-left;
  }
}
