.department {

  section{
    padding: 30px 0;
  }

  section:first-of-type{
    padding: 0;
  }

  section:nth-child(even){
    background-color:$ln-color-light-gray;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #ddd;
  }

  section.department-team{

    .panel-heading h3{
      margin:0;
    }

    .panel-body{
      height: auto;
    }

    .panel-footer{
      border-top-color: $ln-base-light-blue;
    }


    @media (min-width: $screen-sm-min) {
        .panel-body {
          height: 178px;

          > article {
            display: block;
            height: 148px;
            overflow: hidden;
          }
        }
      }
  }
}

.teaser-department-block{
  .teaser-department-primary {
    min-height: 213px;
    position:relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: #fff;
    align-items: center;
    background-color: #fff;
    margin-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      .img-responsive{
        max-height: 213px;
      }
    }

    .teaser-department-body{
      width:100%;
      height: 60px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      bottom: 0;
      top: auto;
      background-color: $ln-color-13-rgba-60;

      h3{
        margin: 20px 0;
        text-align: center;
        display: inline-block;
        width:100%;
      }

      .teaser-department-text{
        text-align: center;
        display: block;
        padding: 0 10px;

        > div{
          max-height: 70px;
          overflow: hidden;
          margin-bottom: 10px;
          text-align: left;
        }

        .btn-default{
          margin:10px auto 0 auto;
          color: $ln-base-light-blue;
          border: none;
          background-color: #fff;
        }

        p {
          color: #fff;
        }
      }
    }
  }
}