/**
 * Created by RZI on 07.03.2016.
 */

$ln-color-01: #353535;
$ln-color-02: #002448;
$ln-color-03: #0066A5;

$ln-color-13: #0069b4;
$ln-color-13-rgba-60:  rgba(0,105,178,0.6);
$ln-color-13-rgba-70:  rgba(0,105,178,0.7);
$ln-color-13-rgba-100: rgba(0,105,178,1);

$ln-color-14: #00305d;
$ln-color-14-rgba-60:  rgba(0,48,93,0.6);
$ln-color-14-rgba-100: rgba(0,48,93,1);

$ln-color-15: #004f87;

$ln-color-16: #008bd2; /* lagune-blue */

$ln-color-04: #5FABCE;

$ln-color-05: #A5027D;
$ln-color-06: #CD0000;
$ln-color-07: #999999;
$ln-color-08: #D3D3D3;
$ln-color-09: #C4C8D3;
$ln-color-10: #E7ECF9;
$ln-color-11: #E4DD10;
$ln-color-12: #07BA07;

/* NEW COLORS */

$ln-color-white: #ffffff;

$ln-r16-gray-1: #666666;
$ln-r16-gray-2: #767b81;
$ln-base-color-ln-feature-1845: #525252;

$ln-logo-color-light: $ln-color-13;
$ln-logo-color-dark: $ln-color-14;

$ln-color-light-gray: #f5f5f5;
$ln-color-dark-gray: $ln-color-08;

$ln-gradient1-color-from: $ln-color-02;
$ln-gradient1-color-to:   $ln-color-03;
$ln-gradient1-degree: 30;

$ln-color-gray-light_v1: #eee;

/* some aliases for me */
$ln-base-color: $ln-base-color-ln-feature-1845;

$ln-base-light-blue: $ln-color-13;
$ln-base-dark-blue: $ln-color-14;
$ln-base-lagune-blue: $ln-color-16;
$ln-base-brambleberry: $ln-color-05;
$ln-base-brambleberry-dark: #730157;

$ln-dsgvo-layer-color: $ln-color-15;

$ln-link-color: $ln-color-13;
$ln-link-color-hover: $ln-color-05;

$ln-divider-color: $ln-color-03;

// Unify customized variables
$g-color-primary: $ln-color-13;

$ln-color-sky-blue: #367fa9;
$ln-color-light-see-green: #20b2aa;

/* Background */
$ln-admin-alert-bg-color-success: #00a65a;
$ln-admin-alert-bg-color-error: #d73925;
$ln-admin-alert-bg-color-warning: #ffcc00;

/* Border */
$ln-admin-alert-border-color-success: #008d4c;
$ln-admin-alert-border-color-error: #d73925;
$ln-admin-alert-border-color-warning: #ffcc00;

/** Text */
$ln-admin-alert-color-warning: $ln-darkgray;
