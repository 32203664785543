.ln-cookie-fallback {
  background-color: $ln-lightgray;
  padding: 3.75rem 1.875rem;
}

.ln-media-frame {
  .ln-cookie-fallback {
    background: none;
  }
}
