/**
 * Created by rzi on 08.03.2016.
 */
.cta-header {
    //height: 175px;
    height: 252px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    margin-top: 30px;
}

.cta-body {
    height: 175px;
    padding: 22px 55px;
    border-left: 1px solid #C7C6D6;
    border-right: 1px solid #C7C6D6;
}

.cta-footer {
    height: 60px;
    padding: 0 55px;
    border-left: 1px solid #C7C6D6;
    border-right: 1px solid #C7C6D6;
    border-bottom: 1px solid #C7C6D6;
}

.cta-body h3 {
    margin: 0 0 25px 0;
}

/* Broschure */
.cta-broschure .cta-header{
    background-image: url('../images/cta-box-broschuere.png');
}

/* Dates */
.cta-dates .cta-header{
    background-image: url('../images/cta-box-veranstaltung.png');
}

.cta-dates {
    color: #ffffff;
    background: #003E71;
    background: -webkit-linear-gradient(0deg, #003E71 20%, #005596 80%);
    background: -moz-linear-gradient(0deg, #003E71 20%, #005596 80%);
    background: -ms-linear-gradient(0deg, #003E71 20%, #005596 80%);
    background: -o-linear-gradient(0deg, #003E71 20%, #005596 80%);
}

.cta-dates .cta-body strong,
.cta-dates .cta-body p,
.cta-dates .cta-body br {
    font-size: 12px;
    line-height: 14px;
}

.cta-dates .cta-body {
    border-left: 1px solid #003E71;
    border-right: 1px solid #005596;
}

.cta-dates .cta-body .date {
    color: #60AACD;
}

.cta-dates .cta-footer {
    border-left: 1px solid #003E71;
    border-right: 1px solid #005596;
    border-bottom: 0px solid #C7C6D6;
}

