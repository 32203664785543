.footer {
  $block: &;

  padding: 2.5rem .75rem;
  //old background-image: $ln-gradient-nightblue-to-lagoonblue--to-top-right;
  background-color: $ln-midnightblue;

  @media (min-width: $ln-breakpoint-sm) {
    padding: 2.5rem 0;
  }

  @media (min-width: $ln-breakpoint-md) {
    padding: 5rem 0;
  }

  a {
    border: 0;
    font-weight: $ln-font-weight-light;
    font-style: $ln-font-style-base;
    color: #fff;

    &:hover {
      border: 0;
      color: $ln-electric-purple--hover;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid change_color($ln-white, $alpha: 0.5%);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;

    &--center {
      justify-content: center;
    }
  }

  &__logo {
    display: block;
    width: 9.5rem;
    margin: 0 0 1.25rem;

    > img {
      width: 100%;
      height: auto;
      border-style: none
    }

    @media screen and (min-width: $ln-breakpoint-sm) {
      width: 12.5rem;
    }
  }

  &__group {
    flex-basis: 100%;
    max-width: 100%;

    @media screen and (min-width: $ln-breakpoint-sm) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media screen and (min-width: $ln-breakpoint-lg) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &--center {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__headline {
    display: block;
    margin: 1.875rem 0 .3125rem;
    color: #fff;
    font-size: $ln-font-size-base;
    text-transform: uppercase;

    @media screen and (min-width: $ln-breakpoint-lg) {
      margin: 0 0 .625rem;
      font-size: 1.125rem;
    }
  }

  &__nav {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding: 0 0 .3125rem .9375rem;
      color: #fff;

      &:before {
        display: block;
        position: absolute;
        content: "\BB";
        top: -2px; // fix position
        left: 0;
        margin-right: 5px;
      }

      a {
        font-size: $ln-font-size-base;
      }

      @media screen and (min-width: $ln-breakpoint-md) {
        padding: 0 0 .625rem .9375rem;

        a {
          font-size: 1.125rem;
        }
      }
    }
  }

  &__group--center {
    #{$block}__nav {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;

      @media (min-width: $ln-breakpoint-sm) {
        flex-direction: row;

        li {
          display: inline-block;

          &:not(:first-child) {
            margin-left: 30px;
          }
        }
      }
    }
  }

  &__small {
    display: block;
    font-weight: $ln-font-weight-light;
    font-size: .875rem;
    color: #fff;
  }

  &__social {
    width: 100%;

    > ul {
      margin-bottom: 2.5rem;
      list-style: none;
      padding: 0;
      text-align: left;

      li {
        display: inline-block;
        vertical-align: top;
        padding: 0 .3125rem;

        &:first-child {
          padding: 0 .625rem 0 0;
        }

        &:not(:last-child) {
          margin-right: .5rem;
        }

        a {
          display: block;
          width: 100%;
          max-height: 1.5625rem;
          text-align: center;
          font-size: 1.4375rem;
          color: #fff;
        }
      }

      @media screen and (min-width: $ln-breakpoint-md) {
        margin: 0;
      }
    }

    .ln-icon-social-kununu {
      display: block;
      padding-top: 0.188rem;
    }
  }

  &__copyright {
    width: 100%;

    > p {
      margin: 0 0 .625rem;
      font-size: .75rem;
      color: #fff;
      text-align: left;

      @media screen and (min-width: $ln-breakpoint-md) {
        margin: 0;
        font-size: $ln-font-size-base;
        text-align: right;
      }
    }

    &--center {
      > p {
        @media screen and (min-width: $ln-breakpoint-md) {
          text-align: center;
        }
      }
    }
  }

  &__social,
  &__copyright {
    flex: 0 0 100%;
    max-width: 100%;

    @media screen and (min-width: $ln-breakpoint-md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__meta--center {
    #{$block}__social,
    #{$block}__copyright {
      flex-basis: 100%;
      max-width: 100%;
    }

    #{$block}__copyright {
      > p {
        text-align: center;
      }
    }
  }
}
