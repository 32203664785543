/**
 * Created by RZI on 07.03.2016.
 */
.screenshot-slider {
  background-color: #e8ecf8;
  margin-top: 50px;
}

.carousel-caption {
  position: relative;
  right: 0;
  bottom: 0;
  color: #000000;
  text-align: left;
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-caption {
    right: 0;
    left: 0;
    padding-bottom: 0;
  }
}

.laptop-screenshot {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

@media screen and (min-width: 768px) {

  .screenshot-slider .carousel.slide {
    /* height: 617px; */
    height: 658px;
    padding-top: 24px;
  }

  .screenshot-slider .carousel-indicators {
    bottom: -5px;
  }


}

.carousel-control.left {
  right: auto;
  left: 0;
  background-image: none;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: none;
}

