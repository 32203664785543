/**
 * Created by RZI on 01.05.2016.
 */

.small-img-box {
  padding-left: 5px;
  padding-right: 5px;

  $small-img-box-image-height: 111px;
  $small-img-box-button-height: 50px;
  $small-img-box-height: $small-img-box-image-height + $small-img-box-button-height;

  .overview-panel {
    height: $small-img-box-height;
    margin-bottom: 24px;
    display: block;




    .image {
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      height: $small-img-box-image-height;
    }

    .grayscale {
      filter: gray; /* IE6-9 */
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
      filter: saturate(25%);
      /*
      -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

      -webkit-filter: saturate(25%);

      transition: all .3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      background-size: 110%;

      &:hover {
        background-size: 100%;
      }
    }

    .button-holder {
      @include linear-gradient($ln-gradient1-color-from, $ln-gradient1-color-to);
      height: $overview-box-button-height;

      .button {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        text-align: center;
        color: #ffffff;
      }
    }

    &:hover {

      .image {

      }

      .button-holder {
        background: $ln-color-05;
        @include linear-gradient($ln-color-05, $ln-color-05);
      }

      .grayscale {
        filter:none;
        -webkit-filter: none;
      }

    }

  }

}





