
.callToActionBox .iconholder {
    display: none;
}



@media (min-width: 320px) {
    .callToActionBox {
        margin-top: 23px;
    }
}

@media (max-width: 768px) {
    .callToActionBox {
        width: 70%;
        margin: 23px auto 0 auto;
    }
}

@media (min-width: 768px) {

    .callToActionBox {
        padding: 24px 12px 12px 12px;
        color: #ffffff;
        background: #003E71;
        background: -webkit-linear-gradient(0deg, #003E71 20%, #005596 80%);
        background: -moz-linear-gradient(0deg, #003E71 20%, #005596 80%);
        background: -ms-linear-gradient(0deg, #003E71 20%, #005596 80%);
        background: -o-linear-gradient(0deg, #003E71 20%, #005596 80%);
    }

    .callToActionBox .iconholder {
        display: block;
        position: absolute;
        right: 25px;
        top: 0;
        background-color: #005596;
        padding: 7px;
        font-size: 25px;
        border-radius: 30px;
    }
}


@media (min-width: 992px) {
    .callToActionHolder {
        margin-top: 385px;
        height: 175px;
    }

    .callToActionBox-body {
        height: 100px;
    }
}

.callToActionBox p {
    font-size: 14px;
    line-height: 17px;
}

.callToActionBox h3 {
    color: #ffffff;
    margin: 0 0 12px 0;
    padding: 0;
}

.callToActionBox .date {
    color: #60AACD;
}
