/**
 * Created by rzi on 08.03.2016.
 */
.industryDescription {
    -webkit-columns: 2 370px;
    -moz-columns: 2 370px;
    -ms-column-count: 2 370px;
    columns: 2 400px;

    -webkit-column-gap: 2em;
    -moz-column-gap: 2em;
    -ms-column-gap: 2;
    column-gap: 2em;

    line-height: 1.45;
    min-height: 400px;
    min-height: inherit;
    min-height: initial;
    overflow: hidden;

    a {
      font-weight: 700;
      color: $ln-color-01;
    }
}

.industryDescription p {
    margin-bottom: 1.3em;
    color: #353535;
    font-weight: 300;
    widows: 3;
}



/*
.industryDescription {
    height: 400px;

    -webkit-column-count: 2;
    -moz-column-count: 2;
    -o-column-count: 2;
    -ms-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    -o-column-gap: 30px;
    -ms-column-gap: 30px;
    column-gap: 30px;

    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    overflow: hidden;
}
*/