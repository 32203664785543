.theme-ln {
  .lnet-faq-filter {
    $block: '.lnet-faq-filter';

    #{$block}__item {
      background-color: $ln-electric-purple;
      border-color: $ln-electric-purple;
      color: #fff;

      &:hover {
        background-color: $ln-electric-purple--hover;
        border-color: $ln-electric-purple--hover;
      }
    }
  }
}
