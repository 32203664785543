.ln-logo {
  display: block;
  height: auto;

  &--brand {
    width: ln-px-to-rem(170px);
    padding: ln-px-to-rem(11px 15px 10px 15px);

    @media (min-width: $ln-breakpoint-lg) {
      height: ln-px-to-rem(85px);
      width: ln-px-to-rem(210px);
      padding: ln-px-to-rem(18px 15px 17px 15px);
    }
  }

  &--partner {
    @media (max-width: $ln-breakpoint-lg) {
      width: ln-px-to-rem(210px);
    }
  }
}