.theme-ln {
  .teaser-department-block {
    .teaser-department-primary {
      h3 {
        color: #fff;
      }
    }
  }

  .department-team {
    h3 {
      color: #fff;
    }
  }
}
