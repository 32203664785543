.person-description {
  margin-top: 20px;
}

.statement {
  p:first-of-type {

    &:before {
      content: "\201C";
      font-size: 20px;
      margin: -80px 0 0 -5px;
    }

    &:after {
      content: "\201D";
      font-size: 20px;
      margin: -80px 0 0 0px;
    }
  }
}
