/** @deprecated, use iconTeaser.scss **/
$ln-solution-teaser-color-01: #0063aa;

.lnet-solution-teaser {
  $self: &; /** initial scope: https://css-tricks.com/using-sass-control-scope-bem-naming/ */
  position: relative;
  width: 100%;

  &__item {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 30px;
    padding: 20px 15px 15px 15px;
    color: $ln-base-color;
    text-decoration: none;

    &:hover, &:active, &:focus {
      color: $ln-base-color;
      text-decoration: none;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 95px;
    height: 95px;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    text-align: center;
    background-color: #fff;

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 56px;
      font-style: normal;
      // we have to set the color twice
      // lucanet-icons defines their colors at "before" pseudo elements
      color: $ln-color-13;
      &::before {
        color: $ln-color-13;
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    font-size: 18px
  }

  &__text {
    display: block;
    text-align: center;
    font-size: 14px;
    padding: 0 20px;
  }

  &__cta {
    position: absolute;
    bottom: 15px;
    right: 50%;
    transform: translateX(50%);
    color: $ln-color-13;
    background-color: $ln-color-light-gray;
  }

  // item modifier
  &__item--has-cta {
    // padding of item + padding to accomodate for cta
    padding-bottom: 66px;
  }

  &__item--inverse {
    background-color: $ln-color-light-gray;

    #{ $self }__icon {
      background-color: $ln-color-13;

      > i {
        color: #fff;
        &::before {
          // we have to set the color twice
          // lucanet-icons defines their colors at "before" pseudo elements
          color: #fff;
        }
      }
    }
  }

  // item hover area
  &__item {
    &:hover {
      #{ $self }__icon {
        background-color: $ln-color-13;

        > i {
          // we have to set the color twice
          // lucanet-icons defines their colors at "before" pseudo elements
          color: #fff;
          &::before {
            color: #fff;
          }
        }
      }

      #{ $self }__cta {
        color: $ln-color-13;
        background-color: #fff;
        border-color: $ln-solution-teaser-color-01;
      }
    }

    // item inverse modifier hover area
    &--inverse {
      &:hover {
        background-color: $ln-color-13;
        color: #fff;

        #{ $self }__icon {
          background-color: #fff;

          > i {
            // we have to set the color twice
            // lucanet-icons defines their colors at "before" pseudo elements
            color: $ln-color-13;
            &::before {
              color: $ln-color-13;
            }
          }
        }
      }
    }
  }

  /** very simple flex box example **/
  &--grid {
    @media only screen and (min-width: $screen-sm) {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;

      #{ $self }__item {
        width: 345px;
      }
    }

    @media only screen and (min-width: $screen-lg) {
      flex-wrap: nowrap;
      justify-content: center;

      #{ $self }__item {
        width: 25%;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
