/* deprecated use ln-slider */
.logo-carousel {

  .carousel-control {
      background: none repeat scroll 0 0 transparent;
      border: 4px solid transparent;
      border-radius: 23px;
      height: ln-px-to-rem(40px);
      width : ln-px-to-rem(40px);
      margin-top: ln-px-to-rem(55px);
      color: #000;
      text-shadow: none;
      opacity: 1;
      filter: alpha(opacity=100);
      font-size: ln-px-to-rem(35px);
      line-height: ln-px-to-rem(25px);

    &:hover {
      background: none repeat scroll 0 0 #a71381;
      border-radius: 23px;
      border-style: solid;
      border-color: #e8ecf8;
      color: #fff;
      filter: alpha(opacity=90);
      opacity: .9;
    }
  }

  .carousel-control.left  {
    left: ln-px-to-rem(-12px);
  }

  .carousel-control.right {
    right: ln-px-to-rem(-12px !important);
  }

  .carousel-indicators {
    right: 50%;
    top: auto;
    bottom: 0;
    margin-right: ln-px-to-rem(-19px);

    li {
     background: #c0c0c0;
    }

    .active {
        background: #333333;
    }
  }

  a {
    img {
      filter: gray;  /* For IE 6 - 9 */
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='desaturate'><feColorMatrix type='saturate' values='0'/></filter></svg>#desaturate");
      -webkit-filter: grayscale(100%);  /* For Webkit browsers */
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%); /* standard */
      -webkit-transition: all .4s ease;;
       -moz-transition: all .4s ease;;
       -ms-transition: all .4s ease;;
       -o-transition: all .4s ease;;
       transition: all .4s ease;;
    }

    &:hover {
      img {
        filter: none;
      }
    }
  }

  .image-holder {
    display: flex;
    width: 100%;
    height: ln-px-to-rem(120px);
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    border: 0;
  }
}

.logo-carousel .owl-carousel img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.carousel-controller {
  position: relative;
  margin-bottom: 0;
  padding: ln-px-to-rem(0 40px);

  .owl-prev, .owl-next {
    display: block;
    width: ln-px-to-rem(30px);
    text-align: center;
    border-radius: 45px;
    height: ln-px-to-rem(30px);
    line-height: ln-px-to-rem(33px);
    position: absolute;
    top: 45%;
  }

  .owl-prev:hover, .owl-next:hover {
    background-color: $ln-green;
    color: #fff;
    cursor: pointer;
  }

  .owl-prev {
    left: 0;
  }

  .owl-next {
    right: 0;
  }
}

.ln-carousel-controller-nav {
  &.disabled {
    .owl-prev, .owl-next {
      display: none;
    }

    .owl-stage-outer {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
    }
  }
}
