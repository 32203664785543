.ln-panel {
  $block: &;
  $item: & + '__item';

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;

  &--columns {
    flex-direction: column;
  }

  &--align-center {
    justify-content: space-around;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    margin: 0 0 20px 0;
    border: 1px solid transparent;
    text-align: center;

    &--collapsible {
      cursor: pointer;
      margin: 0;
    }
  }

  &__header {
    padding: 15px 20px 14px;
    border-bottom: 1px solid transparent;
  }

  &__wrapper {
    background-color: #fff;
    color: $ln-darkgray;
    padding: 20px;
  }

  &__body {
    padding: 0.3125rem;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
    justify-content: flex-start;
    padding-top: 20px;

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__headline {
    display: inline;
    font-weight: $ln-font-weight-medium;
    line-height: 1.25rem;
    letter-spacing: 0.025rem;
  }

  &__icon {
    display: inline;
    background: transparent;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  /* theming */
  // default is nightblue
  &__item {
    border: 1px solid $ln-nightblue;
  }

  &__header {
    background-color: $ln-nightblue;
    color: #fff;
  }

  &__headline {
    color: #fff;
  }

  &__icon {
    i:before {
      color: #fff;
    }
  }

  @each $color, $value in $ln-colors {
    #{ $item }--#{ $color } {
      @include ln-panel-theme($block, $value);
    }
  }

  @each $color, $value in $ln-colors {
    #{ $item }--header-#{ $color } {
      @include ln-panel-theme-header($block, $value);
    }
  }

  &--no-border {
    #{ $block }__item {
      border: none;
    }
  }

  /* grid */
  // >= 992
  @media only screen and (min-width: $ln-breakpoint-lg) {
    justify-content: space-between;

    #{ $block }__item {
      width: 31%;
    }

    &--columns {
      #{ $block }__item {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }

  // collapse
  &__item {
    &--collapsible {
      margin-top: 0;
      border: 1px solid #fff;
      border-bottom: 1px solid $ln-lightgray;

      #{ $block }__wrapper {
        background-color: $ln-gray-f5f5f5;
      }

      #{ $block }__body,
      #{ $block }__footer {
        font-size: $ln-font-size-base;

        > p, a {
          font-size: $ln-font-size-base;
        }
      }

      #{ $block }__header {
        position: relative;

        #{ $block }__icon {
          position: absolute;
          right: 1.875rem;
          top: 50%;
          transform: translateY(-50%);

          i::before {
            /** adapt from font-awesome, to avoid multiple i tags */
            content: "\f077";
          }
        }

        &.collapsed {
          background-color: #fff;

          #{ $block }__headline {
            color: $ln-darkgray;
            font-weight: $ln-font-weight-base;
          }

          #{ $block }__icon {
            i::before {
              /** adapt from font-awesome, to avoid multiple i tags */
              content: "\f078";
              color: $ln-gray;
            }
          }

          &:hover {
            background-color: $ln-gray-f5f5f5;

            #{ $block }__headline {
              color: $ln-green;
            }
          }
        }
      }
    }
  }
}

.lnet-panel-group {
  $block: '.lnet-panel-group';

  &--collapse {

    #{ $block }__headline {
      // taken from _headlines.scss h3
      font-weight: $ln-font-weight-base;
      font-size: 1.375rem;
      line-height: 1.75rem;
      margin-top: 0;
      margin-bottom: 15px;
      @include letter-spacing(-5);

      @media only screen and (min-width: $ln-breakpoint-md) {
        font-size: 1.25rem;
        line-height: 1.625rem;
        margin-top: 5px;
        @include letter-spacing(25);
      }
    }

    #{ $block }-item {
      border-bottom: 1px solid rgba(0, 0, 0, .05);

      &__header {
        padding: 0;
      }

      &__headline {
        display: block;
        margin: 0;
        padding: 10px 0 10px $ln-faq-left-space;
        font-size: 1rem;
        position: relative;
        line-height: 1.4;
        cursor: pointer;

        &:hover {
          color: $ln-base-brambleberry;
        }

        &:before {
          font-family: fontawesome;
          content: "\f107";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.125rem;
          line-height: 1.1;
          color: $ln-base-light-blue;
        }

        &.collapsed:before {
          content: "\f105";
        }
      }

      &__body {
        @media only screen and (min-width: $ln-breakpoint-md) {
          width: 80%;
        }
      }

      &__text {
        padding-left: 30px;
      }
    }
  }
}
