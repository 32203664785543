.ln-multiple-upload {
  position: relative;
  padding: 18px 10px 0;
  border: 1px dashed #ccc;
  border-radius: 4px;
  background-color: #eef1f2;
  text-align: center;

  i {
    margin-right: 10px;
  }

  &__info {
    small {
      font-style: italic;
      font-size: 0.75rem;
    }
  }

  &__ctn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    input[type='file'] {
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__list {
    text-align: left;
    background-color: #fff;
    margin: 10px 0;
    font-size: 1rem;

    li:first-of-type {
      padding-top: 0.75rem;
    }

    li:last-of-type {
      padding-bottom: 0.75rem;
    }
  }
}
