.container {

  @media screen and (max-width: $ln-breakpoint-sm-max) { // old value 767.98px
    padding: 0 1.25rem;
  }

  @media screen and (min-width: $ln-breakpoint-sm) {
    width: 540px;
    max-width: 540px;
  }

  @media screen and (min-width: $ln-breakpoint-md) {
    width: 720px;
    max-width: 720px;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    width: 960px;
    max-width: 960px;
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    width: 1160px;
    max-width: 1160px;
  }

  @media screen and (min-width: $ln-breakpoint-xxl) {
    width: 1400px;
    max-width: 1400px;
  }

  @media screen and (min-width: $ln-breakpoint-xxxl) {
    width: 1400px;
    max-width: 1400px;
  }
}

/** remove margin from Container WEB-14, WEB-309 **/
body {
  .container {
    .ln-container {
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}
