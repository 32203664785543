/**
 * Created by RZI on 19.04.2016.
 */

.panel-primary > .panel-heading {
    color: #fff;
    background-color: $ln-color-03;
    border-color:     $ln-color-03;
}

.panel-heading {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}