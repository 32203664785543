@mixin ln-text-p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: $ln-font-size-base;
  font-weight: $ln-font-weight-light;
  line-height: $ln-line-height-base;
  color: $ln-darkgray;

  @media screen and(min-width: $ln-breakpoint-lg) {
    font-size: 1.125rem;
  }

  &:last-child {
    margin: 0;
  }
}
