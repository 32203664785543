.ln-well {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  padding: $ln-grid-gutter-width;
  background-color: $ln-gray-f5f5f5;
  border: 1px solid $ln-gray-eaeaea;

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__badge {
    @include ln-badge();

    @each $color, $value in $ln-colors {
      &--#{$color} {
        background-color: $value;
      }
    }
  }
}
