.ln-partner {
  $block: &;

  // list block
  &-list {
    margin-top: ln-px-to-rem(40px);
    width: 100%;

    &__headline {
    }

    &__item {
      position: relative;
      width: 100%;
      padding: ln-px-to-rem(40px);
      border-bottom: 1px solid $ln-lightgray;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &__collapse-indicator {
      position: absolute;
      top: ln-px-to-rem(10px);
      right: ln-px-to-rem(10px);
      cursor: pointer;

      // one of two i elements at a time will be shown to indicate collapse state
      i {
        font-size: ln-px-to-rem(24px);
        color: $ln-lagoonblue;

        &:first-of-type {
          display: inline-block;
        }

        &:last-of-type {
          display: none;
        }
      }
    }

    &__item-body {
      @extend .clearfix;

      // compensate for bootstrap col paddings that can be used in the mark-up of this clear fixed element
      margin-left: -15px;
      margin-right: -15px;
    }

    &__item-image {
      margin-bottom: ln-px-to-rem(10px);
      // for collapsing
      cursor: pointer;
    }

    &__item-headline {
      margin-top: 0;
    }

    &__item-description {
      display: block;
      margin-top: ln-px-to-rem(5px);
      margin-bottom: ln-px-to-rem(10px);
      position: relative;
      // for collapsing
      cursor: pointer;
      transition: max-height 200ms ease;
    }

    // collapse part
    &__item {
      // closed part
      &:not(.opened) {
        .ln-partner-list__item-description {
          max-height: ln-px-to-rem(100px);
          overflow: hidden;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
          }
        }
      }

      // opened part
      &.opened {
        // plus/minus
        .ln-partner-list__collapse-indicator {
          i {
            &:first-of-type {
              display: none;
            }

            &:last-of-type {
              display: inline-block;
            }
          }
        }

        // description
        .ln-partner-list__item-description {
          max-height: inherit;
        }
      }
    }

    &__item-overlay-frame {
    }

    // Definition of table wrapper and table elements
    &__item-table-wrapper {
      display: table;
      width: 100%;
    }

    &__item-focus-areas, &__item-offices {
      display: table-row;

      @media only screen and (min-width: $ln-breakpoint-sm) {
        display: table-cell;
        width: 70%;

        &:nth-child(1) {
          padding-right: ln-px-to-rem(10px);
        }

        &:nth-child(2) {
          padding-left: ln-px-to-rem(10px);
        }
      }
    }

    &__item-offices {
      @media only screen and (min-width: $ln-breakpoint-sm) {
        width: 30%;
      }

      ul {
        margin-bottom: ln-px-to-rem(20px);
      }
    }

    // End of Definition of table wrapper and table elements
    &__item-country {
      display: block;
      font-size: $ln-font-size-base;
      font-weight: $ln-font-weight-medium;
      margin-top: ln-px-to-rem(15px);
      padding-bottom: ln-px-to-rem(10px);

      @media (min-width: $ln-breakpoint-lg) {
        font-size: 1.125rem;
      }
    }

    &__item-city {

    }

    &__item-contact-button {
    }
  }

  &-contact {

    &__header, &__body {
      padding: ln-px-to-rem(30px);
    }

    &__close {

      i {
        font-size: ln-px-to-rem(24px);
      }
    }

    &__headline {
    }

    &__status-message1 {
      display: none;
      margin-top: ln-px-to-rem(15px);
      text-align: center;
      font-size: ln-px-to-rem(16px);
      font-weight: 600;
    }

    &__status-message2 {
      display: none;
      margin-bottom: ln-px-to-rem(30px);
      text-align: center;
      font-size: ln-px-to-rem(16px);
    }

    &__form {
      &--hide {
        display: none;
      }
    }

    &__image {
      text-align: center;
      margin: ln-px-to-rem(5px) ln-px-to-rem(10px);

      img {
        max-height: 120px;
      }
    }

    &__description {
      margin-top: ln-px-to-rem(5px);
      margin-bottom: ln-px-to-rem(10px);
    }

    &__salutation-label {
      margin-top: ln-px-to-rem(5px);
      margin-bottom: ln-px-to-rem(5px);
    }

    &__salutation {
      margin-top: ln-px-to-rem(5px);
      margin-bottom: ln-px-to-rem(10px);
    }

    &__firstName, &__lastName, &__company, &__job-position, &__email, &__phone {
      border: 1px solid $ln-lagoonblue;
    }

    &__message {
      border: 1px solid $ln-lagoonblue;
    }

    &__law-text {
    }

    &__send-button {
      span {
        &:last-of-type {
          display: none;
        }
      }

      &--sending {
        span {
          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: inline-block;
          }
        }
      }
    }
  }

  &__grid {
    display: flex;

    &--introduction {
      flex-wrap: nowrap;
      align-items: stretch;
      flex-direction: column;

      #{$block}__logo {
        text-align: center;
        padding: 1.875rem;
      }
    }
  }

  &__content {
    &--list-underline {
      @include ln-list-underline();
    }

    &--bluebox {
      position: relative;
      padding: 1.5625rem 1.875rem;
      background-image: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
      color: #fff;

      h2, h3, h4, p {
        color: #fff;
      }

      @include ln-list-white();
    }
  }

  &__locations {
    &:before {
      display: none;
      content: "";
      position: absolute;
      background: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
      z-index: 0;
      height: 18.125rem;
      width: 50%;
      top: 0;
    }
  }

  &__datev-banner {
    svg {
      min-width: auto !important;
    }
  }

  @media (min-width: $ln-breakpoint-px-md) {
    &__grid {

      &--introduction {
        flex-direction: row;

        #{$block}__logo {
          border-right: 1px solid #eaeaea;
          margin: 0 2.5rem 0 0;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
          padding: 0 1.875rem;
        }
      }
    }

    &__content {
      &--bluebox {
        padding: 1.5625rem 2.5rem;
      }
    }

    &__locations {
      padding: 2rem 0;
      min-height: 20.125rem; // 290px

      &:before {
        display: block;
        top: 2rem;
      }
    }
  }

  @media (min-width: $ln-breakpoint-px-lg) {
    &__content {
      &--bluebox {
        padding: 3.125rem 4.6875rem;
      }
    }

    &__locations {
      &:before {
        width: 43%;
      }
    }
  }
}
