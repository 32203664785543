.ln-collapsible-panel {
  $block: &;

  display: block;
  border-bottom: 1px solid $ln-gray-eaeaea;

  &__headline {
    font-weight: $ln-font-weight-light;
    font-size: 1.125rem;

    &--single-line-spacing {
      line-height: 1;
    }
  }

  &__symbol {
    width: 1rem;
    height: 1rem;
  }

  // one of two i elements at a time will be shown to indicate state
  &__indicator {
    i {
      &:first-of-type {
        display: inline-block;
      }

      &:last-of-type {
        display: none;
      }
    }
  }

  // define header and its wrapped elements
  &__header {
    display: block;
    position: relative;
    padding: .75rem 2.8125rem;
    cursor: pointer;
    background-color: #fff;

    #{$block}__symbol {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translateY(-50%);
    }

    #{$block}__indicator {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      transform: translateY(-50%);
    }

    &--padding-small {
      padding: .75rem 2.5rem 1.25rem .75rem;
    }
  }

  &__body {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease;
  }

  &--active {
    #{$block}__headline {
      color: #fff;

      strong {
        color: #fff;
      }
    }

    #{$block}__indicator {
      i {
        color: #fff;

        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: inline-block;
        }
      }
    }

    #{$block}__header {
      background: $ln-green;
    }

    #{$block}__body {
      max-height: none;
    }
  }

  @media only screen and (max-width: $ln-breakpoint-md-max) {
    &--mobile-background-lightgray {
      #{$block}__body {
        background: $ln-lightgray;
      }
    }
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    &--desktop-background-lightgray {
      #{$block}__body {
        background: $ln-lightgray;
      }
    }

    &__header {
      &--hide-desktop {
        display: none;
      }
    }

    &__body {
      &--no-transition-desktop {
        transition: none;
      }
    }

    &--no-border-desktop {
      border: none;
    }
  }
}
