.ln-breadcrumb {
  display: none;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px !important; // important overrides bootstrap container
  font-size: 1.125rem;
  font-weight: $ln-font-weight-light;
  line-height: 1;
  color: $ln-gray-999;

  @media only screen and (min-width: $ln-breakpoint-lg) {
    display: block;
    padding: 1.25rem 15px;
  }

  @media only screen and (min-width: $ln-breakpoint-xxl) {
    display: block;
    padding: 1.25rem 15px;
  }

  ul li {
    margin: 0 !important;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__separator {
    margin-left: 10px;
    margin-right: 10px;

    &::before {
      content: '>';
    }
  }

  &__item {
    display: inline-block;

    &:after {
      content: "\BB";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }

    span {
      display: inline-block;
      padding: 0 .5rem;
    }

    &:first-child {
      span {
        padding-left: 0;
      }
    }
  }

  &__link {
    font-size: 1.125rem;
    font-weight: $ln-font-weight-light;
    color: $ln-gray-999;
    border: 0;

    &:hover {
      border: 0;
      font-weight: $ln-font-weight-base;
      color: $ln-green;
    }
  }
}
