@use "sass:math";
/**
* CSS Style modify for owl-slider
* https://owlcarousel2.github.io/OwlCarousel2/docs/api-classes.html
*/
.lnet-slider {
  $self: &; /** initial scope **/
  $control-height: 120px;

  /** Left-Right Button Control */
  &__nav {
    width: 0;
    opacity: 0.5;
    -webkit-transition: 0.4s all ease-in-out .1s;
    -moz-transition: 0.4s all ease-in-out .1s;
    -ms-transition: 0.4s all ease-in-out .1s;
    -o-transition: 0.4s all ease-in-out .1s;
    transition: 0.4s all ease-in-out .1s;

    &:hover {
      opacity: 1;
    }
  }

  &__prev, &__next {
    position: absolute;
    width: 50px;
    top: 50%;
    height: $control-height;
    line-height: $control-height;
    font-size: 15px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0;
    margin: math.div($control-height,-2) 0 0 0;
    text-shadow: none;
    border-bottom: 1px solid $ln-color-white;
    border-top: 1px solid $ln-color-white;
    color: $ln-color-white;
    text-align: center;

    &:hover{
      cursor: pointer;
    }

    &--grey-small  {
      background: none;
      border: none;
      border-radius: 45px;
      color: $ln-color-01;
      height: 30px;
      line-height: 33px;
      margin: auto;
      top: 45%;
      width: 30px;

      &:hover {
        background-color: $ln-link-color-hover;
        color: $ln-color-white;
      }
    }
  }

  &__prev {
    left: 0;
    border-right: 1px solid $ln-color-white;
  }

  &__next {
    right: 0;
    border-left: 1px solid $ln-color-white;
  }

  /** Transition Content, use owl-item class, can't modify for owl-plugin */
  .owl-item{
      #{ $self }--transition {
        opacity: 0;
        -webkit-transition: 1s all ease-in-out .1s;
        -moz-transition: 1s all ease-in-out .1s;
        -ms-transition: 1s all ease-in-out .1s;
        -o-transition: 1s all ease-in-out .1s;
        transition: 1s all ease-in-out .1s;
      }

      &.active #{ $self }--transition {
        opacity: 1;
        -webkit-transition: 1s all ease-in-out .2s;
        -moz-transition: 1s all ease-in-out .2s;
        -ms-transition: 1s all ease-in-out .2s;
        -o-transition: 1s all ease-in-out .2s;
        transition: 1s all ease-in-out .2s;
      }
  }

  /** Counter Bulletpoints */
  &__indicator-wrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
  }

  &__indicator {
    margin: 0 2px;
    background-color: $ln-color-14-rgba-60;
    opacity: .7;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-indent: -999px;
    border: 1px solid $ln-color-14;
    border-radius: 10px;
    cursor: pointer;

    &.active {
      opacity: 1;
      border-color: $ln-color-14-rgba-60;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

/* WEB-152 - new statement slider */
.lnet-statement-slider {
  &.carousel-controller {
    padding: 0 20px;
  }
}

@media only screen and (min-width: $screen-md-min) {
  .lnet-statement-slider {
    height: $ln-statement-slider-height-md;

    &.carousel-controller {
      padding: 0 40px;
    }
  }
}
