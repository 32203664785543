//html {
//  font-size: 16px;
//}
//
//body {
//  font-family: $font-stack-1;
//  font-weight: $font-weight-normal;
//  font-style: normal;
//  font-size: 1rem;
//  line-height: 24px;
//  color: $ln-base-color-ln-feature-1845;
//  @include letter-spacing($letter-spacing-normal);
//}

//strong {
//  font-weight: $font-weight-bold;
//}

//a {
//  /*font-weight: $font-weight-bold;*/
//  color: $ln-link-color;
//  &:hover {
//    color: $ln-link-color-hover;
//    text-decoration: none;
//  }
//}

//a.upper {
//  font-weight: $font-weight-bold;
//  color: $ln-link-color;
//  text-transform: uppercase;
//  @include letter-spacing($letter-spacing-more);
//  &:hover {
//    color: $ln-link-color-hover;
//  }
//}


/* Legacy from prototype */
//.read-more {
//    text-transform: uppercase;
//    text-align: right;
//}
//
//.read-more a {
//    font-weight: bold;
//}

blockquote.blockquote-center {
  border: 0;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;

  p:first-of-type {

      &:before {
        content: "\201C";
      }

      &:after {
        content: "\201D";
      }
  }
}
