.event-detail {
  h3 {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .event-date-line {
    line-height: 30px;
    font-size: 14px;
  }
}
