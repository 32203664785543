.ln-media-frame {
  $block: &;

  &--desktop, &--laptop {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    #{$block}__item {
      display: block;
      position: absolute;
      margin: 0;
      background: #000;

      #{$block}__video {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: contain;
      }

      #{$block}__video {
        color: #fff;
      }
    }
  }

  &--desktop {
    #{$block}__item {
      img {
        object-fit: cover; // fix image positioning
      }
    }
  }

  &--desktop {
    padding-top: 76.194853%;
    background: transparent url('../images/background/frame_desktop.png') no-repeat;
    background-size: contain;

    #{$block}__item {
      width: 91.9117647059%;
      top: 5.42822677925%;
      left: 4.13602941176%;
      padding-top: 51.7%;
    }
  }

  &--laptop {
    padding-top: 60.7536764706%;
    background: transparent url('../images/background/frame_laptop.png') no-repeat;
    background-size: contain;

    #{$block}__item {
      width: 73.5294117647%;
      top: 5.74886535552%;
      left: 13.3272058824%;
      padding-top: 49.4%;
    }
  }
}
