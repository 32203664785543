/** old **/
$container-xs-margin-top : 15px;
$container-xs-margin-bottom : 15px;

$container-sm-margin-top : 15px;
$container-sm-margin-bottom : 15px;

$container-md-margin-top : 15px;
$container-md-margin-bottom : 15px;

$container-lg-margin-top : 15px;
$container-lg-margin-bottom : 15px;

$ln-statement-slider-height-md: 200px;
