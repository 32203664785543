.ln-tab-list {
  $block: &;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &__headline {
    display: block;
    font-weight: $ln-font-weight-light;
  }

  &__symbol {
    width: 1rem;
    height: 1rem;
  }

  // one of two i elements at a time will be shown to indicate state
  &__indicator {
    i {
      color: $ln-darkgray;

      &:first-of-type {
        display: inline-block;
      }

      &:last-of-type {
        display: none;
      }
    }
  }

  // define tab and its wrapped elements
  &__tab {
    display: block;
    position: relative;
    padding: .625rem 2.8125rem;
    border-bottom: 1px solid $ln-gray-eaeaea;
    background: #fff;
    cursor: pointer;

    #{$block}__symbol {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translateY(-50%);
    }

    #{$block}__indicator {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      transform: translateY(-50%);
    }

    &--padding-small {
      padding: .625rem 1.25rem;
    }

    &--active {
      background: $ln-green;

      #{$block}__headline,
      strong {
        color: #fff;
      }

      #{$block}__indicator {
        i {
          color: #fff;

          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: inline-block;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $ln-breakpoint-md-max) {
    &--hide-mobile {
      display: none;
    }
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    &__headline {
      font-size: 1.125rem;
    }
  }
}
