.lnet-solution-block {

  &__headline {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  &__link {
    width: 50%;

    @media only screen and (min-width: $screen-md) {
      width: 25%;
    }
  }
}
