/** WEB-171 - workaround - copy styles from unify - @todo we have to build a new gallery block **/
.gallery-style-block {
  &__arrow {
    width: 35px !important;
    height: 35px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
    font-size: 18px;
    background-color: #fff !important;
    z-index: 10;
    cursor: pointer;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      vertical-align: top;
      transform: translateX(-50%) translateY(-50%);
    }

    &--left {
      left: 0;
      bottom: 20px;
    }

    &--right {
      right: 0;
    }
  }

  &__link {
    display: inline;
    font-size: 14px;
    line-height: 1.2 !important;
  }

  &__y {
    padding: 5px 10px;
    margin: -5px -10px;

    .slick {
      &-slide {
        opacity: .5;
        transition: {
          property: opacity;
          duration: .4s;
          timing-function: ease-in;
        }
      }

      &-center {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 991px) {
      display: none !important;
    }
  }
}
