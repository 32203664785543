/**
 * Created by rzi on 19.07.2017.
 */
.wizard {
    margin: 20px auto;
    background: $ln-color-white;
}

    .wizard .nav-tabs {
        position: relative;
        margin: 40px auto;
        margin-bottom: 0;
        border-bottom-color: $ln-color-light-gray;
    }

    .wizard > div.wizard-inner {
        position: relative;
    }

.connecting-line {
    height: 2px;
    background: $ln-color-dark-gray;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: $ln-base-color;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid $ln-color-dark-gray;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}
span.round-tab i{
    color:$ln-logo-color-light;
}
.wizard li.active span.round-tab {
    background: $ln-color-white;
    border: 2px solid $ln-base-brambleberry;

}
.wizard li.active span.round-tab i{
    color: $ln-base-brambleberry;
}

span.round-tab:hover {
    color: $ln-base-color;
    border: 2px solid $ln-base-brambleberry;
}

.wizard .nav-tabs > li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0;
    border: 5px solid transparent;
    border-bottom-color: $ln-base-dark-blue;
    transition: 0.1s ease-in-out;
}

.wizard li.active:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 1;
    margin: 0 auto;
    bottom: 0px;
    border: 10px solid transparent;
    border-bottom-color: $ln-base-brambleberry;
}

.wizard .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
}

    .wizard .nav-tabs > li a:hover {
        background: transparent;
    }

.wizard .tab-pane {
    position: relative;
    padding-top: 50px;
}

@media( max-width : 585px ) {

    .wizard {
        width: 90%;
        height: auto !important;
    }

    span.round-tab {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard li.active:after {
        content: " ";
        position: absolute;
        left: 35%;
    }
}

.wizard {
  .wizard-inner {
    li {
      &::before {
        border: none;
      }
    }
  }
}

/* ********************************************************************************************** */
.workshopradio div {
  clear: both;
  /*margin: 0 50px;*/
  overflow: hidden;
}

.workshopradio label {
  /*min-width: 400px;*/
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
}

.workshopradio input[type="radio"]:empty,
.workshopradio input[type="checkbox"]:empty {
  display: none;
}

.workshopradio input[type="radio"]:empty ~ label,
.workshopradio input[type="checkbox"]:empty ~ label {
  position: relative;
  /* line-height: 2.5em; */
  /*text-indent: 3.25em;*/
  padding-left: 3.25em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 75px;
}

.workshopradio input[type="radio"]:empty ~ label:before,
.workshopradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.workshopradio input[type="radio"]:hover:not(:checked) ~ label:before,
.workshopradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.workshopradio input[type="radio"]:hover:not(:checked) ~ label,
.workshopradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.workshopradio input[type="radio"]:checked ~ label:before,
.workshopradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.workshopradio input[type="radio"]:checked ~ label,
.workshopradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.workshopradio input[type="radio"]:focus ~ label:before,
.workshopradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.workshopradio-primary input[type="radio"]:checked ~ label:before,
.workshopradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #337ab7;
}

/************************************************************************/

.ln-register-workshop{
  select option:disabled{
    color: #ddd;
  }
}

.lnworld-statements-slider-controller {

  .vita {
    font-size: 14px;
  }

  .blockquote-center{
    margin: 0;
  }

  .blockquote-center footer,
  .blockquote footer{
    font-size: 90%;
    padding-top: 10px;
  }

  @media (min-width: $screen-sm-min) {
    .lnworld-top-slider-box {
      height: 180px;
    }
  }

  .lnworld-top-slider-box:before{
    content: "";
    vertical-align: middle;
    height: 100%;
    display: inline-block;
  }

}





