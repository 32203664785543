/** @deprecated, use imageTeaser.scss **/
.lnet-service-teaser {
  @extend .clearfix;
  position: relative;

  &__item {
    position: relative;
    display: block;
    margin-bottom: 15px;
  }

  &__image-wrapper {
    display: block;
    overflow: hidden;
  }

  &__image {
    display: block;
    max-width: 100%;
    height: auto;

    &--scale {
      transition: all .4s ease;
      transform: scale(1);

      &:hover {
        transform: scale(1.1) rotate(2deg);
      }
    }
  }

  &__text {
    display: block;
    width: 100%;
    padding: 5px;
    text-align: center;
    background-color: $ln-color-13;
    color: #fff;
  }

  &--4grid {
    .lnet-service-teaser__item {
      width: 50%;
      float: left;
      padding: 0 15px;
    }

    /** very simple flex box example **/
    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      .lnet-service-teaser__item {
        width: 25%;
        margin: 0 15px 0 0;
        padding: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
