/**
 * Created by RZI on 13.06.2016.
 */

.btn {
  border-radius: 0;
  white-space: normal;
}

.well {
  border-radius: 0;
  -moz-box-shadow: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.panel {
  border-radius: 0;
}

[class*="u-triangle-inclusive-v1"]:not([class*="__front"]):not([class*="__back"]), [class*="u-triangle-inclusive-v2"]:not([class*="__front"]):not([class*="__back"]) {
  position: absolute;
}

.dropdown-toggle::after{
  display: none;
}