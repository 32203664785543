/** WEB-171 @todo we have to build a new software-release-block **/
.software-release-box {

  .u-icon-v2 {
    vertical-align: middle;
  }

  .panel {
    border: 0;
    box-shadow: none;
  }

  .icon-block {
    white-space: nowrap;

    span {
      vertical-align: middle;
      display: block;

      @media (min-width: $screen-md-min) {
        display: inline-block;
      }

    }

  }

  .row {
    padding: 10px;
  }

  .row:nth-child(2n+3) {
    background-color: #E7ECF9;
  }

  .visible-download {
    display: none;
  }

  @media (min-width: $screen-md-min) {
    .visible-download {
      display: inline-block;
    }
  }

  /** WEB-171 workaround  **/
  .software-release-box__os-logo {
    width: 63px;
    height: 63px;
    font-size: 35px;
    position: relative;
    text-align: center;
    transition: all .2s ease-in-out;

    > i {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
}
