@mixin ln-list-underline {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      border-bottom: 1px solid $ln-gray-eaeaea;
      padding: .9375rem 0 .9375rem .9375rem;

      &:before {
        content: "\25AA";
        display: inline-block;
        font-size: 1.25rem;
        left: 0;
        line-height: 1.2;
        margin-right: .3125rem;
        position: absolute;
      }
    }
  }
}

@mixin ln-list-white {
  ul {
    font-weight: $ln-font-weight-light;
    list-style: none;
    margin: 0;
    padding: 0;

    &:before {
      background: #04a88c url('../images/icons/icon-tick.svg') no-repeat 50%/14px 10px;
      border-radius: 50%;
      content: "";
      display: block;
      height: 32px;
      position: absolute;
      right: -.75rem;
      top: -.75rem;
      width: 32px;
    }

    li {
      display: block;
      margin-bottom: .9375rem;
      padding-left: 1.5625rem;
      position: relative;

      &:before {
        background: url('../images/icons/icon-tick.svg') no-repeat 50%;
        content: "";
        display: block;
        height: 10px;
        left: 0;
        position: absolute;
        top: 5px;
        width: 14px;
      }
    }
  }
}
