.ln-location {
  $block: &;

  display: block;

  &__header {
    display: block;
  }

  &__body {
    display: block;
  }

  &__headline {
    display: block;
    margin: 0 0 1.875rem;
    color: $ln-nightblue;
  }

  &__image {
    display: block;
    vertical-align: middle;
    margin-bottom: 2.18rem;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__text {
    display: block;
    margin: 0 0 .75rem 0;
  }

  &__link {
    display: block;
    margin: 0 0 .75rem 0;
  }

  &__information {
    display: block;
    padding-bottom: 2.25rem;
  }

  &__information-wrapper {
    display: block;
  }

  // definition of item and its wrapped elements
  &__item {
    margin-bottom: 1rem;
    border-bottom: 1px solid $ln-gray-eaeaea;

    &:last-child {
      margin-bottom: 0;
    }

    #{$block}__headline {
      margin-bottom: 1rem;
      color: $ln-darkgray;
      font-weight: $ln-font-weight-medium;
    }
  }

  @media only screen and (max-width: $ln-breakpoint-md-max) {
    &__header {
      &--hide-mobile {
        display: none;
      }
    }

    &__image {
      &--hide-mobile {
        display: none;
      }
    }

    &__item {
      &--no-border-mobile {
        border: none;
      }
    }
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    &__item {
      #{$block}__headline {
        font-size: 1.125rem;
      }
    }

    &__information-wrapper {
      display: flex;
      flex-wrap: wrap;

      #{$block}__information {
        &:nth-child(odd) {
          width: 40%;
        }

        &:nth-child(even) {
          width: 60%;
          padding-left: .62rem;
        }
      }
    }
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    &__information {
      width: 85%;
    }
  }
}


// collapsiblePanel extension
.ln-collapsible-panel {
  .ln-location {
    padding: 1.875rem 1.875rem 0 1.875rem;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      padding: 0;
    }
  }
}
