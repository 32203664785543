@use "sass:math";

// display types
.dt {
  display: table;
  width: 100%;
}

.dtc {
  display: table-cell;
}

.dtr {
  display: table-row;
}

.db {
  display: block;
}

.dn {
  display: none !important;
}

.dib {
  display: inline-block;
}

// alignments
.vam {
  vertical-align: middle;
}

.vat {
  vertical-align: top;
}

.vab {
  vertical-align: bottom;
}

// text alignments
.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.tac {
  text-align: center !important;
}

.taj {
  text-align: justify !important;
}

// positions
.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}

.pos-fix {
  position: fixed !important;
}

// build widths
$widthRange: 10, 15, 20, 25, 30, 33, 35, 40, 45, 50, 55, 60, 65, 66, 70, 75, 80, 85, 90, 95, 100;
@each $value in $widthRange {
  .w#{$value} {
    width: percentage(math.div($value,100));
  }
}

// build margin and padding shortcuts
$spacings: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 100;
@each $space in $spacings {
  $value: $space + 'px';

  // margins
  .m#{$space} {
    margin: unquote($value) !important;
  }

  .mt#{$space} {
    margin-top: unquote($value) !important;
  }

  .mr#{$space} {
    margin-right: unquote($value) !important;
  }

  .mb#{$space} {
    margin-bottom: unquote($value) !important;
  }

  .ml#{$space} {
    margin-left: unquote($value) !important;
  }

  // paddings
  .p#{$space} {
    padding: unquote($value) !important;
  }

  .pt#{$space} {
    padding-top: unquote($value) !important;
  }

  .pr#{$space} {
    padding-right: unquote($value) !important;
  }

  .pb#{$space} {
    padding-bottom: unquote($value) !important;
  }

  .pl#{$space} {
    padding-left: unquote($value) !important;
  }

  .p-x-#{$space} {
    padding: 0 unquote($value);
  }
}

@media (max-width: $ln-breakpoint-sm-max) {
  .mt-20--sm {
    margin-top: 20px;
  }

  .mb-20--sm {
    margin-bottom: 20px;
  }
}

@media (max-width: $ln-breakpoint-md-max) {
  .ln-align-center--md {
    margin: 0 auto;
  }
}

@media (min-width: $ln-breakpoint-lg) {
  .ln-align-right--lg {
    margin-left: auto;
  }
}

.ln-my1 {
  margin: 1rem 0;
}

// helper
.ln-spacer {
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 1px;
  background: none;
  border: none;
  outline: none;
}
