.ln-gallery-guide {
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-bottom: 1px solid $ln-gray-999;

    &:first-child {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (min-width: $ln-breakpoint-md) {
      flex-direction: row;
    }
  }

  &__content {
    flex-basis: 100%;
    max-width: 100%;

    li {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (min-width: $ln-breakpoint-md) {
      flex-basis: 60%;
      max-width: 60%;
    }
  }

  &__asset {
    position: relative;
    flex-basis: 100%;
    max-width: 100%;

    @media (min-width: $ln-breakpoint-md) {
      flex-basis: 30%;
      max-width: 30%;
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__description {
    padding: 1rem;
  }
}
