.ln-customer-alert {
  background-color: $ln-midnightblue;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    padding-top: 12px;
    padding-left: 16px;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      flex-direction: row;
      justify-content: space-around;
    }

    /** define minimum spacing of items used in wrapper **/
    > * {
      margin-bottom: 12px !important; /** override bootstrap **/
      margin-right: 16px;
    }
  }

  &__badge {
    padding: 2px 8px;
    border-radius: 3px;
    background-color: #fff;
    color: $ln-lagoonblue;
    font-weight: $ln-font-weight-medium;
    white-space: nowrap;
  }

  &__text {
    text-align: center;
    color: #fff;

    p, strong {
      color: #fff;
    }

    p {
      margin: 0;
    }
  }

  &__link {
    margin: 0;
    border: #fff 2px solid;
    background: transparent;
    color: #fff;
    white-space: nowrap;
    transition: none;

    &:focus {
      border: #fff 2px solid;
      background: transparent;
    }

    &:active {
      border: #fff 2px solid;
      background: transparent;
    }

    &:hover {
      border: #fff 2px solid;
      background-color: #fff;
      color: $ln-lagoonblue;
    }
  }
}
