.ln-flyout-sidebar {
  display: block;
  height: 100%;
  width: ln-px-to-rem(320px);
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: ln-px-to-rem(-384px);
  z-index: 10999;
  padding: ln-px-to-rem(80px 13px 40px 13px);
  transition: all 0.5s ease-out;
  // old > background: $ln-gradient-lagoonblue-to-purple-to-bottom-left;
  // new
  background-color: $ln-midnightblue;
  color: #fff;

  // keep flyout from extending over screen edge for devices with very small screen width
  @media only screen and (min-width: 384px) {
    width: ln-px-to-rem(384px);
    padding-left: ln-px-to-rem(45px);
    padding-right: ln-px-to-rem(45px);
  }

  &--opened {
    right: 0;
    box-shadow: 0 0 16px 0 #000;
  }

  &__close {
    position: absolute;
    top: ln-px-to-rem(20px);
    right: ln-px-to-rem(32px);
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;

    i {
      font-size: 2.125rem;

      &:before {
        color: inherit;
      }
    }
  }

  &__headline {
    @include ln-headline-normal();
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &__horizontal-line {
    margin: ln-px-to-rem(20px 0);
    border-bottom: #fff 1px solid;
  }
}

.ln-flyout-sidebar-text-block {
  $block: &;

  display: block;

  &__headline {
    @include ln-headline-tiny();
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;

    @media (min-width: $ln-breakpoint-md) {
      margin-top: 1rem;
    }
  }

  &__text {
    display: block;
    color: #fff;

    p, strong {
      color: #fff;
    }

    a:not(.btn) {
      border: none;
      color: #fff;

      &:hover {
        border: none;
        color: $ln-electric-purple--hover;
      }
    }
  }

  &__link-icon {
    display: inline;
    margin-right: 0.625rem;

    i {
      font-size: 1.125rem;

      &:before {
        color: inherit;
      }
    }
  }

  a#{$block}__link, #{$block}__link {
    display: inline;
    border: none;
    color: #fff;
    font-weight: $ln-font-weight-medium;

    &:hover {
      border: none;
      color: $ln-electric-purple--hover;
    }
  }
}
