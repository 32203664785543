.ln-faq {
  &-status {
    margin-bottom: 1.875rem;

    &__indicator {
      text-align: center;

      i {
        font-size: 2.5rem;
      }
    }
  }

  &-list {
    &-item {
      background-color: #fff;
      border: 1px solid $ln-gray-e9ecef;
      margin-bottom: 1.125rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      &__header {
        padding: .688rem 1.2rem;
        cursor: pointer;
      }

      &__headline {
        display: block;
        margin: 0;
        color: $ln-darkgray;
        font-size: $ln-font-size-base;
      }

      &__body {
        padding: .688rem 1.2rem;
        border-top: 1px solid $ln-gray-e9ecef;
      }

      &__text, &__text p {
        font-size: $ln-font-size-base;
      }

      @media only screen and (min-width: $ln-breakpoint-lg) {
        &__headline {
          font-size: 1.125rem;
        }
      }
    }
  }

  &-filter {
    $block: &;

    @extend .clearfix;

    &__headline {
      @include ln-headline-default();
      display: block;
      font-size: 1.625rem;
      line-height: 2rem;
      margin-top: 0;
      margin-bottom: 20px;
      letter-spacing: -0.005em;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-flow: row wrap;
      padding: 0;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 100%;
      margin-bottom: 20px;
      padding: 5px 60px;
      background-color: $ln-green;
      border-color: $ln-green;
      color: #fff;
      cursor: pointer;
      position: relative;
      user-select: none;

      &:hover {
        background-color: $ln-darkcyan;
        border-color: $ln-darkcyan;
      }

      &-text {
        display: inline-block;
        text-align: center;
        font-size: .875rem;
        line-height: 1.125rem;
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate(-50%, -50%);
        height: 40px;

        > i {
          &::before {
            color: #fff;
            font-size: 2.5rem;
          }
        }
      }

      &-icon-badge {
        display: none;
        position: absolute;
        left: auto;
        right: -15px;
        top: -15px;
        border-radius: 50%;
        background-color: $ln-base-color;
        color: $ln-color-white;
        width: 30px;
        line-height: 1.75rem;
        height: 30px;
        z-index: 9;

        > i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      &--active {
        background-color: $ln-darkcyan;
        border-color: $ln-darkcyan;

        &:hover {
          background-color: $ln-darkcyan;
          border-color: $ln-darkcyan;
        }

        #{$block}__item-icon-badge {
          display: block;
        }
      }

      &--raspberry {
        background-color: $ln-raspberry;
        border-color: $ln-raspberry;

        &:hover {
          background-color: change-color($ln-berry-shade-7, $alpha: 0.9);
          border-color: change-color($ln-berry-shade-7, $alpha: 0.9);
        }
      }
    }

    @media only screen and (min-width: $ln-breakpoint-px-md) {
      &__wrapper {
        justify-content: flex-start;
      }

      &__item {
        margin-right: 35px;
        width: 280px;
      }
    }
  }
}
