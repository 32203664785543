/**
 * Created by AMA on 16.05.2017.
 */
/*
label.required:before {
    content: "* ";
}
*/

.control-label.required:after {
  content: " *";
}

main .container ul.list-unstyled {
  padding-left: 0;

  li:before {
    border: none;
  }
}

main .ao-form {
  .ao-input-block {
    padding-right: 15px;
  }

  h4 {
    margin-bottom: 20px;
  }

  input[type="radio"], input[type="checkbox"] {
    margin: 0;
  }

  label.ao-form-label {
    line-height: 24px;
  }

  input:not([type="radio"]):not([type="checkbox"]) {
    height: 34px;
    font-size: 14px;
    line-height: 1.42857;
    padding: 6px 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
  }

  button.ao-form-submit {
    color: $ln-color-white;
    background-color: $ln-color-13;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
  }

  label.ao-combo-label {
    font-weight: normal;
  }

  input.ao-form-error,
  textarea.ao-form-error,
  select.ao-form-error {
    border-color: $state-danger-text;
  }

  .ao-form-error-message {
    color: $state-danger-text;
  }
}

/* fix icon at help block */
.form-group {
  &.has-error {
    .help-block {
      i {
        margin-right: 10px;
      }
    }
  }
}

.form-control {
  border-radius: 0;
  background-color: $ln-gray-f5f5f5;
  color: $ln-darkgray;
  padding: .5rem .75rem
}
