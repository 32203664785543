.ln-filter {
  $block: &;

  margin-top: 2.5rem;
  display: block;
  padding: 1.5625rem 1.875rem;
  margin-bottom: 1.875rem;
  background: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
  color: #fff;

  &__item {
    display: inline-block;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: 100%;
  }

  &__select {
    height: auto;
    align-items: center;
    border: 0;
    background-color: #fff;
    font-size: 1rem;
    line-height: 1;
    font-weight: 300;
    padding: .5rem .75rem;
    text-overflow: ellipsis;

    option {
      font-weight: 300;
      line-height: 1.2;
      padding: .1875rem 0;
    }

    &--border-grey {
      box-shadow: none;
      border: 1px solid #333;
    }
  }

  &__btn {
    height: 2.5rem;
    line-height: 1.3;
    margin-top: 0;
  }

  &--flex, &__container-flex {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;

    #{$block}__item:nth-of-type(1n+1) {
      margin-top: .62rem;
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    padding: 1.5625rem 2.5rem;

    &__item {
      width: 33.33%;
    }

    &__btn {
      line-height: 1;
    }

    &--flex, &__container-flex {
      flex-direction: row;
      align-items: center;

      #{$block}__item {
        flex: 2 2 0px; // px is for IE compatibility reasons

        &--flex-grow-zero {
          flex: 1 1 0px; // px is for IE compatibility reasons
        }
      }
    }

    &__container-flex {
      &--flex-end {
        justify-content: flex-end;

        #{$block}__item {
          flex: 0; // px is for IE compatibility reasons
        }
      }
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    padding: 3.125rem 4.688rem;

    &__select {
      font-size: 1.125rem;
    }
  }
}
