.articleline {

  .articleDate {
    margin: $container-xs-margin-top auto 0 auto;
    padding: 0;
  }

 .articleDate .articleDate-inner{
    width: 108px;
    margin: 0 auto;
    padding: 12px 0;
    text-align: center;
    border: 2px solid $ln-color-03;

    .day{
      font-size: 30px;
      line-height: 50px;
    }

    .year{
      font-size: 24px;
      line-height: 30px;
    }
  }

  .articleContent {
    margin-top: $container-xs-margin-bottom;
    margin-bottom: $container-xs-margin-bottom;

    > div:last-of-type {
      padding-bottom:  $container-xs-margin-bottom;
      border-bottom: 1px solid $ln-divider-color;
    }
  }

  @media(min-width: $screen-sm-min) {
    .articleContent {
      min-height: 110px;
      margin-top: 0;
      padding-bottom: $container-md-margin-bottom;
    }

    .articleDate {
      margin-top: 0;
      margin-bottom: $container-md-margin-bottom;
    }
  }

  @media(min-width: $screen-lg-min){
    .articleContent .articleDate {
      margin-top: $container-lg-margin-top;
      padding: 12px 0;
    }

    .articleContent > div:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }

  }
}

