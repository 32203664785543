.reference-company{
  background-color:  #ffffff;
  margin: 10px;
  height: 120px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid $ln-gray-f5f5f5;

  a {
    border:0;
  }
}

#checkboxCaseStudies,
#checkboxVideo,
#checkboxStatement{
  display: none;
}