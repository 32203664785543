$ln-teaser-box-shadow: 2px 2px 36px 20px change-color($ln-black, $alpha: 0.03);

.ln-teaser {
  $block: &;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &--center {
    justify-content: center;
  }

  &__grid-item {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    @include ln-grid-items();
    margin-bottom: 1.25rem;
    outline: 0 !important;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    color: $ln-darkgray;
    background-color: #fff;
    box-shadow: $ln-teaser-box-shadow;
    cursor: pointer;
    transition: box-shadow .5s ease, transform .5s ease;
    outline: 0 !important;

    &--shadow {
      box-shadow: $ln-teaser-box-shadow !important;
    }

    &--no-shadow {
      box-shadow: none;
    }

    &--no-pointer {
      cursor: default;
    }

    &--border {
      border: 1px solid $ln-gray;

      &:hover {
        border: 1px solid $ln-gray !important;
      }
    }

    &--hover-has-pointer {
      // nothing to do here, it is only important when item is hovered, viewport >= $ln-breakpoint-lg
    }

    &:hover {
      border-bottom: 0;
    }

    // only hover >= 992px
    @media screen and (min-width: $ln-breakpoint-lg) {
      &:hover {
        color: $ln-darkgray;
        text-decoration: none;
      }

      &--scale {
        &:hover {
          box-shadow: 2px 2px 26px 15px change-color($ln-black, $alpha: 0.17);
          transform: scale(1.05);
        }
      }

      &--hover-has-pointer {
        &:hover {
          cursor: pointer !important;
        }
      }
    }

    &:focus {
      text-decoration: none;
    }
  }

  // btn exception and cta exception if ln-teaser__item is an a tag
  a#{ $block }__item,
  #{ $block }__item#{ $block }__item--hover-has-pointer {
    &:hover {
      /* old
      .btn-green, .btn-primary {
        color: $ln-white;
        background-color: $ln-darkcyan;
        border: 2px solid $ln-darkcyan;
        text-decoration: none;
      }

      .cta {
        color: $ln-green;
        border-bottom: 1px solid $ln-green;
        text-decoration: none;

        &::after {
          right: -1.125rem;
        }
      }*/

      .btn-green, .btn-primary {
        color: $ln-white;
        background-color: $ln-electric-purple--hover;
        border: 2px solid $ln-electric-purple--hover;
        text-decoration: none;
      }

      .cta {
        color: $ln-white;
        border-bottom: 1px solid $ln-electric-purple--hover;
        text-decoration: none;

        &::after {
          right: -1.125rem;
        }
      }
    }

    strong {
      color: inherit;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 1.875rem 2.1875rem;

    &--zero-padding-top {
      padding-top: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
    padding: .625rem 2.1875rem 1.875rem;
    justify-content: flex-start;

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    &--block {
      padding: 0;
      text-align: center;

      > .btn {
        width: 100%;
        margin: 0;
      }
    }

    &--column {
      flex-direction: column;
      text-align: center;

      > .btn {
        width: 100%;
        margin: 0 0 0.375rem 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    &--large {
      > .btn {
        padding: .625rem 1.5625rem;

        @media screen and (min-width: $ln-breakpoint-md) {
          font-size: 1rem;
          margin-right: .625rem;
          padding: .9375rem 1.5625rem;
        }
      }
    }

    // fix for dynamicSoftwareCardBlock
    // if buttons will be delivered from ckeditor
    &--editor-links {
      flex-direction: column;
      align-items: stretch;

      > p {
        > a {
          margin-top: 0;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: .625rem 2.1875rem 1.875rem;
  }

  &__image {
    max-width: 100%;
    height: auto;
    vertical-align: middle;

    img {
      width: 100%;
    }

    &--frame {
      padding: 2.188rem 2.188rem 0;
    }

    &--logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8.125rem;
      padding: 2.188rem;
      border-bottom: 1px solid $ln-lightgray;

      img {
        width: auto;
        max-height: 100%;
      }

      @media screen and (min-width: $ln-breakpoint-px-lg) {
        height: 10.625rem;
      }
    }
  }

  &__headline {
    display: inline-block;
    color: $ln-nightblue;
    @include ln-headline-teaser();

    &--darkgray {
      color: $ln-darkgray;
    }
  }

  &__header {
    padding: .1875rem 2.1875rem;
    border-bottom: 1px solid $ln-gray-eaeaea;

    #{$block}__headline {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    &--no-border {
      border-bottom: none;
    }
  }

  &__category {
    display: inline-block;
    margin-bottom: .3125rem;
    font-size: .875rem;
  }

  &__meta {
    display: inline-block;
    font-size: .8125rem;
    color: $ln-gray-999;
  }

  &__text {
    @include ln-text-p();

    hr {
      margin: 10px 0;
    }
  }

  &__icon {
    position: relative;
    width: 6rem;
    height: 6rem;
    margin: 0 auto 30px;
    box-shadow: 2px 2px 10px 5px change-color($ln-nightblue, $alpha: 0.02);
    border-radius: 50%;
    background-color: $ln-nightblue;

    &--no-shadow {
      box-shadow: none;
    }

    &--gradient {
      background: $ln-gradient-lagoonblue-to-purple-to-bottom-left;
    }

    > i {
      height: 2.6875rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 43px; // @todo px to rem
      line-height: 1;
      // overrides default color
      &::before {
        color: #fff;
      }

      &[class="is-digit"] {
        color: #fff;
        top: 47%;
        font-style: normal !important;
      }

      // lucanet font specials
      &[class="ln-icon-fast-implementation"] {
        top: 47%;
        left: 47%;
      }

      &[class="ln-icon-close"] {
        left: 48%;
      }
    }

    @media screen and (min-width: $ln-breakpoint-lg) {
      width: 8.75rem;
      height: 8.75rem;

      > i {
        height: 4.125rem;
        font-size: 70px; // @todo px to rem
      }
    }
  }

  &__badge {
    @include ln-badge();
  }

  // icon with badge
  &__icon {
    #{ $block }__badge {
      right: -.625rem;
      top: .3125rem;
    }

    @media screen and (min-width: $ln-breakpoint-lg) {
      #{ $block }__badge {
        right: 0;
      }
    }
  }

  // item types
  &__item {
    &--award {
      cursor: default;
      text-align: center;

      #{ $block }__content {
        padding: 2.1875rem
      }

      #{ $block }__image {
        margin-bottom: 1.25rem;
        text-align: center;

        img {
          max-height: 8.75rem;
          width: auto !important;
        }
      }
    }

    &--benefit {
      cursor: default;
      text-align: center;

      #{ $block }__headline {
        font-size: 1.375rem;
      }
    }

    &--event-location {
      #{ $block }__headline {
        margin-bottom: 0.62rem;
      }

      a {
        line-height: 1.2rem;
      }
    }

    &--usp {
      cursor: default;
      text-align: center;
    }

    &--counter {
      cursor: default;
      text-align: center;
      box-shadow: none;
      background-color: transparent;

      #{ $block }__headline {
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: .375rem;

        @media screen and(min-width: $ln-breakpoint-lg) {
          font-size: 3.75rem;
        }
      }
    }

    &--horizontal {
      height: auto;
      cursor: default;
      text-align: center;
      box-shadow: none;

      @media screen and (min-width: $ln-breakpoint-lg) {
        #{ $block }__body {
          flex-direction: row-reverse;

          #{ $block }__wrapper {
            flex-basis: 70%;
          }
        }
      }

      &--center {
        #{ $block }__body {
          flex-direction: column !important;
        }
      }
    }

    &--blog {
      #{ $block }__body {
        padding: 1.875rem 2.1875rem 0;
      }

      #{ $block }__footer {
        margin-top: 1.875rem;
        padding: 0 2.1875rem 1.875rem;

        > .btn {
          margin: 0;
          @media screen and(min-width: $ln-breakpoint-md) {
            padding: .75rem 1.375rem;
          }
        }
      }

      #{ $block }__headline {
        margin: .625rem 0 .9375rem;
        color: $ln-darkgray;
      }
    }

    &--image-teaser {
      #{ $block }__meta {
        color: $ln-darkgray;
      }

      #{ $block }__headline {
        margin-bottom: .625rem;
      }
    }

    &--use-case {
      #{ $block }__body {
        padding: 1.875rem 2.1875rem 1.25rem;
      }

      #{ $block }__footer {
        > .btn {
          padding: .625rem 1.5625rem;
          font-size: .8125rem;

          @media screen and (min-width: $ln-breakpoint-md) {
            padding: .75rem 1.375rem;
            font-size: 1rem;
          }
        }
      }
    }

    &--person {
      max-width: 28.75rem;
      width: 100%;

      #{ $block }__headline {
        margin-bottom: 0;
      }

      @media (min-width: $ln-breakpoint-sm) and (max-width: $ln-breakpoint-md-max) {
        flex-direction: row;
        max-width: 100%;
        width: 100%;

        #{ $block }__image {
          height: auto;
          max-width: 204px;
        }
      }
    }

    &--recruiting {
      @media only screen and (max-width: $ln-breakpoint-md-max) {
        @include ln-teaser-theme-border($block, $ln-green);
      }

      @media only screen and (min-width: $ln-breakpoint-lg) {
        @include ln-teaser-theme-border($block, $ln-green, $ln-white, 'left');
      }
    }

    &--gray {
      background-color: $ln-gray-f5f5f5;
    }
  }

  // fix usp modifier on a tags
  a#{ $block }__item {
    &--usp {
      cursor: pointer;
    }
  }

  // horizontal fix
  &__grid-item {
    &:nth-child(even) {
      #{ $block }__item {
        &--horizontal {
          @media screen and (min-width: $ln-breakpoint-lg) {
            #{ $block }__body {
              flex-direction: row;
            }
          }
        }
      }
    }
  }

  // theming
  &__item {
    @each $color, $value in $ln-colors {
      &--icon-#{$color} {
        @include ln-teaser-theme-icon($block, $value);
      }
    }

    @each $color, $value in $ln-colors {
      &--border-#{$color} {
        @include ln-teaser-theme-border($block, $value);
      }
    }

    @each $color, $value in $ln-colors {
      &--highlight-#{$color} {
        @include ln-teaser-theme-highlight($block, $value);
      }
    }
  }

  // grid
  // default 4er grid
  @media only screen and (min-width: $ln-breakpoint-md) {
    #{ $block }__grid-item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media only screen and (min-width: $ln-breakpoint-xl) {
    #{ $block }__grid-item {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &--grid-1 {
    #{ $block }__grid-item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &--grid-2 {
    @media only screen and (min-width: $ln-breakpoint-md) {
      justify-content: center;
      #{ $block }__grid-item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  &--grid-3 {
    @media only screen and (min-width: $ln-breakpoint-md) {
      justify-content: center;
      #{ $block }__grid-item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-xl) {
      #{ $block }__grid-item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }

  &--grid-persons {
    margin: 0 auto;

    @media (min-width: $ln-breakpoint-sm) {
      margin: 0;
    }

    @media (min-width: $ln-breakpoint-sm) and (max-width: $ln-breakpoint-md-max) {
      #{ $block }__grid-item {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      #{ $block }__grid-item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    @media (min-width: $ln-breakpoint-xl) {
      #{ $block }__grid-item {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  &--grid-left {
    justify-content: flex-start;
  }
}

// bootstrap 3 - fix margin-left and margin-right, useful in container > row > col-* > ln-teaser context
// could be important when using grid-2 modifier, have a look at the blog
.container .row {
  .ln-teaser {
    &--fix-bootstrap {
      @include ln-bootstrap-compensation();
    }
  }
}

// ln-container-flex
// change padding for lower viewports
// turn off cursor pointer
.ln-container-flex {
  .ln-teaser__item {
    cursor: default;
  }

  @media screen and (max-width: $ln-breakpoint-sm-max) {
    .ln-teaser__grid-item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: $ln-breakpoint-md) and (max-width: $ln-breakpoint-md-max) {
    .ln-teaser__grid-item {
      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }
}
