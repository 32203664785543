/**
 * Created by RZI on 21.12.2016.
 */
.documents-line {
    margin-bottom: 65px;
}

.documents-description .clearfix{
  clear: both;
}

.document-cover-bg {
  background-color: lighten($ln-color-10,1%);
  background-color: lighten($ln-color-10,3%);
  background-color: lighten($ln-color-10,5%);
}

@media (max-width: $screen-xs-max) {
  .documents-line {
    margin-bottom: 0;

    .documents-cover {
      margin-top: $container-xs-margin-top;
    }

    .documents-description {
      margin-bottom: $container-xs-margin-bottom;
    }
  }
}
