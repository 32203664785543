.career-block {
  background-color: $ln-color-white;
  padding-bottom: 60px;

  > .career-block-wrapper {
    padding-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .career-search {
  }

  .career-list-headline {
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
    margin-bottom: 10px;

    .career-list-headline-item {
      text-decoration: none;
    }
  }

  .career-list-body {
    .career-list-body-row {
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid $ln-color-light-gray;

      &:hover {
        background-color: $ln-color-light-gray;
      }
    }

    .career-list-body-label {
      a {
        display: block;
        width: 100%;
      }
    }
  }

  .career-detail-block {
    .career-detail-block-headline {
      text-transform: uppercase;
    }
  }

  .career-form {
    .career-form-item {
      display: block;
    }

    &.dnd {
      .career-form-droparea {
        width: 80%;
        background-color: $ln-color-07;
        border: 1px dashed #ccc;
        border-radius: 5px;
        margin: 25px auto;
        padding: 20px;
        color: #fff;
        position: relative;

        .career-form-files {
          width: 1px;
          height: 1px;
          line-height: 1px;
          font-size: 1px;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .career-form-user-files {
      display: none;

      &.has-content {
        display: block;
      }

      .career-form-filelist-item {
        display: block;
      }
    }
  }
}