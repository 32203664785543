.ln-sticky-sidebar {
  $block: &;

  display: none;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 90;

  @media only screen and (min-width: $ln-breakpoint-xl) {
    display: block;
  }

  &--show-mobile {
    display: block;
  }

  &__btn {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.3125rem;
    margin: 0;
    font-size: 0.75rem;

    @media only screen and (min-width: $ln-breakpoint-xl) {
      width: 5rem;
      height: 4.0625rem;
      padding: 0.5rem;
    }

    i {
      display: block;
      font-size: 1.75rem;
      line-height: 1.9375rem;

      &:before {
        color: inherit;
      }
    }

    &--md {
      height: 5.3125rem;
    }

    &--xl {
      @media only screen and (min-width: $ln-breakpoint-xl) {
        width: 6.5rem;
        height: 6.0625rem;
      }
    }
  }

  &__btn-label {
    display: none;

    @media only screen and (min-width: $ln-breakpoint-xl) {
      display: inline;
    }
  }
}
