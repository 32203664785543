// idea taken from bootstrap
:root {
  @each $color, $value in $ln-colors {
    --#{$color}: #{$value};
  }

  @each $name, $value in $ln-breakpoints-px {
    --breakpoint-#{$name}: #{$value};
  }
}
