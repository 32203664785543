.lnet-statement {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: block;

  &__image {
    width: 100%;
    height: 100px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper-text {
    display: block;

    blockquote {
      padding: 10px 0;
    }
  }

  &__text {
    font-size: 14px;
  }

  &__footer {
    font-size: 90%;
    padding-top: 10px;
  }

  @media only screen and (min-width: $screen-md-min) {
    height: $ln-statement-slider-height-md;
    display: flex;
    align-items: center;

    &__image {
      display: block;
      width: 25%;
      height: auto;
    }

    &__wrapper-text {
      width: 75%;
      blockquote {
        padding: 10px 20px;
      }
    }
  }
}
