.ln-document-list {
  margin-bottom: 0;

  @media (min-width: $ln-breakpoint-md) {
    margin-bottom: 65px;
  }
}

.ln-document-teaser-item {
  $self: &;
  display: block;
  color: $ln-darkgray;
  margin-bottom: 20px;
  border: 0;

  &:hover {
    border: 0;
  }

  @media (min-width: $ln-breakpoint-md) {
    margin-bottom: 0;
  }

  // imitate bootstrap row
  @include ln-clearfix();
  margin-right: -15px;
  margin-left: -15px;

  &__image {
    text-align: center;

    img.img-responsive {
      display: inline-block !important; // overwrite img-responsive class
    }

    @media (min-width: $ln-breakpoint-md) {
      img.img-responsive {
        display: block !important;
      }
    }
  }

  &__date,
  &__edition {
    font-size: .8125rem;
    color: $ln-gray-999;
  }

  &__headline {
    color: inherit;
    margin-top: 0;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 1rem;
    strong {
      color: $ln-darkgray;
    }
  }

  &__cta {
    color: inherit;
  }

  &__wrapper {
    display: block;
  }

  &:hover,
  &:active,
  &:focus {
    color: $ln-green;

    #{$self}__text {
      strong {
        color: $ln-green;
      }
    }
  }
}
