main .container ul.social-menu{
  padding-left: 0;

  li{
    text-indent: 0;
    font-size: 30px;

    a{
      color: $ln-base-dark-blue;
    }

    &:before{
      content: '';
      border: none;
      left: 0;
    }
  }
}