.career-block {
  background-color: $ln-color-white;
  padding-bottom: 60px;

  .career-detail-headline--primary {
    color: #406486;
  }

  .career-detail-headline--secondary {
    color: $ln-base-color-ln-feature-1845;
  }

  > .career-block-wrapper {
    padding-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .career-search {
  }

  .career-list-headline {
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
    margin-bottom: 10px;

    .career-list-headline-item {
      text-decoration: none;
    }
  }

  .career-list-body {
    .career-list-body-row {
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid $ln-color-light-gray;

      &:hover {
        background-color: $ln-color-light-gray;
      }
    }

    .career-list-body-label {
      a {
        display: block;
        width: 100%;
      }
    }
  }

  .career-detail-block {
    .career-detail-block-headline {
      text-transform: uppercase;
      color: #406486;
      font-size: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      letter-spacing: 0;
    }

    ul {
      /* override styling in elements which came from raw html */
      padding-left: 20px !important;
    }
  }
}

.career-info-box {
  @extend .clearfix;
  padding: 30px;
  background-color: #f2f4f7;
  color: #406486;

  hr {
    color: #406486;
    height: 1px;
    background-color: #406486;
    margin: 5px 15px 5px;
  }

  .career-info-box-headline {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: -0.005em;
    text-transform: uppercase;
  }

  .career-benefits {
    @extend .clearfix;
    font-size: 18px;

    // adapt bootstrap
    .career-info-box-headline {
      padding: 0 15px;
    }

    .career-benefits-icon, .career-benefits-item {
      height: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    i {
      font-size: 24px;
    }

    .career-benefits-item {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
    }
  }

  .career-contact, .career-social {
    margin: 20px 0;
  }

  .career-contact {
    margin: 20px 0;
    color: #406486;

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .career-social {
    text-align: center;

    @media only screen and (min-width: 768px) {
      text-align: right;
    }

    .icon {
      width: 34px;
      height: 34px;
      display: inline-block;
      background: #406486;
      color: white;
      font-size: 22px;
      text-align: center;
      vertical-align: middle;
      border-radius: 17px;
    }

    a {
      text-decoration: none;

      &.btn-cta-purple {
        margin: 0 0 20px 0;
        padding: 10px 35px;
        border-radius: 0;
        font-size: 30px;
      }
    }

    ul {
      padding-left: 0;
      margin-left: -5px;
      list-style: none;

      li {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        list-style: none;

        &:before {
          content: none;
          border: none;
          height: auto;
          width: auto;
          position: relative;
          top: 0;
          left: 0;
        }

        a {
          line-height: 31.4px;
        }
      }
    }
  }
}

.career-form {
  position: relative;
  padding: 60px 0;
  background-color: $ln-color-white;
  color: $ln-base-color-ln-feature-1845;

  a.career-form-link-target-header-fix {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    top: -70px;
    line-height: 1px;
    font-size: 1px;
    opacity: 0;

    @media (min-width: $screen-sm-min) {
      top: -90px;
    }
  }

  ul.ln-form-multiple-file-list {
    margin-top: 15px;
    padding-left: 20px;
  }

  button {
    min-width: 60px;

    i {
      margin-right: 8px;
    }

    .submit-spinner {
      display: none;
    }

    &[disabled] {
      .submit-text {
        display: none;
      }
      .submit-spinner {
        display: block;
      }
    }
  }
}