.ln-subsidiary-recommendation {
  display: block;
  position: relative;
  height: 350px;
  z-index: 3;

  @media (min-width: $ln-breakpoint-lg) {
    height: 450px;
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    height: 500px;
  }

  @media screen and (min-width: $ln-breakpoint-xxl) {
    height: 550px;
  }

  @media screen and (min-width: $ln-breakpoint-xxxl) {
    height: 650px;
  }

  &__content {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0.625rem;
    text-align: center;
    transform: translateY(-50%) translateX(-50%);

    @media only screen and (min-width: $ln-breakpoint-lg) {
      width: 40%;
      top: 15%;
      left: 0;
      transform: translateX(0%);
      text-align: left;
      background-color: change_color($ln-white, $alpha: 0.8%);
    }
  }

  &__headline, &__subline {
    margin: 0;
    font-weight: $ln-font-weight-light;
    color: #fff;

    strong {
      color: inherit;
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      color: $ln-nightblue;
    }
  }

  &__headline {
    text-transform: uppercase;
  }

  &__subline {
    margin-top: 20px;
    font-size: 1.375rem;
    line-height: 1.1;

    @media screen and (min-width: $ln-breakpoint-lg) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: $ln-breakpoint-xl) {
      font-size: 1.75rem;
    }

    @media screen and (min-width: $ln-breakpoint-xxl) {
      font-size: 2rem;
    }
  }
}

.ln-subsidiary-recommendation-chooser {
  display: block;
  padding: 25px;
  margin: 0 auto;
  text-align: center;

  > *:first-child {
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 630px;
    transform: translateX(0%);

    > * {
      margin: 0;

      &:first-child {
        margin: 0;
      }
    }
  }
}
