/**
 * Created by RZI on 07.03.2016.
 */

$overview-box-image-height: 145px;
$overview-box-button-height: 58px;
$overview-box-height: $overview-box-image-height + $overview-box-button-height;

.overview-panel {
  height: $overview-box-height;
  margin-bottom: 24px;

  .image {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: $overview-box-image-height;
  }

  .button-holder {
    height: $overview-box-button-height;
    @include linear-gradient($ln-gradient1-color-from, $ln-gradient1-color-to);

    &:hover {
      background: $ln-color-05;
      @include linear-gradient($ln-color-05, $ln-color-05);
    }

    a.button {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      text-align: center;
      color: #ffffff;
    }
  }
}





