/**
 * Created by RZI on 07.03.2016.
 *
 */

$font-stack-1: 'Open Sans', sans-serif;
$font-stack-2: 'Open Sans Condensed', sans-serif;
$font-stack-3: 'Chalkduster Regular'; // FIXME: not available yet

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-size-lg-icon: 66px;
$font-size-md-icon: 44px;

$letter-spacing-normal: 25;
$letter-spacing-more: 40;

$font-size-md-h1: 20px;
$font-size-md-h2: 20px;
$font-size-md-h3: 16px;

$font-size-sm-h1: 24px;
$font-size-sm-h2: 20px;
$font-size-sm-h3: 18px;

$font-size-xs-h1: 20px;
$font-size-xs-h2: 18px;
$font-size-xs-h3: 16px;
