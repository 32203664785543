.ln-counter {
  margin: 1.875rem auto .75rem;
  max-width: 200px;

  &__title {
    font-size: 1.125rem;
    font-weight: $ln-font-weight-medium;
    margin-bottom: .625rem;
    text-align: center;
  }

  &__value {
    border-bottom: 1px solid $ln-gray-999;
    margin-bottom: 1.25rem;
    padding-bottom: .75rem;
    text-align: center;
  }

  &__number {
    display: inline-block;
    font-size: 3.125rem;
    color: $ln-nightblue;
    line-height: 1;
  }

  &__unit {
    display: inline-block;
    font-size: 2.25rem;
    color: $ln-green;
    padding-left: 5px;
  }

  &__info {
    text-align: center;
    line-height: 1.2;
    font-weight: $ln-font-weight-base;

    p {
      font-weight: $ln-font-weight-base;
    }
  }

  &__source {
    display: block;
    text-align: center;
    font-weight: 300;
    color: #999;
    font-size: .875rem;
  }

  @media (min-width: $ln-breakpoint-lg) {
    &__title {
      font-size:1.375rem;
    }

    &__number {
      font-size: 4.375rem;
    }

    &__unit {
      font-size: 1.875rem;
    }
  }
}
