.ln-form {
  padding: 1.25rem 8.5% 3.5rem 8.5%;
  background-color: $ln-gray-f5f5f5;

  [class*="ln-headline"] {
    color: $ln-nightblue;
  }

  &--bg-none {
    background: none;
  }

  form {
    padding-top: 1.25rem;
  }

  hr {
    margin-top: 0;
    margin-bottom: 1.25rem;
    border-color: $ln-gray-e9ecef;
  }

  select, textarea, input[type=text], input[type=email], input[type=date] {
    height: calc(1.5em + 2px + 1.37rem);
    border-color: $ln-gray-e9ecef;
    box-shadow: none;
    font-size: 1rem;
    font-weight: $ln-font-weight-light;
    line-height: 1.5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      // colors do not match prototype (uses exotic colors)
      border-color: $ln-lagoonblue;
      box-shadow: 0 0 0 0.2rem change_color($ln-nightblue, $alpha: .25);
    }
  }

  select {
    appearance: none;
    padding: 0.688rem 1.75rem 0.688rem 1.2rem;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/0.5rem .625rem;

    option {
      font-weight: $ln-font-weight-light;
    }

    &::-ms-expand {
      display: none;
    }
  }

  textarea {
    height: initial;
    padding: .688rem 1.2rem;
    background-color: #fff;
  }

  input[type=text], input[type=email], input[type=date] {
    padding: .688rem 1.2rem;
    background-color: #fff;
  }

  &__headline, &__headline p {
    @include ln-headline-tiny();
    color: $ln-nightblue;
  }

  // is applicable to all labels in form
  &__text, label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #000;
    font-weight: $ln-font-weight-light;

    small {
      line-height: 1.375;
      font-weight: $ln-font-weight-light;
      font-size: .875rem;
    }

    strong {
      color: #000;
    }
  }

  // style radio controls and checkboxes
  .radio {
    display: inline-block;
    padding: 0;
    margin: 0 1rem 0 0;
  }

  .checkbox {
    display: block;
    padding: 0;
    margin: .25rem 0 0 0;

    // checkbox labels can also be used without input field
    label {
      padding: 0;
      margin: 0 0 0 2.125rem;
    }
  }

  input[type=radio], input[type=checkbox] {
    position: initial;
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin: 0;
    vertical-align: top;
    opacity: 0;
    z-index: -1;

    & ~ label {
      position: relative;
      max-width: calc(100% - 2.125rem); // leave space for input
      padding: 0;
      margin: 0 0 0 0.5rem;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: -2.125rem;
        width: 1.625rem;
        height: 1.625rem;
        border: 1px solid $ln-gray-e9ecef;
        background-color: #fff;
        content: "";
        transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      }

      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: -2.125rem;
        width: 1.625rem;
        height: 1.625rem;
        background: no-repeat 50%/50% 50%;
        content: "";
      }
    }

    &:active ~ label::before {
      border-color: $ln-lagoonblue;
      background-color: $ln-lagoonblue;
    }

    &:focus ~ label::before {
      box-shadow: 0 0 0 0.2rem change_color($ln-nightblue, $alpha: .25);
      border-color: $ln-lagoonblue;
    }

    &:checked ~ label::before {
      border-color: $ln-nightblue;
      background-color: $ln-nightblue;
    }
  }

  input[type=radio] {
    & ~ label {
      &::before {
        border-radius: 50%;
      }
    }

    &:checked ~ label::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
    }
  }

  input[type=checkbox] {
    & ~ label {
      &::before {
        border-radius: .25rem;
      }
    }

    &:checked ~ label::after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
    }
  }

  @media only screen and (prefers-reduced-motion: reduce) {
    input[type=radio], input[type=checkbox] {
      & ~ label {
        &::before {
          transition: none;
        }
      }
    }

    select, textarea, input[type=text], input[type=email] {
      transition: none;
    }
  }

  @media only screen and (min-width: $ln-breakpoint-lg) {
    label {
      font-size: 1.125rem;
    }
  }
}

// Honeypot class
.ln-form-hp-control {
  opacity: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;

  input[type=text], label, .control-label {
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }
}
