.ln-btn-back-to-top {
  position: fixed;
  display: block;
  //flex-direction: row;
  //justify-content: center;
  //align-items: flex-end;
  z-index: 10;
  width: 43px;
  height: 43px;
  bottom: 1.875rem;
  right: 30px;
  color: #fff;
  background: $ln-gray-999;
  transition: all ease 0.5s;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  box-shadow: none;
  border: 0;
  outline: 0;

  i {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:before {
      font-size: 1.4rem;
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    width: 52px;
    height: 52px;

    i {
      &:before {
        font-size: 2rem;
      }
    }
  }

  @media (min-width: $ln-breakpoint-xxxl) {
    right: 50%;
    transform: translateX(calc((#{$ln-breakpoint-xxl} / 2) + 1.875rem));
  }

  &--show {
    visibility: visible;
    opacity: 0.7;

    &:hover {
      background-color: $ln-electric-purple--hover;
      opacity: 1;
    }
  }
}
