.ln-event {
  $listBlock: &;

  &-filter {
    display: block;
    padding: 1.5625rem 1.875rem;
    margin-bottom: 1.875rem;
    background: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
    color: #fff;

    &__headline {
      display: block;
      margin-top: 0;
      color: inherit;
    }

    &__text {
      display: block;
      color: inherit;
    }

    // override form-control
    .form-control {
      height: auto;
      margin-top: 0.62rem;
      border-radius: 0;
      color: $ln-nightblue;
      font-size: 1.12rem;
      font-weight: 300;

      optgroup {
        color: $ln-gray-b3b3b3;
        font-size: 1.12rem;
        font-weight: 300;
      }

      option {
        color: $ln-nightblue;
        font-size: 1.12rem;
        font-weight: 300;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-md) {
      padding: 1.5625rem 2.5rem;

      .form-control {
        margin-top: 0;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      padding: 3.125rem 4.6875rem;
    }
  }

  // "event appointment list"
  &-list {
    $block: &;

    &__item {
      display: block;
      position: relative;

      > a:not(.gotoadmin) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        padding: 1.563rem 0;
        margin-bottom: 1.875rem;
        border: none;
        box-shadow: 2px 2px 36px 20px change-color($ln-black, $alpha: 0.03);
        color: inherit;
        font-weight: inherit;
        transition: box-shadow .5s ease, transform .5s ease;

        &:hover {
          border: none;
        }
      }
    }

    &__date {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      line-height: .91666666666;
      margin-bottom: 1.25rem;
      padding: 0 0.938rem;
      color: $ln-nightblue;

      &:after {
        content: "";
        position: static;
        display: block;
        width: calc(100% - 0.313rem);
        margin: 1.375rem 0.313rem 0;
        border-bottom: 1px solid $ln-gray-e8e8e8;
      }
    }

    &__day {
      display: block;
      margin: 0 auto;
      font-size: 3.75rem;
      font-weight: $ln-font-weight-base;
    }

    &__month {
      display: block;
      margin: 0 auto;
      font-size: 1.875rem;
      font-weight: $ln-font-weight-light;
      text-transform: uppercase;
    }

    &__content {
      display: block;
      padding: 0 0.938rem;
      text-align: center;
    }

    &__category {
      display: block;
      margin-bottom: 0.3125rem;
      font-size: 1.12rem;
      font-weight: $ln-font-weight-medium;
      color: $ln-darkgray;
    }

    &__title {
      margin: 0 0 0.75rem;
      font-size: 1.375rem;
      line-height: 1.22727272727;
      color: $ln-nightblue;
    }

    &__additional-info {
      display: block;
      margin-bottom: 0.3125rem;
    }

    &__location {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.12rem;
      font-weight: $ln-font-weight-light;
      margin-bottom: 0.75rem;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: .6875rem;
        height: 1rem;
        margin-right: 0.3rem;
        color: $ln-gray-999;

        // ie 11 hack @see https://www.mediaevent.de/css/conditional-comments.html
        @media (min-width: 0 \0
        ) {
          margin-right: 0.488rem;
        }
      }
    }

    &__more {
      position: relative;
      margin-right: 0.8em;
      color: $ln-green;
      border-bottom: 1px dotted $ln-green;
      font-weight: $ln-font-weight-medium;

      &:before {
        content: "» ";
        position: absolute;
        top: -0.219rem;
        left: 100%;
        margin-left: .3em;
        text-decoration: none;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-md) {
      &__item {
        > a:not(.gotoadmin) {
          flex-direction: row-reverse;
          justify-content: flex-end;
          padding: 3.125rem 0;

          &:hover {
            box-shadow: 2px 2px 26px 15px change-color($ln-black, $alpha: 0.17);
            transform: scale(1.03);
          }
        }
      }

      &__date {
        flex: 0 0 auto;
        width: 162px;
        margin-bottom: 0;
        border-right: 1px solid $ln-gray-e8e8e8;

        &:after {
          content: none;
          display: none;
        }
      }

      &__content {
        border-left: 1px solid $ln-gray-e8e8e8;
        margin-left: -1px;
        padding: 0 3.125rem;
        text-align: left;
      }

      &__location {
        justify-content: flex-start;
      }

      &__more {
        font-size: 1.125rem;
      }
    }
  }

  &-appointment-list {
    $block: &;

    display: block;
    margin-bottom: 1rem;

    &__headline {
      @include ln-headline_teaser();
      margin-bottom: 0.375rem;
      color: $ln-nightblue;
    }

    &__text {
      @include ln-text-p();
      display: block;

      &--bold {
        font-weight: $ln-font-weight-medium;
      }
    }

    &__cta {
      font-size: 1rem;
    }

    &__date {
      display: flex;
      align-items: center;
      width: 7.875rem;
      color: #000;
      font-weight: $ln-font-weight-medium;
      line-height: 1.6875rem;

      &--full-width {
        width: 100%;
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        margin-right: .375rem;
        color: $ln-gray-999;
      }
    }

    &__time {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: $ln-font-weight-light;
      line-height: 1.6875rem;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
        margin-right: .375rem;
        color: $ln-gray-999;
      }
    }

    &__location {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: $ln-font-weight-light;
      line-height: 1.6875rem;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: .6875rem;
        height: 1rem;
        margin-right: .375rem;
        color: $ln-gray-999;
      }
    }

    &__additional-information {
      display: block;
      color: #000;
      font-weight: $ln-font-weight-light;
      line-height: 1.6875rem;
    }

    &__date-time {
      display: flex;
      flex-direction: column;
    }

    &__register {
      display: none;
    }

    &__item {
      display: block;
      position: relative;
      padding: 0.5rem 0;
      border-bottom: 1px solid $ln-gray-e6e6e6;
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      &--sidebar {
        #{$block}__cta {
          font-size: 1.25rem;
        }
      }

      &__date-time {
        flex-direction: row;
      }
    }
  }

  &-contact-link {
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: inherit;
    margin-bottom: 0.375rem;
    border: none;

    &:hover {
      color: $ln-green;
      border: none;
    }

    i {
      display: none;
      position: relative;
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 1rem;
      background-color: $ln-green;
      color: #fff;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media only screen and (min-width: $ln-breakpoint-xl) {
      i {
        display: block;
      }
    }
  }

  &-person-list {
    $personList: &;

    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    @include ln-bootstrap-compensation();

    #{$listBlock}-person {
      flex-basis: 50%;
      max-width: 50%;
      @include ln-grid-items();
    }

    #{$personList}-wrapper {
      align-items: inherit;
      flex-flow: inherit;

      // bootrstrap collapse hack
      &[aria-expanded="true"],
      &.collapsing{
        display: inherit;
        width: 100%; // for ie11
      }
    }
  }

  &-person {
    position: relative;
    margin-bottom: 1rem;

    &__image {
      margin-bottom: 1rem;

      img {
        margin-bottom: .875rem;
      }

      figcaption {
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  &-price {
    display: block;

    &__cta {
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
    }

    &__desc {
      display: none;
      margin-top: 15px;
    }
  }

  &-table-of-content {
    margin-bottom: 1rem;
    font-size: $ln-font-size-base;
    font-weight: $ln-font-weight-light;

    table {
      width: 100%;
    }

    td, th {
      padding: 1rem 1.25rem;
      vertical-align: top;
      width: 70%;

      &:first-of-type {
        width: 30%;
      }
    }

    &--striped {
      tr {
        &:nth-of-type(2n+1) {
          background-color: $ln-gray-f5f5f5;
        }
      }
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      font-size: 1.125rem;
    }
  }

  &-pre-line {
    display: block;
    margin-bottom: 1rem;
    font-weight: $ln-font-weight-medium;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      font-size: 1.125rem;
      text-indent: .1rem;
    }
  }
}
