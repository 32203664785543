/**
 * Created by RZI on 13.09.2016.
 */

.page-preview-boxes {
  .page-thumbnail {
    position:relative;
    overflow:hidden;
  }

  .page-caption {
      position:absolute;
      top:0;
      right:0;
      background:rgba(90, 90, 90, 0.75);
      width:100%;
      height:100%;
      padding:2%;
      display: none;
      text-align: left;
      color:#fff !important;
      z-index:2;
  }
}

