@use "sass:math";
// copy grid logic from bootstrap

@mixin ln-grid-items($gutter: $ln-grid-gutter-width) {
  padding-left:  ceil(math.div($gutter, 2));
  padding-right: floor(math.div($gutter, 2));
}

@mixin ln-bootstrap-compensation($gutter: $ln-grid-gutter-width) {
  margin-left:  ceil(math.div($gutter, -2));
  margin-right: floor(math.div($gutter, -2));
}
