.ln-gallery-image {
  $block: &;

  position: relative;
  display: block;
  width: 100%;

  > a {
    outline: none;

    &:hover {
      border: none;
    }
  }

  &--cursor-zoom-in {
    cursor: zoom-in;

    > a {
      cursor: zoom-in;
    }
  }

  &__badge {
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    background-color: $ln-green--80;
    transition: transform .2s ease;
    box-shadow: 0 0 10px change_color($ln-white, $alpha: 0.3); // hsla (0, 0%, 100%, .5)
    pointer-events: none;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;

      // fix play sign positioning
      &.fa-play {
        text-indent: .2em;
      }
    }

    #{$block}:hover & {
      transform: scale(1.1);
    }

    &--center {
      top: 50%;
      right: 50%;
      left: auto;
      transform: translateX(50%) translateY(-50%);

      #{$block}:hover & {
        transform: translateX(50%) translateY(-50%) scale(1.1);
      }
    }

    &--big {
      width: 4.5rem;
      height: 4.5rem;
      font-size: 1.7rem;
    }
  }
}

.ln-gallery-video {
  position: relative;
  display: block;
  width: 100%;
}
