.theme-fos {
  display: flex;
  flex-direction: column;

  background-color: #F8F8F8;
  max-width: 100vw;
  max-height: 100vh;
  /* hide scrollbar */
  overflow: hidden;

  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
    background-color: #1B254D;
    background-image: none;

    @media (max-width: $ln-breakpoint-md) {
      padding: 1.5rem .75rem;
    }

    @media (min-width: $ln-breakpoint-md) {
      padding: 2rem;
    }
  }
}

.ln-fos-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  &__item {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 1.5rem;

    .btn {
      margin-top: 0;
    }

    &--center {
      text-align: center;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    font-size: 1.125rem;

    > div {
      width: 80%;
      text-align: center;
    }
  }

  &__logo {
    text-align: center;

    img {
      width: 50%;
    }
  }

  &__image {
    text-align: center;

    img {
      width: 45%;
      transform: translateX(-10%);
    }
  }

  @media (min-width: $ln-breakpoint-sm) {
    width: 50%;

    &__item {
      margin-bottom: 1.75rem;
    }

    &__logo {
      img {
        width: 80%;
      }
    }

    &__image {
      img {
        width: 60%;
      }
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    width: 45%;

    &__info {
      font-size: 1.25rem;
    }

    &__logo {
      img {
        width: 70%;
      }
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    width: 35%;

    &__item {
      margin-bottom: 2rem;
    }

    &__logo {
      img {
        width: 70%;
      }
    }
  }

  @media (min-width: $ln-breakpoint-xl) {
    width: 25%;

    &__info {
      font-size: 1.375rem;
    }
  }

  @media (min-width: $ln-breakpoint-xxxl) {
    &__logo {
      img {
        width: 60%;
      }
    }

    &__image {
      img {
        width: 50%;
      }
    }
  }
}
