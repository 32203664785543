/*
table {
  th {
    background-color: $ln-nightblue;
    color: #fff;

    strong, p {
      color: #fff;
    }
  }
}
*/

.full-width-block {
  table {
    $block: &;
    width: 100%;
    border: 1px solid $ln-darkgray;
    border-collapse: separate;
    padding: 5px;

    strong, p {
      font-size: inherit;
    }

    th {
      overflow: hidden;
      background-color: transparent;
      padding: 4px;
      font-weight: $ln-font-weight-light;
      line-height: $ln-line-height-base;
      color: $ln-darkgray;
    }

    td {
      font-weight: $ln-font-weight-light;
      line-height: $ln-line-height-base;
      color: $ln-darkgray;
      text-overflow: ellipsis;
      word-wrap: break-word;
      vertical-align: top;
      padding: 4px 4px 5px 4px;
      font-size: inherit;
    }

    th {
      font-weight: $ln-font-weight-medium;
      border-bottom: 1px solid $ln-darkgray;
    }

    tr {
      td {
        border-bottom: 1px solid $ln-darkgray;
      }

      td:first-child {
        margin-left: 5px;
      }
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
