.ln-fpm {
  $block: &;

  position: relative;
  margin-top: 1.875rem;
  min-height: 32.25rem;

  @media (min-width: $ln-breakpoint-lg) {
    min-height: 28.125rem;
    padding-bottom: 9.375rem;
  }

  @media (min-width: $ln-breakpoint-xl) {
    min-height: 32.25rem;
    padding-bottom: 0;
  }

  &__text-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__headline {
    display: flex;
    justify-content: center;
    pointer-events: none;
    text-align: center;
    text-transform: uppercase;

    p {
      padding: 0 15px;
      font-size: 1.375rem;

      strong {
        display: block;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12.5rem;
      height: 12.5rem;
      transform: translate(-50%, -50%);
      align-items: center;
      background: #fff;
      border-radius: 50%;
      font-size: 1.0625rem;
      z-index: 30;

      p {
        font-size: 1.0625rem;
      }
    }

    @media (min-width: $ln-breakpoint-xl) {
      width: 15.625rem;
      height: 15.625rem;
      font-size: 1.375rem;

      p {
        font-size: 1.375rem;
      }
    }
  }

  &__text {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 3.75rem 0 0;

    @media (min-width: $ln-breakpoint-md) {
      flex: 0 0 50%;
      margin: 3.75rem .75rem 0;
      max-width: calc(50% - 24px);
    }

    @media (min-width: $ln-breakpoint-lg) {
      position: absolute;
      top: 25%;
      width: 40%;
      margin: 0;
      border-top: 1px solid $ln-gray-999;
    }
  }

  &__text-headline {
    display: block;
    border: 0;
    color: $ln-nightblue;
    font-size: 1.375rem;
    margin: 2.1875rem 0 .625rem;
    pointer-events: auto;
    font-weight: $ln-font-weight-base;

    &:hover {
      border: 0;
    }

    @media (min-width: $ln-breakpoint-lg) {
      margin: 1.25rem 0 .625rem;
    }
  }

  a#{ $block }__text-headline {
    &:hover {
      color: $ln-green;
    }
  }

  &__list {
    line-height: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
    pointer-events: auto;

    > li {
      position: relative;
      padding-left: .9375rem;

      ::before {
        content: "\BB";
        display: block;
        position: absolute;
        left: 0;
        margin-right: 5px;
      }
    }

    a {
      font-weight: $ln-font-weight-light;
      color: $ln-darkgray;
      border: 0;

      &:hover {
        border: 0;
        color: $ln-green;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      font-size: .875rem;
      line-height: 1.75rem;
    }

    @media (min-width: $ln-breakpoint-xl) {
      font-size: 1.125rem;

      a {
        font-size: 1rem;
      }
    }
  }

  &__icon {
    position: absolute;
    width: 4.875rem;
    height: 4.875rem;
    border: 3px solid #fff;
    border-radius: 50%;
    pointer-events: none;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 2.5rem;
      line-height: .5;

      // lucanet font specials
      &[class="ln-icon-fast-implementation"] {
        top: 47%;
        left: 47%;
      }

      &[class="ln-icon-close"] {
        left: 48%;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      width: 3.75rem;
      height: 3.75rem;
      border: 2px solid #fff;
      z-index: 28;
    }

    @media (min-width: $ln-breakpoint-xl) {
      width: 4.875rem;
      height: 4.875rem;
      border: 3px solid #fff;
    }
  }

  &__circle-wrapper {
    display: none;

    @media (min-width: $ln-breakpoint-lg) {
      display: block;
      position: absolute;
      width: 25rem;
      height: 25rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 26;
    }

    @media (min-width: $ln-breakpoint-xl) {
      height: 32.25rem;
      width: 32.25rem;
    }
  }

  &__circle-shadow {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 23rem;
    height: 23rem;
    transform: translate(-50%, -50%);
    background: transparent;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    z-index: 28;
    pointer-events: none;

    @media (min-width: $ln-breakpoint-lg) {
      display: block;
      width: 17.75rem;
      height: 17.75rem;
    }

    @media (min-width: $ln-breakpoint-xl) {
      width: 23rem;
      height: 23rem;
    }
  }

  &__circle {
    display: none;
    position: absolute;
    width: 16.125rem;
    height: 16.125rem;
    border: 0;
    transition: background-color .5s ease;
    z-index: 28;

    &:hover {
      border: 0;
    }

    @media (min-width: $ln-breakpoint-lg) {
      display: block;
      width: 12.4375rem;
      height: 12.4375rem;
    }

    @media (min-width: $ln-breakpoint-xl) {
      width: 16.125rem;
      height: 16.125rem;
    }

    &--inner {
      display: block;
      position: absolute;
      width: 11.5rem;
      height: 11.5rem;
      pointer-events: none;

      @media (min-width: $ln-breakpoint-lg) {
        width: 8.875rem;
        height: 8.875rem;
      }

      @media (min-width: $ln-breakpoint-xl) {
        width: 11.5rem;
        height: 11.5rem;
      }
    }
  }

  &__line {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 29;

    &--vertical {
      width: 2px;
      height: 100%;
    }

    &--horizontal {
      width: 100%;
      height: 2px;
    }

    @media (min-width: $ln-breakpoint-lg) {
      display: block;
    }
  }

  // positioning and theming
  &__text {
    &--top-right {
      padding: 1.25rem;
      background-color: #f8e6f1;

      #{ $block }__icon {
        left: 50%;
        top: -38px;
        transform: translateX(-50%);
        background: #bf278d;
      }

      @media (min-width: $ln-breakpoint-lg) {
        top: 24%;
        right: 0;
        padding: 0 0 0 15%;
        background: none;

        #{ $block }__icon {
          left: -28px;
          transform: none;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        top: 28%;

        #{ $block }__icon {
          top: -48px;
          left: -28px;
        }
      }

      @media (min-width: $ln-breakpoint-xxl) {
        top: 25%;

        #{ $block }__icon {
          top: -38px;
          left: -38px;
        }
      }
    }

    &--bottom-right {
      padding: 1.25rem;
      background-color: #f1e8ee;

      #{ $block }__icon {
        top: -38px;
        left: 50%;
        transform: translateX(-50%);
        background: $ln-berry-shade-7;
      }

      @media (min-width: $ln-breakpoint-lg) {
        top: 68%;
        right: 0;
        padding: 0 0 0 15%;
        background: none;

        #{ $block }__icon {
          left: -28px;
          transform: none;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        top: 72%;

        #{ $block }__icon {
          top: -28px;
          left: -28px;
        }
      }

      @media (min-width: $ln-breakpoint-xxl) {
        top: 75%;

        #{ $block }__icon {
          top: -38px;
          left: -38px;
        }
      }
    }

    &--bottom-left {
      padding: 1.25rem;
      background-color: #e7eaf8;

      #{ $block }__icon {
        top: -38px;
        right: 50%;
        transform: translateX(50%);
        background: $ln-nightblue;
      }

      @media (min-width: $ln-breakpoint-lg) {
        top: 68%;
        padding: 0 15% 0 0;
        background: none;

        #{ $block }__icon {
          right: -28px;
          transform: none;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        top: 72%;

        #{ $block }__icon {
          top: -28px;
          right: -28px;
        }
      }

      @media (min-width: $ln-breakpoint-xxl) {
        top: 75%;

        #{ $block }__icon {
          top: -38px;
          right: -38px;
        }
      }
    }

    &--top-left {
      padding: 1.25rem;
      background-color: #e0eef6;

      #{ $block }__icon {
        top: -38px;
        right: 50%;
        transform: translateX(50%);
        background: $ln-lagoonblue;
      }

      @media (min-width: $ln-breakpoint-lg) {
        top: 24%;
        padding: 0 15% 0 0;
        background: none;

        #{ $block }__icon {
          right: -28px;
          transform: none;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        top: 28%;

        #{ $block }__icon {
          top: -48px;
          right: -28px;
        }
      }

      @media (min-width: $ln-breakpoint-xxl) {
        top: 25%;

        #{ $block }__icon {
          top: -38px;
          right: -38px;
        }
      }
    }
  }

  &__circle {
    $circle: &;

    &--top-left {
      top: 0;
      left: 0;
      background: rgba(0, 139, 210, .1);
      border-radius: 258px 0 0;

      &:hover {
        background: rgba(0, 139, 210, .4);
      }

      #{ $circle }--inner {
        background-color: $ln-lagoonblue;
      }

      @media (min-width: $ln-breakpoint-lg) {
        border-radius: 199px 0 0;

        #{ $circle }--inner {
          top: 58px;
          left: 58px;
          border-radius: 142px 0 0;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        border-radius: 258px 0 0;

        #{ $circle }--inner {
          top: 74px;
          left: 74px;
          border-radius: 184px 0 0;
        }
      }
    }

    &--top-right {
      top: 0;
      right: 0;
      background: rgba(191, 39, 141, .1);
      border-radius: 0 258px 0 0;

      &:hover {
        background-color: rgba(191, 39, 141, .4);
      }

      #{ $circle }--inner {
        background-color: #bf278d;
      }

      @media (min-width: $ln-breakpoint-lg) {
        border-radius: 0 199px 0 0;

        #{ $circle }--inner {
          top: 58px;
          right: 58px;
          border-radius: 0 142px 0 0;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        border-radius: 0 258px 0 0;

        #{ $circle }--inner {
          top: 74px;
          right: 74px;
          border-radius: 0 184px 0 0;
        }
      }
    }

    &--bottom-left {
      left: 0;
      bottom: 0;
      background: rgba(32, 42, 96, .1);
      border-radius: 0 0 0 258px;

      &:hover {
        background-color: rgba(32, 42, 96, .4);
      }

      #{ $circle }--inner {
        background-color: $ln-nightblue;
      }

      @media (min-width: $ln-breakpoint-lg) {
        border-radius: 0 0 0 199px;

        #{ $circle }--inner {
          left: 58px;
          bottom: 58px;
          border-radius: 0 0 0 142px;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        border-radius: 0 0 0 258px;

        #{ $circle }--inner {
          left: 74px;
          bottom: 74px;
          border-radius: 0 0 0 184px;
        }
      }
    }

    &--bottom-right {
      right: 0;
      bottom: 0;
      background: rgba(129, 28, 94, .1);
      border-radius: 0 0 258px;

      &:hover {
        background: rgba(129, 28, 94, .4);
      }

      #{ $circle }--inner {
        background-color: $ln-berry-shade-7;
      }

      @media (min-width: $ln-breakpoint-lg) {
        border-radius: 0 0 199px;

        #{ $circle }--inner {
          right: 58px;
          bottom: 58px;
          border-radius: 0 0 142px;
        }
      }

      @media (min-width: $ln-breakpoint-xl) {
        border-radius: 0 0 258px;

        #{ $circle }--inner {
          right: 74px;
          bottom: 74px;
          border-radius: 0 0 184px;
        }
      }
    }
  }
}
