@mixin ln-background-image($backgrounds) {

  @each $name, $value in $ln-breakpoints {
    $image: map_get($backgrounds, $name);

    @if $image {
      @media only screen and (min-width: $value) {
        background-image: url($ln-background-path-rel + $image);
      }
    }
  }
}
