#consulting-office-nav{
  margin-top: $container-lg-margin-top;
  margin-bottom: $container-lg-margin-bottom;
  border-bottom-color:  $ln-base-color;
  padding-left: 0px;
  margin-left:0;


  li{
    margin-bottom: -3px;
  }

  li:not(.active) a{
    background-color: $ln-color-light-gray;
    border-color: $ln-base-color;
    border-bottom-color: transparent;

  }

  li.active a{
    border-color: $ln-base-color;
    border-bottom-color: transparent;
  }
}

#consulting-office-nav:before{
  border:none;
}

#consulting-office-nav.nav-tabs-justified > .active > a,
#consulting-office-nav.nav-tabs.nav-justified > .active > a,
#consulting-office-nav.nav-tabs-justified > .active > a:hover,
#consulting-office-nav.nav-tabs.nav-justified > .active > a:hover,
#consulting-office-nav.nav-tabs-justified > .active > a:focus,
#consulting-office-nav.nav-tabs.nav-justified > .active > a:focus{
  border-color: $ln-base-color;
}

#consulting-office-nav.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a{
  border-bottom-color: $ln-base-color;
}

#consultants .fa-chevron-down:hover{
  cursor: pointer;

}


.input-group.search-consultant{

  .btn{
    height:34px;
    margin-top:-1px;
  }

}
