.btn {
  display: inline-block;
  margin-top: 1.25rem;
  padding: .625rem 1.5625rem;
  vertical-align: middle;
  border-radius: 0;
  border: 2px solid $ln-green;
  color: #fff;
  background-color: $ln-green;
  box-shadow: none;
  font-size: .8125rem;
  font-weight: $ln-font-weight-base;
  line-height: $ln-line-height-base;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  outline: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  @media (min-width: $ln-breakpoint-md) {
    padding: .75rem 1.375rem;
    margin: 2.5rem .625rem 0 0;
    font-size: $ln-font-size-base;
  }

  &--small-margin {
    margin: .25rem .75rem .75rem 0;
  }

  &--zero-margin {
    margin: 0;
  }

  &--icon {
    font-size: 1rem;
    line-height: 1;
    padding: .3125rem;
    width: 1.875rem;
    height: 1.875rem;
    margin: 2.5rem .625rem 0 0;
  }

  &:hover {
    background-color: $ln-green;
    border: 2px solid $ln-green;
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: 0;
    color: #fff;
    background-color: $ln-nightblue;
    border-color: $ln-nightblue;
    box-shadow: none;
  }

  &.disabled,
  &[disabled] {
    @include ln-button-disabled();
  }

  &.gotoadmin {
    position: absolute;
    top: 0;
    width: 2.375rem;
    height: 2.375rem;
    margin: 0;
    color: $ln-darkgray !important;
    background-color: #fff !important;
    border: 1px solid $ln-darkgray !important;
    font-size: .875rem !important;
    line-height: .875rem !important;
    text-decoration: none !important;

    > i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      background-color: $ln-green !important;

      > i {
        color: #fff;
      }
    }
  }
}

a {
  &.btn {
    &.disabled {
      @include ln-button-disabled();
    }
  }
}

/* old
.btn-green, .btn-primary {
  color: $ln-white;
  background-color: $ln-green;
  border: 2px solid $ln-green;
  text-decoration: none;

  &:hover {
    color: $ln-white;
    background-color: $ln-darkcyan;
    border: 2px solid $ln-darkcyan;
    text-decoration: none;
  }
}
*/

// > new
.btn-green, .btn-primary {
  color: $ln-white;
  background-color: $ln-electric-purple;
  border: 2px solid $ln-electric-purple;
  text-decoration: none;

  &:hover {
    color: $ln-white;
    background-color: $ln-electric-purple--hover;
    border: 2px solid $ln-electric-purple--hover;
    text-decoration: none;
  }
}

.btn-raspberry {
  color: $ln-white;
  background-color: $ln-raspberry;
  border: 2px solid $ln-raspberry;
  text-decoration: none;

  &:hover {
    color: $ln-white;
    background-color: change-color($ln-berry-shade-7, $alpha: 0.9);
    border: 2px solid change-color($ln-berry-shade-7, $alpha: 0.9);
    text-decoration: none;
  }
}

.btn-oak {
  color: $ln-white;
  background-color: $ln-oak;
  border: 2px solid $ln-oak;
  text-decoration: none;

  &:hover {
    color: $ln-white;
    background-color: $ln-moderate-orange;
    border: 2px solid $ln-moderate-orange;
    text-decoration: none;
  }
}

.btn-white {
  color: $ln-nightblue;
  background-color: $ln-white;
  border: 2px solid $ln-white;
  text-decoration: none;

  &:hover {
    color: $ln-white;
    background-color: $ln-nightblue;
    border: 2px solid $ln-nightblue;
    text-decoration: none;
  }
}

.btn-nightblue-outline {
  border: 2px solid $ln-nightblue;
  color: $ln-nightblue;
  background: transparent;
  text-decoration: none;

  &:hover {
    border: 2px solid $ln-nightblue;
    background-color: $ln-nightblue;
    color: $ln-white;
    text-decoration: none;
  }
}

.btn-white-outline {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;

  &:hover {
    color: #fff;
    background-color: $ln-nightblue;
    border: 2px solid $ln-nightblue
  }
}

.ln-btn__spinner {
  $root: &;

  min-width: 60px;

  i {
    margin-right: 8px;
  }

  &--active {
    display: none;
  }

  &.disabled,
  &[disabled] {
    #{ $root }--inactive {
      display: none;
    }

    #{ $root }--active {
      display: block;
    }
  }
}
