.ln-metastream {
  background-repeat: no-repeat;
  background-size: 100% auto;

  &--top {
    background-position: top
  }

  &--top-middle {
    background-position: center 20%
  }

  &--middle {
    background-position: 50%
  }

  &--bottom {
    background-position: bottom
  }

  &--bottom-middle {
    background-position: center 70%;
  }

  &--one {
    @include ln-background-image($ln-meta-stream-one);
  }

  &--two {
    @include ln-background-image($ln-meta-stream-two);
  }

  &--three {
    @include ln-background-image($ln-meta-stream-three);
  }

  &--four {
    @include ln-background-image($ln-meta-stream-four);
  }

  &--five {
    @include ln-background-image($ln-meta-stream-five);
  }

  &--six {
    @include ln-background-image($ln-meta-stream-six);
  }
}
