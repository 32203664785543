.company-info{

  .image-holder{
    border: 1px solid $ln-color-08;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  dt:not(:first-of-type){
    margin-top: 10px;
  }

  dt {
    font-weight: 500;
  }
}