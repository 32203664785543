/** Define main section distance to the amount of stagebar, topmenu and header. */
body {
  padding-top: $ln-header-height-xs;

  &.has-stagebar {
    /** header height + stagebar height **/
    padding-top: calc(#{$ln-header-height-xs} + #{$ln-stagebar-height-base});

    .ln-top-menu {
      top: $ln-stagebar-height-base; // height from .lnet-stage-bar
    }

    .ln-header {
      top: $ln-stagebar-height-base;
    }

    .ln-navigation {
      top: $ln-stagebar-height-base;
    }

    .ln-mega-menu {
      top: calc(#{$ln-stagebar-height-base} + #{$ln-navigation-topbar-height-base});
      max-height: calc(#{$ln-mega-menu-height-base} - #{$ln-navigation-topbar-height-base} - #{$ln-stagebar-height-base});
    }

    .ln-navigation__topbar {
      top: $ln-stagebar-height-base;
    }
  }

  @media (min-width: $ln-breakpoint-md) {
    padding-top: $ln-header-height-base;

    &.has-stagebar {
      /** header height + stagebar height **/
      padding-top: calc(#{$ln-header-height-base} + #{$ln-stagebar-height-base});
    }
  }

  @media (min-width: $ln-breakpoint-xl) {

    /** header height  + stagebar height **/
    &.has-stagebar {
      padding-top: calc(#{$ln-header-height-base} + #{$ln-stagebar-height-base});

      .ln-navigation {
        top: 0;
      }

      .ln-mega-menu {
        top: calc(#{$ln-header-height-base} + #{$ln-stagebar-height-base});
        max-height: 0;
      }
    }

    /** header + topmenu **/
    &.has-topmenu {
      padding-top: calc(#{$ln-header-height-base} + #{$ln-top-menu-height-base});

      .ln-header {
        top: $ln-top-menu-height-base;
      }

      .ln-mega-menu {
        top: calc(#{$ln-header-height-base} + #{$ln-top-menu-height-base});
      }
    }

    /** header height + stagebar height + top menu height**/
    &.has-topmenu.has-stagebar {
      padding-top: calc(#{$ln-header-height-base} + #{$ln-stagebar-height-base} + #{$ln-top-menu-height-base});

      .ln-header {
        top: calc(#{$ln-stagebar-height-base} + #{$ln-top-menu-height-base});
      }

      .ln-mega-menu {
        top:calc(#{$ln-header-height-base} + #{$ln-stagebar-height-base} + #{$ln-top-menu-height-base});
      }
    }
  }
}

body {
  &.has-mobile-menu {
    overflow: hidden;
  }

  @media (min-width: $ln-breakpoint-xl) {
    &.has-mobile-menu {
      overflow: auto;
    }
  }
}

// ckeditor exception
body.cke_editable {
  padding-top: 0;
}

/** used to compensate for outer container that already introduces a padding and outer grid width **/
.content-block-area {
  .container {
    max-width: 100% !important;
    width: 100% !important; // override bootstrap
    padding-left: 0;
    padding-right: 0;
  }

  .full-width-block {
    padding: 0 !important;
  }
}
