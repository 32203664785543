/**
 * Created by rzi on 05.02.2017.
 */

#topStatements {
  background-color: $ln-color-white;

  .owl-stage-outer {
    height: 280px;
  }

}

.top-statement-box {
  min-height: 280px;
  margin: .938rem 0;
  font-size: 14px;


  .statement-position {
    min-height: 0;

    @media (min-width: $screen-md-min) {
      min-height: 50px;
    }
  }

  .top-statement-box__represent {
    &--headline {
      font-weight: 500;
    }
  }


  .image-block {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 10px;

    @media (min-width: $screen-md-min) {
      width: 120px;
      height: 120px;
    }
  }

  .blockquote-center {
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    color: $ln-base-color-ln-feature-1845 !important; // override bootstrap

    > p {
      @media (min-width: $screen-md-min) {
        height: 140px;
      }

      height: auto;
      padding: 10px;
    }

    footer {
      color: inherit;
    }
  }

  footer {
    font-size: 90%;
    padding-top: 10px;

    &:before {
      content: "";
    }

    p:before,
    p:after,
    p:first-of-type:before,
    p:first-of-type:after {
      content: "";
    }

    .btn {
      width: 50%;
    }
  }

  cite, em {
    font-style: normal;
  }

  &:before {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    content: "";
  }
}

