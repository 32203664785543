.service-block {
  padding-top: 15px;

  @media (min-width: $screen-md-min) {
    .service-block-content {
      height: 150px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .service-block-content {
      height: 100px;
      margin-bottom: 10px;
    }
  }
}