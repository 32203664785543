/**
 * Created by RZI on 20.09.2016.
 */

.overview-holder {
    .img-container {
      overflow: hidden;
    }
  
    img {
      -webkit-transition: all .4s ease;
       -moz-transition: all .4s ease;
       -ms-transition: all .4s ease;
       -o-transition: all .4s ease;
       transition: all .4s ease;
    }

    &:hover {
      img {
        transform: scale(1.1) rotate(2deg);
      }
    }
  
  }
