/**
 * Created by Robert on 19.09.2016.
 */
.image-holder {

   img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray;
     -webkit-transition: all .4s ease;
     -moz-transition: all .4s ease;
     -ms-transition: all .4s ease;
     -o-transition: all .4s ease;
     transition: all .4s ease;

    &:hover {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%);
      filter: none;
      -ms-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

  &.colored {

   img {
    filter:none;
    -webkit-filter: none;
   }

   &:hover {}
  }

}
