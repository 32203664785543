// fix for ckeditor dropdowns
.cke_panel_block {
  all: initial;

  * {
    all: unset;

    :hover,
    :focus,
    :active,
    :visited,
    :last-child,
    :first-child {
      all: unset;
    }
  }

  .cke_panel_grouptitle {
    display: block;
    cursor: default;
    font-size: 11px;
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    padding: 6px 6px 7px 6px;
    color: #484848;
    border-bottom: 1px solid #d1d1d1;
    background: #f8f8f8;
  }

  .cke_panel_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    white-space: nowrap;
  }

  .cke_panel_listItem {
    margin: 0;
    padding: 0;

    a {
      display: block;
      padding: 6px 7px;
      color: inherit !important;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #e9e9e9;
      }

      h1, h2, h3, h4, h5, h6, address, div, p {
        font-family: sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: normal;
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: 0;
      }

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 14px;
      }

      h4 {
        font-size: 12px;
      }

      h5 {
        font-size: 10px;
      }

      h6 {
        font-size: 8px;
      }

      pre {
        font-family: monospace;
        font-size: 12px;
        color: rgb(51, 51, 51);
      }

      address {
        font-size: 12px;
        color: rgb(51, 51, 51);
        font-style: italic;
      }

      div, p {
        font-size: 12px;
      }
    }
  }
}

.cke_editable {
  .ln-spacer-line {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
