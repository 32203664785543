@charset "UTF-8";

@font-face {
  font-family: "lucanet-icons-font";
  src:url("../fonts/lucanet/lucanet-icons-font-202203111510.eot");
  src:url("../fonts/lucanet/lucanet-icons-font-202203111510.eot?#iefix") format("embedded-opentype"),
  url("../fonts/lucanet/lucanet-icons-font-202203111510.woff") format("woff"),
  url("../fonts/lucanet/lucanet-icons-font-202203111510.ttf") format("truetype"),
  url("../fonts/lucanet/lucanet-icons-font-202203111510.svg#lucanet-icons-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "lucanet-icons-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ln-icon-"]:before,
[class*=" ln-icon-"]:before {
  font-family: "lucanet-icons-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ln-icon-consolidation-area:before {
  content: "\61";
}
.ln-icon-charts-of-account:before {
  content: "\62";
}
.ln-icon-data-transfer:before {
  content: "\63";
}
.ln-icon-data-validation:before {
  content: "\64";
}
.ln-icon-currency-conversion:before {
  content: "\65";
}
.ln-icon-validation-and-ic-reconciliation:before {
  content: "\66";
}
.ln-icon-consolidation-run:before {
  content: "\67";
}
.ln-icon-quality-assurance:before {
  content: "\68";
}
.ln-icon-financial-reporting:before {
  content: "\69";
}
.ln-icon-hr-sabbatical:before {
  content: "\6a";
}
.ln-icon-hr-occupational-pension:before {
  content: "\6b";
}
.ln-icon-hr-mobile-phones:before {
  content: "\6c";
}
.ln-icon-hr-health-promotion:before {
  content: "\6d";
}
.ln-icon-hr-good-connection:before {
  content: "\6e";
}
.ln-icon-hr-flexible-working-hours:before {
  content: "\6f";
}
.ln-icon-hr-further-education:before {
  content: "\70";
}
.ln-icon-hr-employee-events:before {
  content: "\71";
}
.ln-icon-hr-employee-discounts:before {
  content: "\72";
}
.ln-icon-hr-company-car:before {
  content: "\73";
}
.ln-icon-hr-christmas-bonus:before {
  content: "\74";
}
.ln-icon-aca-expert-knowledge-transfer:before {
  content: "\75";
}
.ln-icon-aca-interactive-working:before {
  content: "\76";
}
.ln-icon-aca-pleasant-atmosphere:before {
  content: "\77";
}
.ln-icon-aca-tailor-made-seminars:before {
  content: "\78";
}
.ln-icon-aca-valuable-knowledge:before {
  content: "\79";
}
.ln-icon-cons-comprehensive-transparency:before {
  content: "\7a";
}
.ln-icon-cons-concentrated-expertise:before {
  content: "\41";
}
.ln-icon-cons-convincing-concept:before {
  content: "\42";
}
.ln-icon-cons-excellent-service-quality:before {
  content: "\43";
}
.ln-icon-cons-quickly-to-target:before {
  content: "\44";
}
.ln-icon-cons-secure-project-sequence:before {
  content: "\45";
}
.ln-icon-fast-implementation:before {
  content: "\4c";
}
.ln-icon-finance-to-finance:before {
  content: "\4d";
}
.ln-icon-real-time:before {
  content: "\4e";
}
.ln-icon-consulting:before {
  content: "\4f";
}
.ln-icon-disclosure-management:before {
  content: "\50";
}
.ln-icon-e-bilanz:before {
  content: "\51";
}
.ln-icon-equity:before {
  content: "\52";
}
.ln-icon-financial-consolidation:before {
  content: "\53";
}
.ln-icon-group-report:before {
  content: "\54";
}
.ln-icon-image:before {
  content: "\55";
}
.ln-icon-importer:before {
  content: "\56";
}
.ln-icon-kommunal:before {
  content: "\57";
}
.ln-icon-planner:before {
  content: "\58";
}
.ln-icon-references:before {
  content: "\59";
}
.ln-icon-smart-accounting:before {
  content: "\5a";
}
.ln-icon-tax-balance-sheet:before {
  content: "\30";
}
.ln-icon-reporting:before {
  content: "\31";
}
.ln-icon-integrated-financial-planning:before {
  content: "\32";
}
.ln-icon-german-municipal-financial-statements:before {
  content: "\33";
}
.ln-icon-financial-statements:before {
  content: "\34";
}
.ln-icon-data-collection:before {
  content: "\35";
}
.ln-icon-cpm:before {
  content: "\36";
}
.ln-icon-consolidated-financial-statements:before {
  content: "\37";
}
.ln-icon-annual-reporting:before {
  content: "\38";
}
.ln-icon-integrated:before {
  content: "\39";
}
.ln-icon-more-simple:before {
  content: "\21";
}
.ln-icon-transparent:before {
  content: "\22";
}
.ln-icon-data-approval:before {
  content: "\23";
}
.ln-icon-dispatch-of-templates:before {
  content: "\24";
}
.ln-icon-ic-reconciliation:before {
  content: "\25";
}
.ln-icon-reporting-set-up:before {
  content: "\26";
}
.ln-icon-transmission-of-data:before {
  content: "\27";
}
.ln-icon-balence-sheet-and-liquidity-planning:before {
  content: "\28";
}
.ln-icon-definition-of-the-data-model:before {
  content: "\29";
}
.ln-icon-group-planning:before {
  content: "\2a";
}
.ln-icon-implementation-of-planning:before {
  content: "\2b";
}
.ln-icon-integration-of-sub-plans:before {
  content: "\2c";
}
.ln-icon-reporting-1:before {
  content: "\2d";
}
.ln-icon-scenarios-and-forecasts:before {
  content: "\2e";
}
.ln-icon-set-up-of-sub-plans:before {
  content: "\2f";
}
.ln-icon-transfer-of-actual-data:before {
  content: "\3a";
}
.ln-icon-process-definition:before {
  content: "\3b";
}
.ln-icon-creation-of-data-warehouse:before {
  content: "\3c";
}
.ln-icon-data-transfer-into-report-templates:before {
  content: "\3d";
}
.ln-icon-definition-of-user-rights:before {
  content: "\3e";
}
.ln-icon-reporting-templates:before {
  content: "\3f";
}
.ln-icon-analysis-and-evaluation:before {
  content: "\40";
}
.ln-icon-data-processing:before {
  content: "\5b";
}
.ln-icon-report-distribution:before {
  content: "\5d";
}
.ln-icon-accounting-guideline:before {
  content: "\5e";
}
.ln-icon-data-analysis:before {
  content: "\5f";
}
.ln-icon-prep-of-financial-statement:before {
  content: "\60";
}
.ln-icon-publishing-annual-accounts:before {
  content: "\7b";
}
.ln-icon-simulation-of-financial-statement:before {
  content: "\7c";
}
.ln-icon-situation-analysis-and-target-concept:before {
  content: "\7d";
}
.ln-icon-transition-into-tax-balance:before {
  content: "\7e";
}
.ln-icon-data-transfer-into-e-bilanz:before {
  content: "\5c";
}
.ln-icon-electronic-transmission:before {
  content: "\e000";
}
.ln-icon-maintaining-master-data:before {
  content: "\e001";
}
.ln-icon-mapping-of-accounts:before {
  content: "\e002";
}
.ln-icon-other-entries:before {
  content: "\e003";
}
.ln-icon-reconcialiation-of-tax-accounts:before {
  content: "\e004";
}
.ln-icon-validation-of-entries:before {
  content: "\e005";
}
.ln-icon-contact:before {
  content: "\e006";
}
.ln-icon-close:before {
  content: "\46";
}
.ln-icon-xy-countries:before {
  content: "\47";
}
.ln-icon-xy-users:before {
  content: "\48";
}
.ln-icon-partner-attractive-partner-margins:before {
  content: "\49";
}
.ln-icon-partner-dedicated-partner-organisation:before {
  content: "\4a";
}
.ln-icon-partner-leading-software-solution:before {
  content: "\4b";
}
.ln-icon-partner-satisfied-customers:before {
  content: "\e008";
}
.ln-icon-partner-easy-to-integrate:before {
  content: "\e009";
}
.ln-icon-partner-excellent-cpm-tool:before {
  content: "\e00a";
}
.ln-icon-partner-maximum-results:before {
  content: "\e00b";
}
.ln-icon-partner-simple-fair-transparent:before {
  content: "\e00c";
}
.ln-icon-partner-support-free-of-charge:before {
  content: "\e00d";
}
.ln-icon-partner-valuable-resources:before {
  content: "\e00e";
}
.ln-icon-challenge:before {
  content: "\e007";
}
.ln-icon-ifrs:before {
  content: "\e00f";
}
.ln-icon-bi-analytics:before {
  content: "\e010";
}
.ln-icon-hr-international-environment:before {
  content: "\e011";
}
.ln-icon-hr-oepnv-grants:before {
  content: "\e012";
}
.ln-icon-hr-onboarding:before {
  content: "\e013";
}
.ln-icon-hr-practical-relevance:before {
  content: "\e014";
}
.ln-icon-crm-support:before {
  content: "\e015";
}
.ln-icon-fi-support:before {
  content: "\e016";
}
.ln-icon-sales-support:before {
  content: "\e017";
}
.ln-icon-tech-support:before {
  content: "\e018";
}
.ln-icon-default:before {
  content: "\e019";
}
.ln-icon-etl-apps:before {
  content: "\e01a";
}
.ln-icon-financial-olap-server:before {
  content: "\e01b";
}
.ln-icon-network-and-security:before {
  content: "\e01c";
}
.ln-icon-all-in-one:before {
  content: "\e01d";
}
.ln-icon-awarded-company:before {
  content: "\e01e";
}
.ln-icon-customer:before {
  content: "\e01f";
}
.ln-icon-employee-growth:before {
  content: "\e020";
}
.ln-icon-foundation:before {
  content: "\e021";
}
.ln-icon-global-locations:before {
  content: "\e022";
}
.ln-icon-luca-pacioli:before {
  content: "\e023";
}
.ln-icon-easy-to-handle:before {
  content: "\e024";
}
.ln-icon-global-presence:before {
  content: "\e025";
}
.ln-icon-hr-teamspirit:before {
  content: "\e026";
}
.ln-icon-hr-working-with-a-view:before {
  content: "\e027";
}
.ln-icon-social-kununu:before {
  content: "\e028";
}
.ln-icon-customer-newsletter:before {
  content: "\e029";
}
.ln-icon-angle-left:before {
  content: "\e02a";
}
.ln-icon-angle-right:before {
  content: "\e02b";
}
.ln-icon-calendar:before {
  content: "\e02c";
}
.ln-icon-quote-right:before {
  content: "\e02d";
}
.ln-icon-phone:before {
  content: "\e02e";
}
.ln-icon-map-marker:before {
  content: "\e02f";
}
.ln-icon-long-arrow-up:before {
  content: "\e030";
}
.ln-icon-envelope:before {
  content: "\e031";
}
.ln-icon-clock:before {
  content: "\e032";
}
.ln-icon-lucanet-ag-signet:before {
  content: "\e033";
}
.ln-icon-thriving-together:before {
  content: "\e034";
}
.ln-icon-embracing-empowerment:before {
  content: "\e035";
}
.ln-icon-customer-passionate:before {
  content: "\e036";
}
.ln-icon-always-growing:before {
  content: "\e037";
}
.ln-icon-aiming-for-success:before {
  content: "\e038";
}
