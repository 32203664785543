/**
 * Created by Robert on 19.09.2016.
 */
.btn {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.big-cta {
  line-height: 40px;

  i {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    opacity: 0;
    width: 0;
  }

  &:hover>i {
    opacity: 1;
    width: 20px;
  }
}

@media (max-width: $screen-xs-max) {
  .big-cta {
    white-space: normal;
    line-height: $letter-spacing-normal + px;
  }
}
