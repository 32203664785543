.ln-flyout-login {

  position: relative;

  &__wrapper {
    position: relative;
    top: 0;
    left: auto;
    right: 0;
    border-radius: 0;
    border: none;
    min-width: 250px;
    min-height: 160px;
    padding: .625rem;
    box-shadow: none;
    margin: 0 0 0 1.5rem;
    font-size: .9375rem;
    background: transparent;

    &--small {
      min-height: 50px;
    }
  }

  &__link {
    display: block;
    color: #fff;
    font-size: 1.25rem;
    font-weight: $ln-font-weight-base;
    padding: .325rem .625rem;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    border: 0;

    &:hover, &:focus {
      color: #fff;
      border: 0;
      text-decoration: none;
    }

    &--small {
      font-weight: 500;
      font-size: 1rem;
      text-transform: initial;
      padding: .188rem 0;
    }

    .fa {
      padding-right: .313rem;
    }
  }

  form {
    padding-top: 0;
  }

  label {
    color: #fff;
    font-weight: 400;
  }

  input.form-control {
    height: 25px;
  }

  .form-group {
    margin: .625rem 0;
  }

  @media (min-width: $ln-breakpoint-xl) {
    &__wrapper {
      position: absolute;
      top: 100%;
      padding: .625rem;
      background-color: $ln-lightgray;
      margin: 0;
    }

    &__link {
      color: $ln-darkgray;
      font-size: .9375rem;
      padding: 0 .625rem;
      font-weight: $ln-font-weight-light;

      &:hover, &:focus {
        color: #000;
      }

      &--small {
        font-weight: $ln-font-weight-base;
        font-size:  .9375rem;
        padding: .188rem 0;
      }
    }

    form {
      padding-top: 0;
    }

    label {
      color: $ln-darkgray;
      font-weight: 400;
    }
  }
}
