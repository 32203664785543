.ln-mega-menu {
  $block: &;

  display: block;
  position: fixed;
  top: $ln-navigation-topbar-height-base;
  height: $ln-mega-menu-height-base;
  max-height: calc(#{$ln-mega-menu-height-base} - #{$ln-navigation-topbar-height-base});
  padding: 0;
  z-index: 650;
  overflow: auto;
  background: $ln-lagoonblue url("../images/mobile-navigation-bg.png") no-repeat top/cover;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
  right: -100%;
  transition: right .5s ease;

  @media (max-width: $ln-breakpoint-lg-max) {
    width: 100%;
    max-width: 100%;
  }

  &__wrapper-left {
    flex-grow: 1;
    padding: 1.25rem 1.25rem 2.5rem;
    align-items: stretch;
  }

  &__wrapper-right {
    display: none;
    flex-basis: 25%;
    //flex-basis: 534px;
    background: $ln-gradient-lagoonblue-to-purple-to-bottom-left;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    flex-direction: column;

    &:last-child {
      #{$block}__list {
        border: 0;

        &:not(#{$block}__list--second-level) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__grid-item {
    flex-direction: column;
  }

  &__list {
    flex-basis: 100%;
    list-style: none;
    margin: 0;
    padding: 0 0 .9375rem;

    &--second-level {
      padding: 0 .625rem 0;
      border: 0;

      #{ $block }__item {
        padding: .9375rem 0 0 .9375rem;
        border: 0;

        &:before {
          font-size: 1rem;
          line-height: 1.3;
        }
      }

      > li {
        position: relative;
        padding: 0 0 0 .9375rem;

        &:before {
          position: absolute;
          left: 0;
          content: '\00BB';
          font-size: 1.125rem;
          font-weight: $ln-font-weight-light;
          padding-right: .3375rem;
          color: #fff;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid change-color($ln-white, $alpha: 0.2);
    padding: .3125rem 0 .3125rem .9375rem;
    font-size: 1.125rem;
    color: #fff;
    font-weight: $ln-font-weight-light;

    a {
      position: relative;
      color: #fff;
      font-size: 1.125rem;
      font-weight: $ln-font-weight-light;
      text-decoration: none;
      width: 100%;
      display: block;
      border-bottom: 0;

      &:hover, &:active, &:focus {
        border-bottom: 0;
        color: #fff;
        text-decoration: none;

        > strong {
          color: inherit;
        }
      }

      @media screen and (max-width: $ln-breakpoint-lg-max) {
        > strong {
          color: inherit;
        }
      }
    }

    &:before {
      position: absolute;
      left: 0;
      content: '\00BB';
      font-size: 1.125rem;
      font-weight: $ln-font-weight-light;
      padding-right: .3375rem;
      color: #fff;
    }
  }

  &__headline {
    color: #fff;
    display: block;
    font-size: 1.25rem;
    line-height: 1.1;
    font-weight: $ln-font-weight-base;
    text-decoration: none;
    margin-bottom: .75rem;

    a {
      border-bottom: 0;
      text-decoration: none;
      color: #fff;

      &:hover, &:active, &:focus {
        color: #fff;
        text-decoration: none;
      }
    }

    &:not(:first-of-type) {
      margin-top: .75rem;
    }
  }

  &__btn-close {
    border: 0;
    color: #fff;
    box-shadow: none;
    margin-bottom: 1.25rem;
    cursor: pointer;
    background: transparent;
    width: 100%;
    text-align: left;

    &:hover, &:focus {
      cursor: pointer;
      border: 0;
      box-shadow: none;
      outline: none;
    }
  }

  @media (min-width: $ln-breakpoint-xl) {
    display: flex;
    height: auto;
    top: $ln-header-height-base;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    max-height: 0;
    background: $ln-lightgray;
    box-shadow: none;

    &__wrapper-right {
      display: block;
    }

    &__wrapper-left {
      padding: 3.125rem 1.25rem;
    }

    &__grid {
      flex-direction: row;

      &:last-child {
        > #{$block}__grid-item  {
          margin-bottom: 0;
        }

        > #{$block}__list  {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &__grid-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-bottom: 2.5rem;
    }

    &__list {
      height: 100%;
      margin: 0 1.25rem;
      padding: 0 0 1.875rem .9375rem;
      border-bottom: 1px solid $ln-gray;

      &--second-level {
        flex-basis: 100%;
        border-bottom: 0;
        margin: .3125rem 0 .3125rem .3125rem;
        padding: 0;
        line-height: 1.3;

        #{ $block }__item {
          border: 0;
          padding: 0 0 0 .9375rem;
        }

        > li {
          &:before {
            color: $ln-darkgray;
          }
        }
      }
    }

    &__headline {
      color: $ln-midnightblue;

      a {
        color: $ln-midnightblue;
        font-weight: $ln-font-weight-base;

        &:hover, &:active, &:focus {
          color: $ln-electric-purple--hover;
        }
      }
    }

    &__item {
      display: block;
      border: 0;
      padding: 0 0 0 .9375rem;
      font-size: 1.125rem;
      color: $ln-darkgray;

      a {
        display: inline-block;
        width: auto;
        font-size: 1.125rem;
        color: $ln-darkgray;

        &:hover, &:active, &:focus {
          color: $ln-electric-purple--hover;
        }
      }

      &:before {
        color: $ln-darkgray;
      }

      &--active {
        a {
          color: $ln-electric-purple;

          > strong {
            color: inherit;
          }
        }
      }
    }

    &__btn-close {
      display: none;
    }
  }
}

.ln-main-menu__item--megamenu-show {
  .ln-mega-menu {
    right: 0;
    transition: right .5s ease;
  }
}

@media (min-width: $ln-breakpoint-xl) {
  //Open mega menu
  .ln-main-menu--mouse-in .ln-main-menu__item:hover {
    .ln-mega-menu {
      max-height: calc(100vh - #{$ln-header-height-base});
      transition: max-height .5s, padding .5s ease;
    }
  }
}
