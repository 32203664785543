/* inspired by bootstrap */

/* Colors */
// primary
// new
$ln-midnightblue: #002a42;
$ln-electric-purple: #9728ff;
$ln-electric-purple--hover: #762cb9;
$ln-neon-green: #28ff14;
// old
$ln-nightblue: rgba(32, 42, 96, 1) !default; // #202a60
$ln-lagoonblue: rgba(0, 139, 210, 1) !default; // #008bd2
$ln-green: rgba(4, 168, 140, 1) !default; // #04a88c
$ln-raspberry: rgba(184, 14, 128, 1) !default; // #b80e80
$ln-raspberry--90: rgba(184, 14, 128, 0.9) !default;
$ln-berry-shade-7: rgba(129, 28, 94, 1) !default; // #811c5e

// secondary
$ln-sage: rgba(115, 165, 150, 1) !default; // #73a596
$ln-oak: rgba(210, 170, 115, 1) !default; // #d2aa73
$ln-purple: rgba(60, 43, 114, 1) !default; // #3c2b72

// hover
$ln-darkcyan: rgba(5, 147, 123, 1) !default; // #05937b
$ln-moderate-orange: rgba(192, 154, 95, 1) !default; // #c09a5f
$ln-berry-shade-7--90: rgba(129, 28, 94, 0.9) !default;

// gray
$ln-darkgray: rgba(51, 51, 51, 1) !default; // #333, #333333
$ln-gray: rgba(187, 187, 187, 1) !default; // #bbb, #bbbbbb
$ln-lightgray: rgba(242, 242, 242, 1) !default; // #f2f2f2
// new interlutions colors
$ln-gray-999: #999;
$ln-gray-eaeaea: #eaeaea;
$ln-gray-f5f5f5: #f5f5f5;
$ln-gray-b3b3b3: #b3b3b3;
$ln-gray-e8e8e8: #e8e8e8;
$ln-gray-e6e6e6: #e6e6e6;
$ln-gray-e9ecef: #e9ecef;

// black and white
$ln-black: rgba(0, 0, 0, 1) !default; // #000, #000000
$ln-white: rgba(255, 255, 255, 1) !default; // #fff, #ffffff
$ln-white--80: rgba(255, 255, 255, 0.8) !default;
$ln-white--25: rgba(255, 255, 255, 0.25) !default;
$ln-green--80: rgba(4, 168, 140, 0.8) !default;

// gradients
$ln-moderate-darkblue: rgba(43, 72, 143, 1) !default; // #2b488f, only usage for gradients
$ln-darkblue: rgba(16, 91, 153, 1) !default; // #105b99, only usage for gradients

$ln-gradient-nightblue-to-lagoonblue--to-top-right: linear-gradient(to top right, $ln-nightblue 0%, $ln-darkblue 50%, $ln-lagoonblue 100%) !default;
$ln-gradient-purple-to-lagoonblue--to-bottom-left: linear-gradient(to bottom left, $ln-purple 0%, $ln-moderate-darkblue 50%, $ln-lagoonblue 100%) !default;
$ln-gradient-lagoonblue-to-purple-to-bottom-left: linear-gradient(to bottom left, $ln-lagoonblue 0%, $ln-moderate-darkblue 50%, $ln-purple 100%) !default;

// stagebar colors
$ln-env-dev-bg-color: #4F805D;
$ln-env-stage-bg-color: #e38d13;

$ln-colors: () !default;
$ln-colors: map-merge(
                (
                        "midnightblue": $ln-midnightblue,
                        "electric-purple": $ln-electric-purple,
                        "nightblue": $ln-nightblue,
                        "lagoonblue": $ln-lagoonblue,
                        "green": $ln-green,
                        "raspberry": $ln-raspberry,
                        "raspberry--90": $ln-raspberry--90,
                        "berry-shade-7": $ln-berry-shade-7,
                        "sage": $ln-sage,
                        "oak": $ln-oak,
                        "purple": $ln-purple
                ),
                $ln-colors
);

$ln-colors-extend: () !default;
$ln-colors-extend: map-merge(
                (
                        "white": $ln-white,
                        "black": $ln-black,
                        "darkgray": $ln-darkgray,
                        "lightgray": $ln-lightgray,
                        "gray-eaeaea": $ln-gray-eaeaea,
                        "gray-f5f5f5": $ln-gray-f5f5f5,
                        "gray-b3b3b3": $ln-gray-b3b3b3,
                        "gray-e8e8e8": $ln-gray-e8e8e8,
                        "gray-e6e6e6": $ln-gray-e6e6e6,
                        "gray-e9ecef": $ln-gray-e9ecef,
                        'gray-999': $ln-gray-999
                ),
                $ln-colors-extend
);


/* Font */
$ln-font-family: Roboto, Helvetica Neue, Arial, sans-serif !default;
$ln-font-weight-base: 400;
$ln-font-style-base: normal;
$ln-font-size-base-px: 16px;
$ln-font-size-base: 1rem;
$ln-line-height-base: 1.5;
$ln-font-weight-light: 300;
$ln-font-weight-medium: 500;

/* Breakpoints */
$ln-breakpoint-px-xs: 0;
$ln-breakpoint-px-sm: 576px;
$ln-breakpoint-px-md: 768px;
$ln-breakpoint-px-lg: 992px;
$ln-breakpoint-px-xl: 1200px;
$ln-breakpoint-px-xxl: 1500px;
$ln-breakpoint-px-xxxl: 1600px;

$ln-breakpoint-px-xs-max: $ln-breakpoint-px-sm - 1; // 575px
$ln-breakpoint-px-sm-max: $ln-breakpoint-px-md - 1; // 767px
$ln-breakpoint-px-md-max: $ln-breakpoint-px-lg - 1; // 991px
$ln-breakpoint-px-lg-max: $ln-breakpoint-px-xl - 1; // 1199px
$ln-breakpoint-px-xl-max: $ln-breakpoint-px-xxl - 1; // 1499px
$ln-breakpoint-px-xxl-max: $ln-breakpoint-px-xxxl - 1; // 1599px

$ln-breakpoint-xs: ln-px-to-rem($ln-breakpoint-px-xs); // 0
$ln-breakpoint-sm: ln-px-to-rem($ln-breakpoint-px-sm); // 576px > 36rem
$ln-breakpoint-md: ln-px-to-rem($ln-breakpoint-px-md); // 768px > 48rem
$ln-breakpoint-lg: ln-px-to-rem($ln-breakpoint-px-lg); // 992px > 62rem
$ln-breakpoint-xl: ln-px-to-rem($ln-breakpoint-px-xl); // 1200px > 75rem
$ln-breakpoint-xxl: ln-px-to-rem($ln-breakpoint-px-xxl); // 1500px > 93.75rem
$ln-breakpoint-xxxl: ln-px-to-rem($ln-breakpoint-px-xxxl); // 1600px > 100rem

$ln-breakpoint-xs-max: ln-px-to-rem($ln-breakpoint-px-xs-max); // 575px
$ln-breakpoint-sm-max: ln-px-to-rem($ln-breakpoint-px-sm-max); // 767px
$ln-breakpoint-md-max: ln-px-to-rem($ln-breakpoint-px-md-max); // 991px
$ln-breakpoint-lg-max: ln-px-to-rem($ln-breakpoint-px-lg-max); // 1199px
$ln-breakpoint-xl-max: ln-px-to-rem($ln-breakpoint-px-xl-max); // 1499px
$ln-breakpoint-xxl-max: ln-px-to-rem($ln-breakpoint-px-xxl-max); // 1599px

$ln-breakpoints-px: () !default;
$ln-breakpoints-px: map_merge(
                (
                        "xs": $ln-breakpoint-px-xs,
                        "xs-max": $ln-breakpoint-px-xs-max,
                        "sm": $ln-breakpoint-px-sm,
                        "sm-max": $ln-breakpoint-px-sm-max,
                        "md": $ln-breakpoint-px-md,
                        "md-max": $ln-breakpoint-px-md-max,
                        "lg": $ln-breakpoint-px-lg,
                        "lg-max": $ln-breakpoint-px-lg-max,
                        "xl": $ln-breakpoint-px-xl,
                        "xl-max": $ln-breakpoint-px-xl-max,
                        "xxl": $ln-breakpoint-px-xxl,
                        "xxl-max": $ln-breakpoint-px-xxl-max,
                        "xxxl": $ln-breakpoint-px-xxxl,
                ),
                $ln-breakpoints-px
);

$ln-breakpoints: () !default;
$ln-breakpoints: map_merge(
                (
                        "xs": $ln-breakpoint-xs,
                        "xs-max": $ln-breakpoint-xs-max,
                        "sm": $ln-breakpoint-sm,
                        "sm-max": $ln-breakpoint-sm-max,
                        "md": $ln-breakpoint-md,
                        "md-max": $ln-breakpoint-md-max,
                        "lg": $ln-breakpoint-lg,
                        "lg-max": $ln-breakpoint-lg-max,
                        "xl": $ln-breakpoint-xl,
                        "xl-max": $ln-breakpoint-xl-max,
                        "xxl": $ln-breakpoint-xxl,
                        "xxl-max": $ln-breakpoint-xxl-max,
                        "xxxl": $ln-breakpoint-xxxl,
                ),
                $ln-breakpoints
);

/* Grid */
$ln-grid-gutter-width: 30px !default;

/* Header heights */
$ln-header-height-base: 5.313rem;
$ln-header-height-xs: 4.063rem;
$ln-stagebar-height-base: 2.5rem;
$ln-top-menu-height-base: 2.5rem;
$ln-navigation-topbar-height-base: 3.4375rem;
$ln-mega-menu-height-base: 100vh;

/* Modal */
$ln-modal-max-width-xl: 87.5rem; //1400px

/* Background */
$ln-background-path-rel: '../images/background/';

$ln-meta-stream-one: (
        'xs' : 'meta_one_xs.png',
        'sm' : 'meta_one_sm.png',
        'md' : 'meta_one_md.png',
        'lg' : 'meta_one_lg.png',
        'xl' : 'meta_one_xl.png'
);

$ln-meta-stream-two: (
        'xs' : 'meta_two_xs.png',
        'sm' : 'meta_two_sm.png',
        'md' : 'meta_two_md.png',
        'lg' : 'meta_two_lg.png',
        'xl' : 'meta_two_xl.png'
);

$ln-meta-stream-three: (
        'xs' : 'meta_three_xs.png',
        'sm' : 'meta_three_sm.png',
        'md' : 'meta_three_md.png',
        'lg' : 'meta_three_lg.png',
        'xl' : 'meta_three_xl.png'
);

$ln-meta-stream-four: (
        'xs' : 'meta_four_xs.png',
        'sm' : 'meta_four_sm.png',
        'md' : 'meta_four_md.png',
        'lg' : 'meta_four_lg.png',
        'xl' : 'meta_four_xl.png'
);

$ln-meta-stream-five: (
        'xs' : 'meta_five_xs.png',
        'sm' : 'meta_five_sm.png',
        'md' : 'meta_five_md.png',
        'lg' : 'meta_five_lg.png',
        'xl' : 'meta_five_xl.png'
);

$ln-meta-stream-six: (
        'xs' : 'meta_six_xs.png',
        'sm' : 'meta_six_sm.png',
        'md' : 'meta_six_md.png',
        'lg' : 'meta_six_lg.png',
        'xl' : 'meta_six_xl.png'
);

/* Flags */
$ln-flags: () !default;
$ln-flags: map_merge(
        (
                'at': 'at.png',
                'be': 'be.png',
                'ch': 'ch.png',
                'cn': 'cn.png',
                'de': 'de.png',
                'en': 'us.png',
                'es': 'es.png',
                'fr': 'fr.png',
                'gb': 'gb.png',
                'nl': 'nl.png',
                'sg': 'sg.png',
                'uk': 'gb.png',
                'us': 'us.png'
        ),
        $ln-flags
);
$ln-flag-path: '/images/flag/';

/* Spaces */
$ln-space-large: 1.875rem;
$ln-space-large-md: 2.5rem;
$ln-space-large-lg: 3.125rem;
$ln-space-extra-large: 2.5rem;
$ln-space-extra-large-md: 3.75rem;
$ln-space-extra-large-lg: 5rem;
