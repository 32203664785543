@mixin ln-container-flex-theme-border($color) {
  border: 1px solid $color;
}

@mixin ln-container-flex-theme-background($color) {
  background-color: $color;
  color: #fff;

  > p, strong {
    color: inherit;
  }
}

@mixin ln-container-flex-theme-color($color) {
  color: $color;

  > p, strong {
    color: inherit;
  }
}
