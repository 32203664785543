.card-block {

  h2{
    color: $ln-color-13;
    text-align: center;
    margin-top: 0px;
  }

  background: $ln-color-10;
  border: 1px solid $ln-color-13;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 15px;
}