/**
 * Created by rzi on 08.03.2016.
 */

@mixin linear-gradient($ln-gradient1-color-from, $ln-gradient1-color-to) {
  background: $ln-gradient1-color-from; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, $ln-gradient1-color-from , $ln-gradient1-color-to); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $ln-gradient1-color-from , $ln-gradient1-color-to); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $ln-gradient1-color-from , $ln-gradient1-color-to); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, $ln-gradient1-color-from , $ln-gradient1-color-to); /* Standard syntax */
}


