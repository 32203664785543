.logo-carousel.cfo-statements-slider-controller{
  .slider-image-holder
  {
    height: 176px;
  }

  @media (min-width: $screen-md-min) {
    .slider-image-holder .img-responsive{
      bottom: auto;
    }
  }
}



