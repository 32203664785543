html {
  font-size: $ln-font-size-base-px;
}

body {
  font-family: $ln-font-family;
  font-weight: $ln-font-weight-base;
  font-style: $ln-font-style-base;
  font-size: $ln-font-size-base;
  line-height: $ln-line-height-base;
  color: $ln-darkgray;
  margin: 0;
  text-align: left;
  background-color: #fff;

  &:focus {
    outline: 0;
  }
}

strong {
  font-weight: $ln-font-weight-medium;
  color: $ln-darkgray;
}

p {
  @include ln-text-p();
}

a {
  font-family: $ln-font-family;
  font-style: $ln-font-style-base;
  font-weight: $ln-font-weight-medium;
  outline: 0;
  text-decoration: none;
  background-color: transparent;
  /** old
  color: $ln-green;
  border-bottom: 1px dotted $ln-green;
  */
  color: $ln-electric-purple;
  border-bottom: 1px dotted $ln-electric-purple;
  transition: border .5s ease;

  &:hover {
    border-bottom: 1px solid $ln-electric-purple--hover;

    strong {
      color: $ln-electric-purple--hover;
    }
  }
}

address, dl, ol, ul {
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
}

ul {
  font-size: $ln-font-size-base;
  list-style-type: square;
  padding-left: 1.875rem;

  @media screen and(min-width: $ln-breakpoint-lg) {
    font-size: 1.125rem;
  }
}

blockquote, ul li {
  font-family: $ln-font-family;
  font-style: $ln-font-style-base;
  font-weight: $ln-font-weight-light;
}

label {
  font-weight: $ln-font-weight-medium;
}

figcaption {
  font-size: 0.875rem;
  font-weight: $ln-font-weight-light;
  margin: 5px 0 15px;
}

.blockquote-center {
  color: $ln-darkgray;
}

.cta {
  display: inline-block;
  position: relative;
  font-size: 1.125rem;
  font-family: $ln-font-family;
  font-style: $ln-font-style-base;
  font-weight: $ln-font-weight-medium;
  outline: 0;
  text-decoration: none;
  background-color: transparent;
  color: $ln-green;
  border-bottom: 1px dotted $ln-green;

  &:hover {
    color: $ln-green;
    border-bottom: 1px solid $ln-green;
    text-decoration: none;

    &::after {
      right: -1.125rem;
    }
  }

  &:after {
    border: 0;
    content: "\BB";
    display: inline-block;
    position: absolute;
    top: 0;
    right: -.9375rem;
    transition: right .3s ease;
  }
}

@each $color, $value in $ln-colors {
  .ln-color--#{$color} {
    color: #{$value};
  }
}
