.theme-ln {
  .panel-primary {
    border-color: $ln-nightblue;
  }

  .panel-primary > .panel-heading {
    background-color: $ln-nightblue;
    border-color: $ln-nightblue;
  }

  .panel-footer {
    background-color: transparent;
  }
}