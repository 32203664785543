.ln-language-switcher {
  position: relative;
  width: 100%;
  padding: .9375rem 0;

  &__list {
    position: relative;
    top: 0;
    width: 100%;
    min-width: 10rem;
    z-index: 9999;
    left: auto;
    margin-top: 0;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding: .625rem;
    background: transparent;
  }

  &__item {
    display: block;
    width: 100%;
    color: #fff;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 2;
    font-weight: $ln-font-weight-base;
    padding: .188rem 1.25rem;
    border:0;

    &:hover, &:focus {
      color: #fff;
      border:0;
      text-decoration: underline;
    }
  }

  &__button {
    text-align: left;
    width: 100%;
    color: #fff;
    font-size: 1.25rem;
    font-weight: $ln-font-weight-base;
    padding: 0 .625rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    border: 0;
    box-shadow: none;
    background: transparent;

    &:after {
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }

    &:hover, &:focus {
      border: 0;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      text-decoration: none;
    }

    .fa {
      padding-right: .313rem;
    }
  }

  @media (min-width: $ln-breakpoint-xl) {
    padding: 0;

    &__button {
      text-align: left;
      width: auto;
      font-size: .9375rem;
      font-weight: $ln-font-weight-light;
      color: $ln-darkgray;

      &:after {
        display: none;
      }

      &:hover, &:focus {
        color: #000;
      }
    }

    &__list {
      position: absolute;
      top: 100%;
      width: auto;
      background-color: $ln-lightgray;
      right: -1.25rem;
      padding: .625rem 0;
    }

    &__item {
      display: block;
      width: auto;
      color: $ln-darkgray;
      text-transform: initial;

      &:hover {
        color: $ln-nightblue;
        text-decoration: underline;
      }
    }
  }
}
