.ln-blog {
  &__social {
    > ul {
      list-style: none;
      padding-left: 0;

      li {
        display: inline-block;
        padding-left: .625rem;
        padding-right: .625rem;
        font-size: 1.875rem;

        > a {
          border: 0 !important;
          &:hover {
            color: $ln-electric-purple--hover;
          }
        }
      }
    }
  }
}
