.ln-main-menu {
  $block: &;
  $item: & + '__item';

  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 0;
  width: 100%;
  flex-grow: 1;

  &__list {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    padding: 0 15px;
    margin: 0;

    &--dark {
      margin-top: 1.875rem;
      flex-grow: 1;
      background: rgba(0,48,93,.5);
      text-transform: uppercase;
      height: 100%;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid change-color($ln-white,$alpha:0.3);

    &--has-sub {
      #{ $block }__btn-megamenu {
        display: block;
      }
    }
  }

  &__link {
    position: relative;
    flex-grow: 1;
    height: 100%;
    padding: .9375rem .3125rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: $ln-font-weight-base;
    border-bottom: 0;

    &:hover {
      color: #fff;
      border-bottom: 0;
      text-decoration: none;
    }

    &--hide-mobile {
      display: none;
      @media screen and (min-width: $ln-breakpoint-xl) {
        display: inherit;
      }
    }
  }

  &__btn-megamenu {
    display: none;
    flex-basis: 40%;
    border: 0;
    background: transparent;
    color: #fff;
    text-align: right;

    &:hover, &:focus {
      border: 0;
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }

    .fa {
      font-size: .75rem;
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &--block {
      display: block;
      width: 100%;
      flex-basis: 100%;
      padding: .9375rem .3125rem .9375rem 0;
      text-align: left;
      cursor: pointer;
    }
  }

  @media (min-width: $ln-breakpoint-xl) {
    justify-content: center;
    flex-direction: row;
    padding: 0;
    background: transparent;
    box-shadow: none;
    position: relative;
    height: 100%;
    margin-top: 0;

    &__list{
      display: flex;
      flex-basis: auto;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      padding: 0;
      background: transparent;
      box-shadow: none;
      transition: none;
      position: relative;
      top: 0;

      &--no-desktop {
        display: none;
      }
    }

    &--mouse-in {

      #{$block}__item {

        &:hover {
          box-shadow: inset 0px -3px 0px 0px $ln-electric-purple;
        }

        &--has-sub {
          &:hover:after {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $ln-electric-purple--hover;
            bottom: -6px;
            content: "";
            display: block;
            height: 0;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 0;
            z-index: 651;
          }
        }

        #{$block}__link {
          &:hover {
            cursor: pointer;
            color: $ln-electric-purple--hover;
          }
        }
      }
    }

    &__item {
      display: inline-block;
      border-bottom: 0;
      padding: 0;

      &--active {
        box-shadow: inset 0px -3px 0px 0px $ln-electric-purple;
      }

      &--has-sub {
        #{ $block }__link:after {
          display: block;
          margin-left: .643em;
          vertical-align: .255em;
          content: "";
          border-top: .3em solid;
          border-right: .3em solid transparent;
          border-bottom: 0;
          border-left: .3em solid transparent;
          transition: border-top-color .2s ease;
        }

        #{ $block }__btn-megamenu  {
          display: none;
        }
      }
    }

    &__link {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: center;
      color: $ln-midnightblue;
      padding: 0 .938rem;
      font-size: 1.125rem;
      line-height: 1.7;

      &:hover {
        color: $ln-midnightblue;
      }
    }

    &__btn-megamenu {
      display: none;
    }
  }
}
