.ln-screenshot {
  $block: &;

  display: block;
  width: 100%;

  // allow admin buttons to be positioned
  position: relative;

  &__info {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__image {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    border: none;

    &:hover {
      border: none;
    }

    &--cursor-zoom-in {
      cursor: zoom-in;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__video {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }

  // use wrapper like bootstrap row
  &__wrapper {
    @extend .clearfix;
    display: block;
    width: 100%;

    margin-bottom: ln-px-to-rem(15px);
    margin-top: ln-px-to-rem(20px);

    // compensate for bootstrap row margins
    @include ln-bootstrap-compensation();

    // use this class like bootstrap cols inside of wrapper
    & &-item {
      display: block;
      width: 100%;
      float: left;

      // imitate bootstrap col padding
      @include ln-grid-items();

      @media only screen and (min-width: $ln-breakpoint-md) {
        width: 50%;
      }
    }

    // used for alternating side swapping effect
    #{$block}:nth-child(2n+2) & &-item:first-child {
      @media only screen and (min-width: $ln-breakpoint-md) {
        float: right;
      }
    }
  }

  &__badge {
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    background-color: $ln-green--80;
    transition: transform .2s ease;
    box-shadow: 0 0 10px change_color($ln-white, $alpha: 0.5); // hsla (0, 0%, 100%, .5)

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;

      // fix play sign positioning
      &.fa-play {
        text-indent: .2em;
      }
    }

    #{$block}__image:hover & {
      transform: scale(1.1);
    }

    &--center {
      top: 50%;
      right: 50%;
      transform: translateX(50%) translateY(-50%);

      #{$block}__image:hover & {
        transform: translateX(50%) translateY(-50%) scale(1.1);
      }
    }

    &--normal {
      width: 6.25rem;
      height: 6.25rem;
      font-size: 3.25rem;
    }

    &--big {
      width: 8.5rem;
      height: 8.5rem;
      font-size: 3.25rem;
    }
  }
}

// collapsiblePanel extension
.ln-collapsible-panel {
  .ln-screenshot {
    padding: 1.875rem;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      padding: 4.375rem 6.25rem;
    }
  }
}
