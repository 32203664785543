.ln-stage-bar {
  height: 2.5rem;
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 700;
  text-align: center;
  color: #fff;
  font-weight: $ln-font-weight-medium;
  text-transform: uppercase;
  font-size: $ln-font-size-base;
  line-height: 2.5rem;

  &--dev {
    background-color: $ln-env-dev-bg-color;
  }

  &--stage {
    background-color: $ln-env-stage-bg-color;
  }
}
