.ln-top-menu {
  display: none;
  position: fixed;
  height: 2.5rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 700;
  background-color: $ln-lightgray;

  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 2.5rem;
  }

  &__item {
    padding: 0 .625rem;
    position: relative;
    font-size: .9375rem;
    text-transform: uppercase;
    list-style: none;

    &--separator {
      margin-right: .625rem;
      padding-right: 1.25rem;
      border-right: 1px solid $ln-gray;
    }

    &--video {
      padding: 0 .625rem;

      a {
        display: flex;
        font-size: .9375rem;
        font-weight: $ln-font-weight-light;
        min-width: 7.5rem;
        height: 2.5rem;
        padding: 0 1.25rem;
        color: #fff;
        background-color: $ln-lagoonblue;
        align-items: center;
        border: none;
        transition: ease-in-out, background-color .15s;

        &:hover {
          background-color: $ln-berry-shade-7--90; /** @ToDo Must be replaced, BG hover color not defined **/
          border: none;
        }
      }
    }

    &--demo {
      padding: 0 .625rem;

      a {
        display: flex;
        font-size: .9375rem;
        font-weight: $ln-font-weight-light;
        min-width: 7.5rem;
        height: 2.5rem;
        padding: 0 1.25rem;
        color: #fff;
        background-color: $ln-raspberry;
        align-items: center;
        border: none;
        transition: ease-in-out, background-color .15s;

        &:hover {
          background-color: change-color($ln-berry-shade-7, $alpha: 0.9);
          border: none;
        }
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__link {
    color: $ln-darkgray;
    font-size: .9375rem;
    font-weight: $ln-font-weight-light;
    padding: 0 .625rem;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 0;

    &:hover, &:focus {
      border-bottom: 0;
      text-decoration: none;
      color: #000;
    }
  }

  &__icon {
    padding-right: .313rem;
  }

  @media (min-width: $ln-breakpoint-xl) {
    display: block;
  }
}




