/**
 * Created by RZI on 07.03.2016.
 */
.video-slider .carousel.slide {
    height: 625px;
    margin-bottom: 40px;
}

.description {
    margin-top: 15px;
}