
.partner {
  margin-top: 40px;
}

.partner-statements-slider-controller{

  background-color: transparent;

  .partner-slider-container{
    background-color: #fff;
    padding-top: 20px;
    height: 300px;

    .partner-logo-image{
      padding-top: 10px;

    }

  }
}