.ln-header {
  position: fixed;
  width: 100%;
  height: $ln-header-height-xs;
  top: 0;
  z-index: 600;
  background-color: #fff;
  transition: box-shadow 1s ease;
  border-bottom: 1px solid $ln-gray-f5f5f5;

  .container {
    padding: 0;

    @media screen and (max-width: $ln-breakpoint-lg-max) { // old value $screen-lg-min
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    &--center {
      justify-content: center;
    }
  }

  &__logo {
    display: block;
    height: auto;
    width: ln-px-to-rem(170px);
    padding: ln-px-to-rem(11px 15px 10px);
    border: 0;

    &:hover {
      border: 0;
    }
  }

  @media screen and (min-width: $ln-breakpoint-md) {
    height: $ln-header-height-base;
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    &__logo {
      height: ln-px-to-rem(85px);
      padding: ln-px-to-rem(18px 15px 17px);
      width: ln-px-to-rem(210px);
    }
  }

  &__btn-toggle {
    margin: 0 .938rem;
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: none;
    color: change-color(#000, $alpha: .05);

    &:hover, &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    svg {
      height: 1.563rem;
      width: 2.25rem;
    }
  }

  &__icon-toggle {
    height: 1.563rem;
    width: 2.25rem;
    fill: $ln-nightblue;
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    &__wrapper {
      align-items: stretch;
    }

    &__btn-toggle {
      display: none;
    }
  }
}

