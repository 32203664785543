@mixin ln-teaser-theme-icon($prefix, $color, $standard: $ln-white) {
  #{ $prefix }__icon {
    background-color: $color !important;
    box-shadow: 2px 2px 10px 5px change-color($color, $alpha: 0.2) !important;

    > i:before {
      color: $standard !important;
    }
  }
}

@mixin ln-teaser-theme-border($prefix, $color, $standard: $ln-white, $direction: 'bottom') {
  border-#{ $direction }: 10px solid  #{ $standard };

  @include ln-teaser-theme-icon($prefix, $color, $standard);

  .btn {
    color: $standard !important;
    border: 2px solid $color !important;
    background-color: $color !important;
  }

  &:hover {
    border-#{ $direction }: 10px solid $color;
  }
}

@mixin ln-teaser-theme-highlight($prefix, $color, $inverse: $ln-white) {
  background-color: $inverse !important;
  border: 0 !important;
  cursor: pointer !important;
  transition: box-shadow .5s ease,transform .5s ease;

  @include ln-teaser-theme-icon($prefix, $color, $inverse);

  .btn {
    color: $inverse !important;
    border: 2px solid $color !important;
    background-color: $color !important;
  }

  &:hover {
    background-color: $color !important;
    color: $inverse !important;
    transform: none !important;
    transition: background .5s ease;

    #{ $prefix }__headline,
    #{ $prefix }__text{
      color: $inverse !important;
    }

    #{ $prefix }__icon {
      color: $color !important;
      background-color: $inverse !important;
      box-shadow: none !important;

      > i:before {
        color: $color !important;
      }
    }

    .btn {
      border: 2px solid $inverse !important;
    }
  }
}
