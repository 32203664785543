.ln-reference {
  $block: &;
  position: relative;

  &__headline {
    text-align: center;
    margin-bottom: 1.25rem;

    &--businessProcess {
      @include ln-headline-tiny();
      margin-top: 3rem;
    }
  }

  &__logo {
    display: none;
    margin: 0 0 1.25rem;
    text-align: center;
  }

  &__grid {
    display: flex;

    &--introduction {
      flex-wrap: nowrap;
      align-items: stretch;
    }

    &--statement {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      #{$block}__grid-item:nth-child(1) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        padding-top: 1.25rem;
      }

      #{$block}__grid-item:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        padding-bottom: 1.25rem;
      }
    }

    &--proven-outcomes {
      flex-wrap: wrap;
      justify-content: center;

      #{$block}__grid-item {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &__challenge {
    padding: 15px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        border-bottom: 1px solid $ln-gray-eaeaea;
        padding: .9375rem 0 .9375rem .9375rem;

        &:before {
          content: "\25AA";
          display: inline-block;
          font-size: 1.25rem;
          left: 0;
          line-height: 1.2;
          margin-right: .3125rem;
          position: absolute;
        }
      }
    }
  }

  &__company {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        color: #04a88c;
        font-weight: 500;
        padding: .5rem 0 .5rem .9375rem;
        position: relative;

        &:before {
          content: "\BB";
          display: inline-block;
          font-size: 1.25rem;
          left: 0;
          line-height: 1.2;
          margin-right: .3125rem;
          position: absolute;
        }
      }
    }
  }

  &__image-text {
    margin-bottom: 2.5rem !important;
  }

  &__added-value {
    position: relative;
    padding: 1.5625rem 1.875rem;
    background-image: $ln-gradient-purple-to-lagoonblue--to-bottom-left;
    color: #fff;

    h2, h3, h4, p {
      color: #fff;
    }

    ul {
      font-weight: $ln-font-weight-light;
      list-style: none;
      margin: 0;
      padding: 0;

      &:before {
        background: #04a88c url('../images/icons/icon-tick.svg') no-repeat 50%/14px 10px;
        border-radius: 50%;
        content: "";
        display: block;
        height: 32px;
        position: absolute;
        right: -.75rem;
        top: -.75rem;
        width: 32px;
      }

      li {
        display: block;
        margin-bottom: .9375rem;
        padding-left: 1.5625rem;
        position: relative;

        &:before {
          background: url('../images/icons/icon-tick.svg') no-repeat 50%;
          content: "";
          display: block;
          height: 10px;
          left: 0;
          position: absolute;
          top: 5px;
          width: 14px;
        }
      }
    }
  }

  &__video {
    margin: 0 auto;
  }

  @media (min-width: $ln-breakpoint-md) {
    &__image-text {
      margin-bottom: 1.875rem !important;
    }

    &__added-value {
      padding: 1.5625rem 2.5rem;

      ul {
        li:before {
          top: 8px;
        }
      }
    }

    &__grid {
      &--proven-outcomes {
        #{$block}__grid-item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    &__headline {
      margin-bottom: 2.5rem;
    }

    &__logo {
      display: block;
      border-right: 1px solid $ln-gray-eaeaea;
      margin: 0 2.5rem 0 0;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &__image-text {
      margin-bottom: 5rem !important;
    }

    &__added-value {
      padding: 3.125rem 4.6875rem;

      ul, li {
        font-size: 1.125rem;
      }
    }

    &__video {
      max-width: 682px;
    }
  }
}
