/**
 * Created by RZI on 07.03.2016.
 */
.customer-statements {
    border-top: 1px solid #C8C7D9;
}

.customer-statements .person-name {
    text-transform: uppercase;
    font-weight: bold;
}

.customer-statements .company-name {

}

.customer-statement-outerbox {
    height: 190px;
    /* overflow: hidden; */
    position: relative;
    margin-bottom: 20px;
}

.customer-statement-outerbox::after {
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;

    font-family: 'LucaNet-web';
    content: "\e60c";
    color: #666666;

    position: absolute;
    top: 16px;
    left: 21px;

}


.statement-box-holder {
    margin-bottom: 24px;
}

.customer-statement-box {
    border: 1px solid #c7c6d6;
    margin-bottom: 20px;
    position: relative;
    padding: 40px 50px 0 50px;
    background: #FFFFFF;
    height: 190px;
    overflow: hidden;

    -webkit-transition: height 300ms ease-in-out;
    -moz-transition: height 300ms ease-in-out;
    -o-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
}

.bubble-box {
    position: absolute;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

    bottom: -13px;
    left: 50%;
    width: 25px;
    height: 25px;
    border-top: 1px solid transparent;;
    border-right: 1px solid #c7c6d6;;
    border-bottom: 1px solid #c7c6d6;;
    border-left: 1px solid transparent;
    display: inline-block;
    background-color: #ffffff;

}

.person-info-box {
    margin: 0;
    padding: 0;
}



.customer-statements .image-holder {
    height: 110px;
}

.customer-statements .image-holder img {
    max-height: 110px;
}
