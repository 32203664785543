.ln-cc {
  width: 100%;
  padding: 30px;
  background-color: $ln-nightblue;
  color: #fff;
  opacity: 1;
  transition: opacity 1s ease;
  z-index: 10000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  font-size: .875rem;

  @media screen and (min-width: $ln-breakpoint-sm) {
    font-size: $ln-font-size-base;
  }

  &__wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: unset;
      width: 100%;

      @media screen and (min-width: $ln-breakpoint-lg) {
        flex-direction: row;
        justify-content: space-between;
      }
  }

  &__desc {
    display: block;
    flex: 1 1 auto;
    font-weight: $ln-font-weight-light;
    max-width: 100%;
    margin-bottom: 1rem;

    > a {
      border-bottom: 1px dotted #fff;
      color: #fff;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid #fff;
        color: #fff;
        text-decoration: none;
      }
    }

    @media screen and (min-width: $ln-breakpoint-lg) {
      max-width: 75%;
      margin-right: 1em;
    }
  }

  &__compliance {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    > .btn {
      margin: 0;
      padding: .625rem .9375rem;
      font-size: .8125rem;
      font-weight: $ln-font-weight-light;
      white-space: nowrap;
    }

    > .btn:first-of-type {
      margin-right: .5rem;
      margin-bottom: .5rem;
    }

    @media (min-width: $ln-breakpoint-sm) {
      flex-direction: row;

      > .btn:first-of-type {
        margin-bottom: 0;
      }
    }

    @media (min-width: $ln-breakpoint-md) {
      > .btn {
        padding: .75rem 1.375rem;
        font-size: $ln-font-size-base;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      justify-content: flex-end;
    }
  }

  &__footer {
    text-align: center;

    @media (min-width: $ln-breakpoint-lg) {
      text-align: left;
    }
  }

  &__link, &__link:hover {
    color: #fff;
    border-bottom-color: #fff;
  }
}
