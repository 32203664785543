.ln-hero-banner {
  $block: &; /** initial scope: https://css-tricks.com/using-sass-control-scope-bem-naming/ */
  width: 100%;
  height: 300px;
  position: relative;
  z-index: 1;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &__background-image {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: $ln-breakpoint-md-max) {
      background: url("/images/hero-bg-mobile.jpg") 50% !important;
      background-size: cover !important;
    }
  }

  &__overlay-wrapper {
    position: absolute;
    display: none;
    height: 630px;
    min-height: 49.0625rem;
    top: 99%;
    transform: rotate(-7deg);
    transform-origin: left top;
    width: 122%;
    z-index: 3;
  }

  &__overlay {
    display: block;
    background: #fff;
    height: 100%;
    left: -50px;
    position: absolute;
    transform: rotate(0);
    width: 100%;
  }

  &__container-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }

  &__headline {
    display: inline-block;
    color: #fff;

    strong {
      color: inherit;
    }
  }

  &__subline {
    display: inline-block;
    margin-bottom: 0.625rem;
    font-weight: $ln-font-weight-light;
    font-size: 1.375rem;
    line-height: 1.1;
    color: #fff;
  }

  &__teaser {
    display: inline-block;
    margin-bottom: 0.938rem;
    font-size: 1rem;
    color: #fff;

    p {
      color: inherit;
      padding-left: 0;
    }
  }

  &__cta {
    display: inline-block;
    padding: 0.625rem 1rem;
    margin: 1.875rem .625rem 0 0;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--h450 {
    height: 350px;
  }

  &--h550 {
    height: 450px;
  }

  &--align-center {
    text-align: center;

    #{ $block }__cta {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &--align-right {
    text-align: center;
  }

  &--align-left {
    text-align: center;
  }

  @media screen and (min-width: $ln-breakpoint-lg) {
    &__subline {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: $ln-breakpoint-xl) {
    &__subline {
      font-size: 1.75rem;
    }
  }

  @media screen and (min-width: $ln-breakpoint-xxl) {
    &__subline {
      font-size: 2rem;
    }
  }

  @media (min-width: $ln-breakpoint-lg) {
    &__container-wrapper {
      top: 0;
      transform: translateY(0%);
    }

    &__background-image {
      display: block;
    }

    &__element-wrapper {
      width: 66%;
    }

    &__overlay-wrapper {
      transform: rotate(0);
      top: 0;
      height: 100%;
      width: 100%;
    }

    &__overlay {
      display: none;
      transform: rotate(0);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &__headline {
      padding: 0.625rem;
      letter-spacing: 0;
      color: $ln-lagoonblue;
      background-color: $ln-white--80;
    }

    &__subline {
      padding: 0.625rem;
      color: $ln-darkgray;
      background-color: $ln-white--80;
    }

    &__teaser {
      margin-top: 0;
      margin-bottom: 0.938rem;
      padding: 0.625rem;
      color: $ln-darkgray;
      background-color: $ln-white--80;
      font-size: 1.25rem;
      font-weight: 400;
    }

    &__cta {
      margin: 1.875rem .625rem 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &--theme-lagoonblue-overlay {
      #{ $block }__headline {
        color: $ln-lagoonblue;
      }

      #{ $block }__overlay {
        display: block;
        background: $ln-white--25;
      }
    }

    &--theme-nightblue-overlay {
      #{ $block }__headline {
        color: $ln-nightblue;
      }

      #{ $block }__overlay {
        display: block;
        background: $ln-white--25;
      }
    }

    &--theme-raspberry-overlay {
      #{ $block }__headline {
        color: $ln-raspberry;
      }

      #{ $block }__overlay {
        display: block;
        background: $ln-white--25;
      }
    }

    &--theme-raspberry {
      background: none;

      #{ $block }__headline {
        color: $ln-raspberry;
        //background-color: transparent;
      }

      #{ $block }__subline {
        //background-color: transparent;
      }

      #{ $block }__teaser {
        //background-color: transparent;
      }
    }

    &--theme-light {
      #{ $block }__overlay {
        display: block;
        background-color: $ln-white--80;
      }

      #{ $block }__headline {
        color: $ln-darkgray;
        //background-color: transparent;
      }

      #{ $block }__subline {
        color: $ln-darkgray;
        //background-color: transparent;
      }

      #{ $block }__teaser {
        //background-color: transparent;
      }
    }

    &--theme-dark {
      background: none;

      #{ $block }__background-image {
        display: block;
      }

      #{ $block }__overlay {
        display: block;
        background-color: $ln-white--25;
      }

      #{ $block }__headline {
        color: #fff;
        background-color: transparent;
      }

      #{ $block }__subline {
        color: #fff;
        background-color: transparent;
      }

      #{ $block }__teaser {
        color: #fff;
        background-color: transparent;
      }
    }

    &--theme-light, &--theme-dark {
      &.lnet-hero-banner--align-left {
        #{ $block }__cta {
          &:first-of-type {
            margin-left: 0.625rem;
          }
        }
      }

      &.lnet-hero-banner--align-right {
        #{ $block }__cta {
          &:last-of-type {
            margin-right: 0.625rem;
          }
        }
      }
    }

    &--theme-brombeer {
      #{ $block }__headline {
        color: $ln-raspberry;
        //background-color: transparent;
      }

      #{ $block }__subline {
        color: #fff;
        //background-color: transparent;
      }

      #{ $block }__teaser {
        color: #fff;
        //background-color: transparent;
      }
    }

    &--align-center {
      #{ $block }__element-wrapper {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--align-left {
      text-align: left;

      #{ $block }__element-wrapper {
        margin-left: 0;
        margin-right: auto;
      }
    }

    &--align-right {
      text-align: right;

      #{ $block }__element-wrapper {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &--h450 {
      height: 450px;
    }

    &--h550 {
      height: 550px;
    }

    &--valign-top {
      padding-top: 0.938rem;

      #{ $block }__container-wrapper {
        top: 0;
        transform: translateY(0%);
      }
    }

    &--valign-bottom {
      padding-bottom: 0.938rem;

      #{ $block }__container-wrapper {
        top: 100%;
        transform: translateY(-100%);
      }
    }

    &--valign-middle {
      #{ $block }__container-wrapper {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--theme-recruiting-dark-overlay {
    @media (min-width: $ln-breakpoint-xxl) {

      #{ $block }__headline {
        font-size: 2.875rem;
      }

      #{ $block }__subline {
        font-size: 1.563rem;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      #{ $block }__background-image {
        background-image: url("/images/header/header-1920x300-01.jpg");
      }
    }
  }

  &--theme-recruiting-light-overlay {
    @media (min-width: $ln-breakpoint-xxl) {

      #{ $block }__headline {
        font-size: 2.875rem;
      }

      #{ $block }__subline {
        font-size: 1.563rem;
      }
    }

    @media (min-width: $ln-breakpoint-lg) {
      #{ $block }__background-image {
        background-image: url("/images/header/header-1920x300-02.jpg");
      }
    }
  }

  /* world 2021 */
  &--world2021 {
    background-color: #060d17;

    #{ $block }__background-image {
      @media (max-width: $ln-breakpoint-md-max) {
        background: url("/images/world/header-ln-world-mobile-991x450.jpg") 50% !important;
      }
    }
  }
}
