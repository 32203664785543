/** desktop menu **/
.live-demo-button {
  @extend .btn-cta-purple;

  @media (min-width: $screen-md-min) {
    padding: 8px 20px;
  }
}

/** mobile menu **/
#header {
  .main-navigation {
    > li {
      a.live-demo-button {
        color: #fff;

        &:hover {
          color: $ln-base-brambleberry;
          background-color: #ffffff;
          border-color: $ln-base-brambleberry;
        }
      }
    }
  }
}
