$ln-solution-color-02: #ddd;
$ln-solution-color-03: #eee;

.ln-solution {
  position: relative;

  &__section {
    position: relative;
  }

  &__section--lg-up {
    display: none;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      display: block;
    }
  }

  &__section-headline {
    display: block;
    margin-top: 0;
  }

  &-text {
    &__headline {
      display: block;
      margin-top: 0;
      margin-bottom: 1.25rem;
    }

    &__text {
      margin: 0 0 10px 0;
    }
  }

  &-video {
    &__description {
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.025em;
    }
  }

  &-counter {
    height: 310px;
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      margin-bottom: 0;
    }

    /** helper class **/
    &__text-helper {
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: 0.025em;
    }

    &__heading {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    &__headline {
      @extend .ln-solution-counter__text-helper;
      margin-left: 10px;
      margin-right: 10px;
      display: inline;
    }

    &__value {
      @extend .ln-solution-counter__text-helper;
      color: $ln-green;
      margin-bottom: 20px;
    }

    &__link {
      &:hover {
        text-decoration: none;
      }

      @extend .ln-solution-counter__text-helper;
      font-size: 12px;
    }

    &__text {
      @extend .ln-solution-counter__text-helper;
      font-size: 12px;
      margin-left: 0;
      margin-right: 0;
    }

    &__subline {
      @extend .ln-solution-counter__text-helper;
      color: $ln-green;
      font-size: 16px;
    }

    &__info-parent {
      position: relative;
    }

    &__info-child {
      color: $ln-green;
      position: absolute;
      top: -10px;
    }

    &__check-mark {
      color: $ln-green;
      font-size: 18px;
    }

    &__wrapper {
      overflow: hidden;
      max-height: 50px;
    }
  }

  &-demo-teaser {
    &__headline {
      @extend .ln-solution__section-headline;
    }
  }

  &-demo-teaser-link {
    line-height: 40px;
    letter-spacing: 0.025em;
    font-style: normal;

    i {
      opacity: 0;
      width: 0;
      transition: all .5s ease;
    }

    &:hover i {
      opacity: 1;
      width: 20px;
    }
  }

  &-panel {
    &__heading {
      text-align: center;
    }

    &__headline {
      display: inline;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      margin: 0 10px;
      letter-spacing: 0.4px;
    }

    &__text {
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
      margin: 0;
      letter-spacing: 0.025em;
    }
  }

  &-usp {
    border: 1px solid $ln-solution-color-02;
    background-color: $ln-color-light-gray;
    padding: 60px 15px 30px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 60px;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $ln-solution-color-03;
    }

    &__headline {
      display: inherit;
      text-align: center;
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.025em;
    }

    &__description {
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      margin: 0 0 10px 0;
      letter-spacing: 0.025em;
    }

    &__symbol {
      display: inline-block;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $ln-green;
      border-radius: 50%;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 40px;
        font-style: normal;

        &:before {
          color: #fff;
        }
      }
    }

    &__plus-symbol {
      //display: inline-block;
      display: none;
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-color: $ln-color-08;
      border-radius: 50%;

      i {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 28px;
        font-style: normal;
      }
    }
  }

  &-company {
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      margin-bottom: 10px;
    }

    &__image-link {
      display: block;
      position: relative;
      height: 150px;
      background-color: #fff;
      border: 0;

      img {
        max-width: 100%;
        max-height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.95);
        transition: all .4s ease;
      }

      &:hover {
        border: 0;
      }
    }

    &:hover &__image-link img {
      transform: translateX(-50%) translateY(-50%) scale(1.05);
    }
  }

  &-company-link {
    line-height: 40px;
    letter-spacing: 0.025em;
    font-style: normal;
    margin-bottom: 10px;
  }

  &-product {
    margin-bottom: 40px;

    @media only screen and (min-width: $ln-breakpoint-lg) {
      margin-bottom: 0;
    }

    &__text-helper {
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.025em;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__link {
      @extend .clearfix;
      display: block;
      border: none;

      &:hover {
        border: none;
      }
    }

    &__icon {
      @extend .ln-solution-product__text-helper;
      font-weight: 400;
      font-size: 44px;

      &:before {
        color: inherit;
      }
    }

    &__headline {
      @extend .ln-solution-product__text-helper;
      font-weight: 700;
      font-size: 14px;
    }

    &__description {
      @extend .ln-solution-product__text-helper;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-category {
    margin-bottom: 40px;

    &__link {
      @extend .clearfix;
      display: table;
      width: 100%;
    }

    &__icon {
      @extend .ln-solution-product__text-helper;
      font-weight: 400;
      font-size: 44px;

      &:before {
        color: inherit;
      }

      &-wrapper {
        display: table-cell;
        vertical-align: middle;
        width: 15%;
        padding-right: 0;
      }
    }

    &__icon--active {
      &:before {
        color: $ln-darkgray;
      }
    }

    &__description {
      @extend .ln-solution-product__text-helper;
      font-weight: 400;
      font-size: 14px;

      &-wrapper {
        display: table-cell;
        vertical-align: middle;
        width: 85%;
        padding-left: 15px;
      }
    }

    &__description--active {
      font-weight: 700;
      color: $ln-darkgray;
    }
  }

  &-process {
    position: relative;
    height: 123px;
    margin-bottom: 70px;
    padding-left: 50px;
    padding-right: 50px;

    //determine direction of flow of processes in process flow matrix:
    //    -bootstrap grid of 4,4,4 columns is expected to be represented by processes
    //    -every three columns represent a row
    //    -make every other row flow from right to left, starting from second row
    &:nth-child(6n + 4), &:nth-child(6n + 5), &:nth-child(6n + 6) {
      float: right;
    }

    &__body {
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: center;
      border-bottom: 1px solid $ln-nightblue;
      cursor: pointer;
    }

    &__icon {
      display: inline-block;
      position: relative;

      i {
        font-size: 67px;
        font-style: normal;
      }
    }

    &:nth-child(odd) &__icon i {
      &:before {
        color: $ln-nightblue;
      }
    }

    &:nth-child(even) &__icon i {
      &:before {
        color: $ln-nightblue;
      }
    }

    &__badge {
      display: inline-block;
      position: absolute;
      left: -50%;
      top: 0;

      text-align: center;
      background-color: $ln-green;
      color: #fff;
      font-weight: 300;
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.025em;
      border-radius: 11px;
      width: 22px;
      height: 22px;
    }

    &__headline {
      display: block;
      color: $ln-nightblue;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: -0.005em;

      @media only screen and (min-width: $ln-breakpoint-md) {
        letter-spacing: 0.025em;
      }
    }

    &__overlay-frame {
      position: relative;
      z-index: 1;
      background-color: #fff;

      &--open {
        z-index: 2;
      }
    }

    &__overlay {
      padding: 20px;
      border-bottom: 1px solid $ln-nightblue;
      border-left: 1px solid $ln-nightblue;
      border-right: 1px solid $ln-nightblue;
    }

    &__overlay-button {
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(50%) translateY(50%);
      height: 20px;
      width: 20px;
      cursor: pointer;
      background-color: $ln-nightblue;
      border: 1px solid #fff;
      border-radius: 50%;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transition: all 350ms ease-in-out;
        color: #fff;
      }

      &--open {
        i {
          transform: translateX(-50%) translateY(-50%) rotate(180deg);
        }
      }
    }

    &__description {
      font-weight: $ln-font-weight-light;
      line-height: 1.1875rem;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: 0.0203rem;

      ul, li {
        font-size: inherit;
      }
    }

    //process matrix connector:
    //    -positioning and direction of connectors is influenced by process flow direction of each row
    &__connector-vertical, &__connector-horizontal {
      display: none;
      height: 17px;
      width: 17px;
      font: normal normal normal 34px/1 "Open Sans", sans-serif;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $ln-raspberry;
    }

      //process matrix connector column1
        //pointing from previous row at current row, belonging to first column of current row, starting at second row
    &:nth-child(3n + 4) &__connector-vertical {
      display: inline-block;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: rotate(90deg);

      &:before {
        content: "\00BB";
      }
    }

      //process matrix connector column2
        //pointing from previous column at second column, every other row starting from first row, belonging to current column
    &:nth-child(6n + 2) &__connector-horizontal {
      display: inline-block;
      position: absolute;
      top: 40px;
      left: 0;
      transform: translateX(-50%);

      &:before {
        content: "\00BB";
      }
    }
        //pointing from previous column at second column, every other row starting from second row, belonging to current column
    &:nth-child(6n + 5) &__connector-horizontal {
      display: inline-block;
      position: absolute;
      top: 40px;
      right: 0;
      transform: translateX(50%);

      &:before {
        content: "\00AB";
      }
    }

      //process matrix connector column3
        //pointing from previous column at third column, every other row starting from first row, belonging to current column
    &:nth-child(6n + 3) &__connector-horizontal {
      display: inline-block;
      position: absolute;
      top: 40px;
      left: 0;
      transform: translateX(-50%);

      &:before {
        content: "\00BB";
      }
    }
        //pointing from previous column at third column, every other row starting from second row, belonging to current column
    &:nth-child(6n + 6) &__connector-horizontal {
      display: inline-block;
      position: absolute;
      top: 40px;
      right: 0;
      transform: translateX(50%);

      &:before {
        content: "\00AB"
      }
    }
  }
}
