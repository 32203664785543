/**
* CSS Style modify for owl-slider
* https://owlcarousel2.github.io/OwlCarousel2/docs/api-classes.html
*/
.ln-slider {
  $block: &; /** initial scope **/

  &--hide-mobile {
    display: none;
  }

  @media (min-width: $ln-breakpoint-md) {
    &--hide-mobile {
      display: block;
    }
  }

  /** navigation container **/
  &__nav {

  }

  &__prev, &__next {
    position: absolute;
    display: block;
    top: 50%;
    transform: translate(0, -50%);
    margin: auto;
    text-align: center;
    padding: 0;
    text-shadow: none;
    line-height: 2.063rem;
    width: 1.875rem;
    height: 1.875rem;

    &:hover {
      cursor: pointer;
    }

    &--circle-grey  {
      border-radius: 45px;
      color: $ln-darkgray;

      &:hover {
        background-color: $ln-green;
        color: $ln-white;
      }
    }
  }

  &__prev {
    left: 0;
    text-indent: -0.1rem;
  }

  &__next {
    right: 0;
    text-indent: 0.1rem;
  }

  /** Counter Bulletpoints */
  &__indicator-wrapper {
    position: absolute;
    bottom: 0.625rem;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
  }

  &__indicator {
    margin: 0 0.125rem;
    background-color: $ln-green;
    opacity: .7;
    width: 0.938rem;
    height: 0.938rem;
    display: inline-block;
    text-indent: -999px;
    border: 1px solid $ln-green;
    border-radius: 10px;
    cursor: pointer;

    &.active {
      opacity: 1;
      border-color: $ln-green;
      background-color: $ln-white--80;
    }
  }

  &__spinner {
    text-align: center;
    .fa {
      font-size: 2rem;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    overflow: hidden;
  }

  &__stage {
    position: relative;
    margin: 0 auto;
  }

  &--nav-outer {
    position: relative;
    padding: 0 2.5rem
  }

  // types
  &--logo {
    #{$block}__stage {
      display: flex;
      align-items: stretch;

      // owl context
      .owl-item {
        display: flex;
        padding: 0 12px;
      }
    }

    #{$block}__image {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 15px 10px;
      border:0;

      &:hover {
        border: 0;

        img {
          transform: scale(1.05);
        }
      }

      img {
        transition: transform .2s ease;
        max-width: 189px;
        max-height: 120px;
      }
    }

    @media only screen and (min-width: $ln-breakpoint-lg) {
      #{$block}__image {
        padding: 30px 20px;
      }
    }
  }

  // owl context
  &.disabled { // default owl class
    #{$block}__prev,
    #{$block}__next {
      display: none;
    }

    &#{$block}--nav-outer {
      padding: 0;
    }
  }

  .owl-loaded { // default owl class
    #{$block}__spinner {
      display: none;
    }
  }
}
